// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_CtQTn {
  height: 100%;
  background: white;
  width: 600px;
}
.content_CtQTn .header_ncbfi {
  height: 56px;
  border-bottom: 1px solid #E7EAEF;
  border-top: 1px solid #E7EAEF;
}
.content_CtQTn .header_ncbfi .headerRow_Rt6BM {
  height: 56px;
}
.content_CtQTn .header_ncbfi .headerRow_Rt6BM .headerTitle_NivLm {
  margin: 16px 24px;
  font-size: 18px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #28548D;
}
.content_CtQTn .header_ncbfi .headerRow_Rt6BM .crossButton_tif4T {
  margin: 12px 24px;
  width: 32px;
  height: 32px;
}
.content_CtQTn .contentArea_c89u4 {
  background: #F9FBFB;
}
.content_CtQTn .contentArea_c89u4 .infoBlock_o0Rln {
  height: 100%;
  padding: 8px 24px;
}
.content_CtQTn .contentArea_c89u4 .infoBlock_o0Rln .infoTitle_lqILx {
  font-size: 13px;
  font-weight: 900;
  line-height: 16px;
  color: #3E4243;
  padding-top: 24px;
}
.content_CtQTn .contentArea_c89u4 .infoBlock_o0Rln .info_cv_pp {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #3E4243;
  padding-top: 12px;
}
.content_CtQTn .contentArea_c89u4 .infoBlock_o0Rln .uploadDate_USIWf {
  font-weight: 800;
}
.content_CtQTn .footer_kSdUb {
  height: 96px;
  padding-top: 30px;
  padding-right: 25px;
  box-shadow: 0px -1px 8px rgba(41, 50, 61, 0.1);
}
.content_CtQTn .footer_kSdUb .footerRow__6BHv {
  padding-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/firmware/Firmware/FirmwareDetails.less"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,iBAAA;EACA,YAAA;AAAF;AAHA;EAMI,YAAA;EACA,gCAAA;EACA,6BAAA;AAAJ;AARA;EAWM,YAAA;AAAN;AAXA;EAcQ,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AAAR;AAnBA;EAuBQ,iBAAA;EACA,WAAA;EACA,YAAA;AADR;AAxBA;EA+BI,mBAAA;AAJJ;AA3BA;EAkCM,YAAA;EACA,iBAAA;AAJN;AA/BA;EAsCQ,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAJR;AAtCA;EA8CQ,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AALR;AA9CA;EAuDQ,gBAAA;AANR;AAjDA;EA6DI,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,8CAAA;AATJ;AAvDA;EAmEM,cAAA;AATN","sourcesContent":["\n.content {\n  height : 100%;\n  background: white;\n  width: 600px;\n\n  .header {\n    height: 56px;\n    border-bottom: 1px solid #E7EAEF;\n    border-top: 1px solid #E7EAEF;\n\n    .headerRow {\n      height: 56px;\n\n      .headerTitle {\n        margin: 16px 24px;\n        font-size: 18px;\n        font-weight: 900;\n        line-height: 24px;\n        letter-spacing: 0.015em;\n        color: #28548D;\n      }\n\n      .crossButton {\n        margin: 12px 24px;\n        width: 32px;\n        height: 32px;\n      }\n    }\n  }\n\n  .contentArea {\n    background: #F9FBFB;\n\n    .infoBlock {\n      height: 100%;\n      padding: 8px 24px;\n\n      .infoTitle {\n        font-size: 13px;\n        font-weight: 900;\n        line-height: 16px;\n        color: #3E4243;\n        padding-top: 24px;\n      }\n\n      .info {\n        font-style: normal;\n        font-weight: normal;\n        font-size: 15px;\n        line-height: 20px;\n        color: #3E4243;\n        padding-top: 12px;\n      }\n\n      .uploadDate {\n        font-weight: 800;\n      }\n    }\n  }\n\n  .footer {\n    height: 96px;\n    padding-top: 30px;\n    padding-right: 25px;\n    box-shadow: 0px -1px 8px rgba(41, 50, 61, 0.1);\n\n    .footerRow {\n      padding-top: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content_CtQTn`,
	"header": `header_ncbfi`,
	"headerRow": `headerRow_Rt6BM`,
	"headerTitle": `headerTitle_NivLm`,
	"crossButton": `crossButton_tif4T`,
	"contentArea": `contentArea_c89u4`,
	"infoBlock": `infoBlock_o0Rln`,
	"infoTitle": `infoTitle_lqILx`,
	"info": `info_cv_pp`,
	"uploadDate": `uploadDate_USIWf`,
	"footer": `footer_kSdUb`,
	"footerRow": `footerRow__6BHv`
};
export default ___CSS_LOADER_EXPORT___;
