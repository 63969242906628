import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, Text, Toast, Button, Content, TextInput, Dropdown } from '@leverege/ui-elements'
import { createUser, updateRole } from '../../../../actions/UserActions'
import { isValidEmail } from '../../../../util/Email'
import { isValidPhone } from '../../../../util/Phone'
import S from './UserEditor.less'
import { ROLE_ID } from '../../../../util/Authz'

class UserEditor extends Component {

  state = {
    email : null,
    role : null
  }

  onSubmit = async () => {
    const { userId, createUser, updateRole, onClose } = this.props
    const { email, phone, role } = this.state
    const shipRoleId = ROLE_ID[role]

    if ( !userId ) {
      try {
        const user = { email, phone, shipRoleId }
        if ( !isValidEmail( email ) ) {
          Toast.error(
            'Please insert a valid email!',
            { autoClose : 8000, containerId : 'toastDefault' }
          )
          return
        }

        if ( !isValidPhone( phone ) ) {
          Toast.error(
            'Please insert a valid phone number!',
            { autoClose : 8000, containerId : 'toastDefault' }
          )
          return
        }
        
        if ( !role ) {
          Toast.error(
            'Please select a role!',
            { autoClose : 8000, containerId : 'toastDefault' }
          )
          return
        }

        const res = await createUser( user )

        if ( res.id ) {
          Toast.success(
            `Successfully created ${role} user`,
            { containerId : 'toastDefault' }
          )
        } else {
          throw new Error( 'Unable to create new SHIP user' )
        }

        onClose()
      } catch ( err ) {
        console.log( err )
        Toast.error(
          err.message,
          { autoClose : 8000, containerId : 'toastDefault' }
        )
      }
    } else {
      try {
        // TODO: auth migration -> LS-1582 update UI functionality
        await updateRole( userId, shipRoleId )

        Toast.success(
          `Successfully updated user role to ${role}`,
          { containerId : 'toastDefault' }
        )
        
        onClose()
      } catch ( err ) {
        Toast.error(
          err.message,
          { autoClose : 8000, containerId : 'toastDefault' }
        )
      }
    }
  }

  componentDidMount() {
    const { userId, users } = this.props
    if ( userId ) {
      const user = userId ? users.find( u => u.id === userId ) : null
      this.setState( {
        email : user.email,
        phone : user.phone,
        role : user.role
      } )
    }
  }

  render() {
    const { userId, onClose } = this.props
    const { email, phone, role } = this.state
    return (
      <React.Fragment>
        <Content relative className={S.content}>
          <Content.Header className={S.header}>
            <Flex direction="row" className={S.headerRow} justify="space-between">
              <Text className={S.headerTitle}>
                {`${userId ? `Edit ${role} User` : 'Create New User'}`}
              </Text>
              <div onClick={() => onClose()}>
                <img src="/images/cross.png" alt="close" className={S.crossButton} />
              </div>
            </Flex>
          </Content.Header>
          <Content.Area className={S.contentArea}>
            <Flex direction="column" className={S.infoBlock}>

              <Flex direction="row" className={S.infoTitle}>
                Email
              </Flex>
              <TextInput
                className={S.info}
                hint="Enter email"
                value={email}
                disabled={!!userId}
                onChange={( { value : email } ) => this.setState( { email } )} />

              <Flex direction="row" className={S.infoTitle}>
                Phone
              </Flex>
              <TextInput
                className={S.info}
                value={phone}
                hint="Enter phone number"
                disabled={!!userId}
                onChange={( { value : phone } ) => this.setState( { phone } )} />


              <Flex direction="row" className={S.infoTitle}>
                Role
              </Flex>
              <Dropdown
                data={[
                  { name : 'Admin', id : 'ShipAdmin' },
                  { name : 'Engineer', id : 'ShipEngineer' },
                  { name : 'Support', id : 'ShipSupport' },
                ]}
                placeholder="Select Role..."
                textField="name"
                valueField="id"
                sendValueField
                value={role}
                onChange={( { value : role } ) => this.setState( { role } )} />

            </Flex>

          </Content.Area>
          <Content.Footer className={S.footer}>
            <Flex variant="buttons" className={S.footerRow}>
              {role === null && <Button onClick={() => this.onSubmit()}>{userId ? 'Save Changes' : 'Add New User'}</Button>}
              {role != null && <Button onClick={() => this.onSubmit()}>{userId ? 'Save Changes' : `Add ${role} User`}</Button>}
              <Button onClick={() => onClose()}>Close</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </React.Fragment>
    )
  }
}

export default connect(
  state => ( {
    users : state.user.users
  } ),
  dispatch => ( {
    createUser : user => dispatch( createUser( user ) ),
    updateRole : ( userId, role ) => dispatch( updateRole( userId, role ) )
  } )
)( UserEditor )
