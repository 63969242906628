module.exports = {
  "hierarchy": {
    "small": [
      "list-small"
    ],
    "tracks": [
      "list-tracks"
    ]
  },
  "props": {
    "default": {
      "icon": "fa fa-check"
    },
    "small": {
      "icon": "fa fa-check"
    },
    "tracks": {
      "icon": "fa fa-check"
    }
  }
}