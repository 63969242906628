import React from 'react'

import S from './FirmwareTypePills.less'

const FirmwareTypePills = ( { binaries } ) => {
  if ( !binaries ) return null
  return (
    <div className={S.container}>
      {binaries.main && <div className={S.pill}>Main Unit</div> }
      {binaries.leaf && <div className={S.pill}>Wireless Sensor</div> }
      {binaries.host && <div className={S.pill}>Wireless Host</div> }
      {binaries.repeater && <div className={S.pill}>Wireless Repeater</div> }
    </div>
  )
}

export default FirmwareTypePills
