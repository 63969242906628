// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_w2_aQ .searchIcon_yvsqw {
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 8px;
}
.container_w2_aQ .searchInput_d6SNP {
  width: 248px;
}
.container_w2_aQ .searchInput_d6SNP:focus + .searchButton_Wb4ZN {
  border: 1px solid #28548d;
  border-left: none;
}
.container_w2_aQ .searchButton_Wb4ZN {
  width: 82px;
}
.container_w2_aQ:hover .searchButton_Wb4ZN {
  border: 1px solid #28548d;
  border-left: none;
}
.container_w2_aQ:hover .searchInput_d6SNP {
  border: 1px solid #28548d;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/lib/input/Search.less"],"names":[],"mappings":"AAAA;EAGI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AADJ;AALA;EAUI,YAAA;AAFJ;AARA;EAcI,yBAAA;EACA,iBAAA;AAHJ;AAZA;EAmBI,WAAA;AAJJ;AAOE;EAEI,yBAAA;EACA,iBAAA;AANN;AAGE;EAOI,yBAAA;AAPN","sourcesContent":[".container {\n\n  .searchIcon {\n    position: absolute;\n    width: 24px;\n    height: 24px;\n    margin: 8px;\n  }\n\n  .searchInput {\n    width: 248px;\n  }\n\n  .searchInput:focus+.searchButton {\n    border: 1px solid #28548d;\n    border-left: none;\n  }\n\n  .searchButton {\n    width: 82px;\n  }\n\n  &:hover {\n    .searchButton {\n      border: 1px solid #28548d;\n      border-left: none;\n    }\n\n    .searchInput {\n      border: 1px solid #28548d;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container_w2_aQ`,
	"searchIcon": `searchIcon_yvsqw`,
	"searchInput": `searchInput_d6SNP`,
	"searchButton": `searchButton_Wb4ZN`
};
export default ___CSS_LOADER_EXPORT___;
