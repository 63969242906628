import React, { Component } from 'react'
import classNames from 'classnames'
import { Flex, Table } from '@leverege/ui-elements'
import Paginator from './Paginator'

class DefaultTable extends Component {
  render() {
    const { data = [], onRowClick = () => {}, columns, loading, minRows, className, noRowsComponent, showPagination = true, ...rest } = this.props

    return (
      <Table
        className={classNames( className )}
        variant="base"
        data={data}
        columns={columns}
        showPagination={showPagination}
        minRows={minRows || 0}
        loading={loading}
        loadingText={(
          <Flex className="defaultFlex" justify="center">
            <i className="fa fa-spinner fa-spin fa-2x fa-fw" />
          </Flex>
        )}
        noDataText={loading ? '' : noRowsComponent || 'No rows found'}
        getTrGroupProps={() => ( { style : { maxHeight : 48 } } )}
        getTrProps={() => ( { style : { maxHeight : 48 } } )}
        getTdProps={( state, rowInfo, column ) => ( {
          style : { 
            maxHeight : 48,
            whiteSpace : 'nowrap',
            textOverflow : 'ellipsis',
            overflow : 'hidden',
            maxWidth : 1,
            borderRight : '1px solid #f0f1f2'
          },
          onClick : () => {
            switch ( column.id ) {
              case 'select':
              case 'action':
                break
              default:
                onRowClick( rowInfo.original )
            }
          }
        } )}
        PaginationComponent={Paginator}
        {...rest} />
    )
  }
}


export default DefaultTable
