const Constants = require( '../actions/Constants' );

const initialState = {
  twoFactorSettings : {}
}

function project( state = initialState, action ) {

  switch ( action.type ) {
    case Constants.SET_TWO_FACTOR_SETTINGS:
      return {
        ...state,
        twoFactorSettings : action.payload
      }

    default:
      return state
  }

}

module.exports = project
