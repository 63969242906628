// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text_lTMWQ {
  color: var(--color-label1);
  font-size: var(--size-fontMedium);
}
.text_lTMWQ.text-small_f4qdR {
  font-size: var(--size-fontSmall);
}
.text_lTMWQ.text-dialogMessage_G90yt {
  margin-top: 10px;
  margin-bottom: 50px;
}
.text_lTMWQ.text-listLabel_uZMIZ {
  font-weight: 600;
}
.text_lTMWQ.text-sectionTitle_esp1b {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.text_lTMWQ.text-small_f4qdR.text-error_ohz3W {
  color: var(--color-alertDangerA);
  font-size: var(--size-fontMedium);
}
.text_lTMWQ.text-instructions_PpP6w {
  font-size: var(--size-fontLarge);
  text-align: center;
  font-weight: bold;
}
.text_lTMWQ.text-instructions_PpP6w.text-dialogTitle_aufao {
  text-align: left;
}
.text_lTMWQ.text-portalForm_CX9eV {
  margin-left: 16px;
}
.text_lTMWQ.text-contentTitle_Ms69H {
  color: #3e4243;
  font-size: 18px;
  font-weight: 800;
  line-height: 28px;
}
.text_lTMWQ.text-contentInfo_V8tCb {
  color: #717476;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
}
.text_lTMWQ.text-hintText_XWJc_ {
  font-size: var(--size-fontMedium);
  font-style: italic;
}
.text_lTMWQ.text-headerText_b7zrj {
  color: #67798e;
  font-size: 15px;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/theme/Text.css"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,iCAAA;AACF;AACA;EACE,gCAAA;AACF;AACA;EACE,gBAAA;EACA,mBAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACA;EACE,gCAAA;EACA,iCAAA;AACF;AACA;EACE,gCAAA;EACA,kBAAA;EACA,iBAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EACE,iBAAA;AACF;AACA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACA;EACE,cAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AACF;AACA;EACE,iCAAA;EACA,kBAAA;AACF;AACA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":[".text {\n  color: var( --color-label1 );\n  font-size: var( --size-fontMedium );\n}\n.text.text-small {\n  font-size: var( --size-fontSmall );\n}\n.text.text-dialogMessage {\n  margin-top: 10px;\n  margin-bottom: 50px;\n}\n.text.text-listLabel {\n  font-weight: 600;\n}\n.text.text-sectionTitle {\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 32px;\n}\n.text.text-small.text-error {\n  color: var( --color-alertDangerA );\n  font-size: var( --size-fontMedium );\n}\n.text.text-instructions {\n  font-size: var( --size-fontLarge );\n  text-align: center;\n  font-weight: bold;\n}\n.text.text-instructions.text-dialogTitle {\n  text-align: left;\n}\n.text.text-portalForm {\n  margin-left: 16px;\n}\n.text.text-contentTitle {\n  color: #3e4243;\n  font-size: 18px;\n  font-weight: 800;\n  line-height: 28px;\n}\n.text.text-contentInfo {\n  color: #717476;\n  font-size: 15px;\n  font-weight: normal;\n  line-height: 20px;\n}\n.text.text-hintText {\n  font-size: var( --size-fontMedium );\n  font-style: italic;\n}\n.text.text-headerText {\n  color: #67798e;\n  font-size: 15px;\n  line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `text_lTMWQ`,
	"text-small": `text-small_f4qdR`,
	"text-dialogMessage": `text-dialogMessage_G90yt`,
	"text-listLabel": `text-listLabel_uZMIZ`,
	"text-sectionTitle": `text-sectionTitle_esp1b`,
	"text-error": `text-error_ohz3W`,
	"text-instructions": `text-instructions_PpP6w`,
	"text-dialogTitle": `text-dialogTitle_aufao`,
	"text-portalForm": `text-portalForm_CX9eV`,
	"text-contentTitle": `text-contentTitle_Ms69H`,
	"text-contentInfo": `text-contentInfo_V8tCb`,
	"text-hintText": `text-hintText_XWJc_`,
	"text-headerText": `text-headerText_b7zrj`
};
export default ___CSS_LOADER_EXPORT___;
