// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portal_L7bSn {
  background-color: rgba(238, 238, 238, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/theme/Portal.css"],"names":[],"mappings":"AAAA;EACE,0CAAA;AACF","sourcesContent":[".portal {\n  background-color: rgba(238, 238, 238, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portal": `portal_L7bSn`
};
export default ___CSS_LOADER_EXPORT___;
