import React from 'react'
import { connect } from 'react-redux'
import { Content, Flex } from '@leverege/ui-elements'

import S from './AnalyticsScreen.less'

class AnalyticsScreen extends React.Component {

  render() {
    return (
      <Content>
        <Content.Header>
          <Flex>
            Analytics
          </Flex>
        </Content.Header>
        <Content.Area>
          <Flex variant="default" className={S.header} justify="space-between">
            Put options here
          </Flex>
        </Content.Area>
      </Content>
    )
  }
}

export default connect( 
  state => ( {
    ui : state.ui
  } ) )( AnalyticsScreen )
