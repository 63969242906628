// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabBarRow_mC_vW {
  height: 100%;
}
.tabBarRow_mC_vW .iconOverlay_yzxUv {
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 0 !important;
}
.tabBarRow_mC_vW .tabIcon_TRj1D {
  filter: invert(50%) sepia(9%) saturate(122%) hue-rotate(161deg) brightness(88%) contrast(87%);
}
.tabBarRow_mC_vW .activeTabIcon_nXqK8 {
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/lib/tab/Tabs.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAFA;EAII,kBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;AACJ;AARA;EAWI,6FAAA;AAAJ;AAXA;EAeI,4FAAA;AADJ","sourcesContent":[".tabBarRow {\n  height: 100%;\n\n  .iconOverlay {\n    position: absolute;\n    width: 24px;\n    height: 24px;\n    margin: 0 !important;\n  }\n\n  .tabIcon {\n    filter: invert(50%) sepia(9%) saturate(122%) hue-rotate(161deg) brightness(88%) contrast(87%);\n  }\n\n  .activeTabIcon {\n    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabBarRow": `tabBarRow_mC_vW`,
	"iconOverlay": `iconOverlay_yzxUv`,
	"tabIcon": `tabIcon_TRj1D`,
	"activeTabIcon": `activeTabIcon_nXqK8`
};
export default ___CSS_LOADER_EXPORT___;
