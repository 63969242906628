const Constants = require( '../actions/Constants' )

const initialState = {
  idsPerPage : {},
  pages : [],
  lookup : {},
  loading : false,
  byEmail : false,
  customer : {}
}

function customer( state = initialState, action ) {

  switch ( action.type ) {

    case Constants.CUSTOMERS_LOADED: {
      const currentPage = action.payload.currentPage || 'first'
      const { customers, nextPage, byEmail } = action.payload
      const ids = customers.map( c => c.id )
      const lookup = {}
      ids.forEach( ( id, index ) => {
        lookup[id] = customers[index]
      } )
      const nPages = [ ...state.pages ]
      if ( nPages.length === 0 ) {
        nPages.push( 'first' )
      }
      const index = nPages.findIndex( p => p === currentPage )
      const nIndex = nPages.findIndex( p => p === nextPage )
      if ( nIndex < 0 && index >= 0 && nextPage ) {
        nPages.splice( index + 1, 0, nextPage )
      }
      return {
        ...state,
        loading : false,
        byEmail,
        pages : nPages,
        idsPerPage : {
          ...state.idsPerPage,
          [currentPage] : ids,
          [nextPage] : [],
        },
        lookup : {
          ...lookup,
          ...state.lookup
        }
      }
    }

    case Constants.CUSTOMERS_DEVICES_LOADED: {
      const { userId, devices } = action.payload
      const user = {
        ...state.lookup[userId],
        devices
      }

      return {
        ...state,
        lookup : {
          ...state.lookup,
          [userId] : user
        }
      }
    }

    case Constants.CUSTOMERS_LOADING: {
      return {
        ...state,
        loading : action.payload.loading
      }
    }

    case Constants.SET_CUSTOMER_DATA: {
      const { payload } = action
      return { 
        ...state,
        customer : payload
      }
    }

    case Constants.CUSTOMERS_CLEAR: {
      return initialState
    }

    default:
      return state
  }

}

module.exports = customer
