// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vTable_QUDBO {
  color: #29323d;
  border-top: 1px solid #e8ecf0;
  font-family: var(--fontFamily-primary);
  border-bottom: 1px solid #e8ecf0;
}
.vTable_QUDBO .header_uiW1y {
  font-weight: 600;
  line-height: 36px;
}
.vTable_QUDBO .row__jVCj {
  border-top: 1px solid #e8ecf0;
  border-left: none;
  line-height: 36px;
  border-right: none;
}
.vTable_QUDBO .row__jVCj.even_d36AL {
  background-color: #f9fafb;
}
.vTable_QUDBO .headerCell_NX4M1 {
  padding-left: var(--size-padMedium);
  padding-right: var(--size-padMedium);
}
.vTable_QUDBO .bodyCell_tQ_LD {
  padding-left: var(--size-padMedium);
  padding-right: var(--size-padMedium);
}
.vTable_QUDBO .headerCell_NX4M1:first-child {
  border-left: none;
}
.vTable_QUDBO .bodyCell_tQ_LD:first-child {
  border-left: none;
}
.vTable_QUDBO.vTable-base__QOWM {
  color: #3e4243;
  font-size: 15px;
  line-height: 20px;
}
.vTable_QUDBO.vTable-base__QOWM .header_uiW1y {
  color: #3e4243;
  text-align: center;
  background-color: transparent;
}
.vTable_QUDBO.vTable-base__QOWM .row__jVCj.even_d36AL {
  background-color: #f8fafd;
}
.vTable_QUDBO.vTable-base__QOWM .row__jVCj.odd_WQBKL {
  background-color: transparent;
}
.vTable_QUDBO.vTable-base__QOWM .headerCell_NX4M1 {
  padding-top: 6px;
  padding-bottom: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/theme/VTable.css"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,6BAAA;EACA,sCAAA;EACA,gCAAA;AACF;AACA;EACE,gBAAA;EACA,iBAAA;AACF;AACA;EACE,6BAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AACA;EACE,yBAAA;AACF;AACA;EACE,mCAAA;EACA,oCAAA;AACF;AACA;EACE,mCAAA;EACA,oCAAA;AACF;AACA;EACE,iBAAA;AACF;AACA;EACE,iBAAA;AACF;AACA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AACF;AACA;EACE,cAAA;EACA,kBAAA;EACA,6BAAA;AACF;AACA;EACE,yBAAA;AACF;AACA;EACE,6BAAA;AACF;AACA;EACE,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".vTable {\n  color: #29323d;\n  border-top: 1px solid #e8ecf0;\n  font-family: var( --fontFamily-primary );\n  border-bottom: 1px solid #e8ecf0;\n}\n.vTable .header {\n  font-weight: 600;\n  line-height: 36px;\n}\n.vTable .row {\n  border-top: 1px solid #e8ecf0;\n  border-left: none;\n  line-height: 36px;\n  border-right: none;\n}\n.vTable .row.even {\n  background-color: #f9fafb;\n}\n.vTable .headerCell {\n  padding-left: var( --size-padMedium );\n  padding-right: var( --size-padMedium );\n}\n.vTable .bodyCell {\n  padding-left: var( --size-padMedium );\n  padding-right: var( --size-padMedium );\n}\n.vTable .headerCell:first-child {\n  border-left: none;\n}\n.vTable .bodyCell:first-child {\n  border-left: none;\n}\n.vTable.vTable-base {\n  color: #3e4243;\n  font-size: 15px;\n  line-height: 20px;\n}\n.vTable.vTable-base .header {\n  color: #3e4243;\n  text-align: center;\n  background-color: transparent;\n}\n.vTable.vTable-base .row.even {\n  background-color: #f8fafd;\n}\n.vTable.vTable-base .row.odd {\n  background-color: transparent;\n}\n.vTable.vTable-base .headerCell {\n  padding-top: 6px;\n  padding-bottom: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vTable": `vTable_QUDBO`,
	"header": `header_uiW1y`,
	"row": `row__jVCj`,
	"even": `even_d36AL`,
	"headerCell": `headerCell_NX4M1`,
	"bodyCell": `bodyCell_tQ_LD`,
	"vTable-base": `vTable-base__QOWM`,
	"odd": `odd_WQBKL`
};
export default ___CSS_LOADER_EXPORT___;
