import React, { Component } from 'react'
import { Checkbox } from '@leverege/ui-elements'
import DefaultTable from './DefaultTable'

class TableWithSelectableRowsAndActions extends Component {

  state = {
    selectedRows : new Set()
  }

  constructor( props ) {
    super( props );

    const {
      onSelectionChange = () => {}
    } = props

    this.onSelectionChange = onSelectionChange;
  }

  toggleSelectionOfAllDevices( value ) {
    const { data } = this.props
    const selectedRows = value ? new Set( [ ...data ] ) : new Set();

    this.setState( { selectedRows } )

    if ( this.onSelectionChange ) {
      this.onSelectionChange( [ ...selectedRows ] )
    }
  }

  toggleSelectionOfSingleDevices( value, item ) {
    const { selectedRows } = this.state

    if ( value ) {
      selectedRows.add( item )
    } else {
      selectedRows.delete( item )
    }

    this.setState( { selectedRows } )

    if ( this.onSelectionChange ) {
      this.onSelectionChange( [ ...selectedRows ] )
    }
  }

  componentDidUpdate( prevProps ) {

    if ( this.props.data !== prevProps.data ) {
      this.setState( { selectedRows : new Set() } )
    } 

  }

  render() {
    const { selectedRows } = this.state
    const { data = [], columns } = this.props

    const extendedColumns = [
      {
        id : 'select',
        Header : <Checkbox
          variant={selectedRows.size > 0 && selectedRows.size !== data.length ? 'partial' : 'row'}
          onChange={( { value } ) => this.toggleSelectionOfAllDevices( value )}
          value={selectedRows.size > 0 && selectedRows.size === data.length} />,
        resizable : false,
        sortable : false,
        width : 52,
        Cell : x => (
          <Checkbox 
            variant="row"
            onChange={( { value } ) => this.toggleSelectionOfSingleDevices( value, x.original )}
            value={selectedRows.has( x.original )} />
        )
      },
      ...columns
    ]

    return (
      <DefaultTable
        {...this.props} 
        columns={extendedColumns} />
    )
  }
}

export default TableWithSelectableRowsAndActions
