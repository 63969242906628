// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exportDialog_oXxAL {
  width: 432px;
  height: 304px;
  border-radius: 8px;
}
.exportTitle_oAp1m {
  font-style: normal;
  font-weight: 800;
  font-size: 20px !important;
  line-height: 24px;
  color: #3E4243 !important;
  padding: 7px 0px 0px 7px;
}
.exportSubTitle_uE2AH {
  font-style: normal;
  font-size: 16px !important;
  line-height: 24px;
  color: #29323D !important;
  padding: 10px 0px 0px 7px;
}
.dateRangeLabel_NqcKc {
  font-style: normal;
  font-weight: 800;
  font-size: 15px !important;
  line-height: 20px;
  color: #29323D !important;
  padding: 20px 0px 0px 7px;
}
.errorMessage_sDbkW {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #C62E2E !important;
  padding-left: 12px;
}
.flexButtonsNoError_rL0fc {
  padding-top: 55px !important;
}
.flexButtonsError_vhrI8 {
  padding-top: 25px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/devices/ExportInventoryTableDialog.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,kBAAA;AACJ;AAEA;EAEI,kBAAA;EACA,gBAAA;EACA,0BAAA;EACA,iBAAA;EACA,yBAAA;EACA,wBAAA;AADJ;AAIA;EAEQ,kBAAA;EACA,0BAAA;EACA,iBAAA;EACA,yBAAA;EACA,yBAAA;AAHR;AAMA;EAEI,kBAAA;EACA,gBAAA;EACA,0BAAA;EACA,iBAAA;EACA,yBAAA;EACA,yBAAA;AALJ;AAQA;EAEI,6BAAA;EACA,8BAAA;EACA,0BAAA;EACA,4BAAA;EACA,yBAAA;EACA,kBAAA;AAPJ;AAUA;EACI,4BAAA;AARJ;AAWA;EACI,4BAAA;AATJ","sourcesContent":[".exportDialog {\n    width: 432px;\n    height: 304px;\n    border-radius: 8px;\n}\n\n.exportTitle {\n    //font-family: Avenir;\n    font-style: normal;\n    font-weight: 800;\n    font-size: 20px !important;\n    line-height: 24px;\n    color: #3E4243 !important;\n    padding: 7px 0px 0px 7px;\n}\n\n.exportSubTitle {\n        //font-family: Avenir;\n        font-style: normal;\n        font-size: 16px !important;\n        line-height: 24px;\n        color: #29323D !important;\n        padding: 10px 0px 0px 7px;\n}\n\n.dateRangeLabel {\n    //font-family: Avenir;\n    font-style: normal;\n    font-weight: 800;\n    font-size: 15px !important;\n    line-height: 20px;\n    color: #29323D !important;\n    padding: 20px 0px 0px 7px;\n}\n\n.errorMessage {\n    //font-family: avenir;\n    font-style: normal !important;\n    font-weight: normal !important;\n    font-size: 12px !important;\n    line-height: 16px !important;\n    color: #C62E2E !important;\n    padding-left: 12px;\n}\n\n.flexButtonsNoError {\n    padding-top: 55px !important;\n}\n\n.flexButtonsError {\n    padding-top: 25px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exportDialog": `exportDialog_oXxAL`,
	"exportTitle": `exportTitle_oAp1m`,
	"exportSubTitle": `exportSubTitle_uE2AH`,
	"dateRangeLabel": `dateRangeLabel_NqcKc`,
	"errorMessage": `errorMessage_sDbkW`,
	"flexButtonsNoError": `flexButtonsNoError_rL0fc`,
	"flexButtonsError": `flexButtonsError_vhrI8`
};
export default ___CSS_LOADER_EXPORT___;
