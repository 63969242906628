module.exports = {
  "hierarchy": {
    "bottom": [
      "tabPane-bottom"
    ],
    "deviceDetails": [
      "tabPane-deviceDetails"
    ],
    "left": [
      "tabPane-left"
    ]
  },
  "props": {
    "default": {},
    "bottom": {},
    "deviceDetails": {},
    "left": {}
  }
}