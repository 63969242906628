// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentArea_vqV9p {
  height: 100%;
}
.contentArea_vqV9p .mainColumn_DCF4m {
  height: 100%;
}
.contentArea_vqV9p .mainColumn_DCF4m .searchControlsRow_Mwaue {
  padding-left: 16px;
  padding-top: 16px;
}
.contentArea_vqV9p .mainColumn_DCF4m .tableContainer_lkTf0 {
  height: 100%;
}
.contentArea_vqV9p .mainColumn_DCF4m .tableContainer_lkTf0 .table_o_CZ7 {
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/admin/Admin/Users.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAFA;EAII,YAAA;AACJ;AALA;EAOM,kBAAA;EACA,iBAAA;AACN;AATA;EAYM,YAAA;AAAN;AAZA;EAeQ,YAAA;EACA,WAAA;AAAR","sourcesContent":[".contentArea {\n  height: 100%;\n\n  .mainColumn {\n    height: 100%;\n\n    .searchControlsRow {\n      padding-left: 16px;\n      padding-top: 16px;\n    }\n\n    .tableContainer {\n      height: 100%;\n\n      .table {\n        height: 100%;\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentArea": `contentArea_vqV9p`,
	"mainColumn": `mainColumn_DCF4m`,
	"searchControlsRow": `searchControlsRow_Mwaue`,
	"tableContainer": `tableContainer_lkTf0`,
	"table": `table_o_CZ7`
};
export default ___CSS_LOADER_EXPORT___;
