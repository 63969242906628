import React, { useState } from 'react';
import { Flex, TabPane } from '@leverege/ui-elements'
import S from './Tabs.less'

const Tabs = ( { tabsConfig, onTabChanged } ) => {
  const [ activeTabId, setActiveTabId ] = useState( tabsConfig && tabsConfig.length ? tabsConfig[0].tabId : null )

  const selectActiveTab = ( tabId ) => {
    setActiveTabId( tabId )
    if ( onTabChanged ) {
      onTabChanged( tabId )
    }
  }

  return (
    <Flex direction="row" className={S.tabBarRow}>
      <TabPane variant="deviceDetails"
        onTabClicked={( { value } ) => selectActiveTab( value )}>
        {
          tabsConfig.map( ( { key, tabId, title, icon, getComponent } ) => (
            <TabPane.Tab
              key={key}
              tabId={tabId}
              tabName={(
                <Flex variant="rowMA">
                  <object
                    type="image/svg+xml"
                    width="24"
                    height="24"
                    className={activeTabId === tabId ? S.activeTabIcon : S.tabIcon}
                    data={icon}
                    aria-labelledby="" />
                  <div className={S.iconOverlay} onClick={() => selectActiveTab( tabId )} />
                  <div>{title}</div>
                </Flex>
              )}>
              {getComponent()}
            </TabPane.Tab>
          ) )
        }
      </TabPane>
    </Flex>
  )
}

export default Tabs
