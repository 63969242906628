// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinnerContainer_U3eTN {
  position: absolute;
  top: 50%;
  left: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/lib/spinner/Spinner.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;AACF","sourcesContent":[".spinnerContainer {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": `spinnerContainer_U3eTN`
};
export default ___CSS_LOADER_EXPORT___;
