import React from 'react'
import { parse } from 'date-fns'
import { connect } from 'react-redux'
import { Content, Flex } from '@leverege/ui-elements'
import InventoryActions from '../../../actions/InventoryActions'
import InventoryAccessors from '../../../accessors/Inventory'

import Search from '../lib/input/Search'
import DefaultTable from '../lib/table/DefaultTable'
import ActionDropdownBar from '../lib/button/ActionDropdownBar'
import ExportInventoryTableDialog from './ExportInventoryTableDialog'

import S from './Devices.less'

class Inventory extends React.Component {

  state = {
    query : '',
    lastUsedQuery : '',
    showDialog : false,
    exportError : null,
    page : 0,
    perPage : 15,
    dropdownOptions : []
  }

  search = ( value ) => {
    const { query, perPage } = this.state
    const { clear, getInventoryDevices } = this.props

    if ( value || value === '' ) {
      this.setState( { lastUsedQuery : query, page : 0 }, () => {
        clear()
        getInventoryDevices( query, 0, perPage )
      } )
    }

  }

  searchQueryChange = ( { value } ) => {
    this.setState( { query : value } )
  }

  loadAllDevices = () => {
    const { query, page, perPage } = this.state
    const { getInventoryDevices } = this.props
    getInventoryDevices( '', page, perPage )
    this.setState( { lastUsedQuery : query } )
  }

  componentDidMount() {
    // this.loadAllDevices()
    let dropdownOptions = [];

    dropdownOptions = [
      { key : 1, name : 'Export', value : 1, onClick : () => this.onShowDialog() }
    ]

    this.setState( { dropdownOptions } )
  }

  onExport = async ( start, end ) => {
    const { exportInventory } = this.props
    try {
      await exportInventory( start, end, this.getColumns() )
      this.onCloseDialog()
    } catch ( err ) {
      this.setState( { exportError : err } )
    }
  }

  setPage = ( page ) => {
    const { idsPerPage, getInventoryDevices } = this.props
    const { query, perPage } = this.state
    this.setState( { page } )
    if ( !idsPerPage[page] ) {
      getInventoryDevices( query, page, perPage )
    }
  }

  setPageSize = ( perPage ) => {
    const { clear, getInventoryDevices } = this.props
    const { query } = this.state
    this.setState( { page : 0, perPage } )
    clear()
    getInventoryDevices( query, 0, perPage )
  }

  onShowDialog = ( ) => {
    this.setState( {
      showDialog : true,
    } )
  }

  onCloseDialog = () => {
    this.setState( {
      showDialog : false,
      exportError : null
    } )
  }

  getColumns = () => {
    return [
      {
        id : 'esn',
        sortable : true,
        Header : 'ESN',
        accessor : InventoryAccessors.esn,
        className : S.tableESN,
        sortMethod : ( a, b ) => {
          const numA = Number( a )
          const numB = Number( b )
          return numB > numA ? 1 : -1
        }
      },
      {
        id : 'iccid',
        sortable : true,
        Header : 'EID',
        accessor : InventoryAccessors.iccid,  // Currently we have EID in .iccid instead of the ICCID. We should change
      },
      {
        id : 'testResult',
        sortable : true,
        Header : 'Testing Results',
        accessor : InventoryAccessors.testResult,
      },
      {
        id : 'createdAt',
        sortable : true,
        accessor : InventoryAccessors.createdAt,
        Header : 'Manufactured On',
        sortMethod : ( a, b ) => {
          const format = 'E MMM do yyyy HH:mm:ss'
          const now = new Date()
          const numA = parse( a, format, now )
          const numB = parse( b, format, now )
          return numB > numA ? 1 : -1
        }
      },
      {
        id : 'tester',
        sortable : true,
        accessor : InventoryAccessors.tester,
        Header : 'Tested By',
      },
      {
        id : 'firmwareNumber',
        sortable : true,
        accessor : InventoryAccessors.firmwareNumber,
        Header : 'Firmware Number',
      }
    ]
  }

  render() {
    const { query, page, lastUsedQuery, dropdownOptions, exportError, showDialog } = this.state
    const { idsPerPage, lookup, loading, pages } = this.props

    const devices = ( idsPerPage[page] || [] ).map( id => lookup[id] )

    const columns = this.getColumns()

    return (
      <Content className={S.contentArea}>
        <Content.Area>
          <Flex variant="colS" className={S.mainColumn}>

            <Flex variant="rowL">
              <Search
                placeholder="Search by ESN"
                disabled={lastUsedQuery === query}
                value={query}
                onChange={this.searchQueryChange}
                onSearch={this.search} />
              <ActionDropdownBar options={dropdownOptions} />
            </Flex>

            <Flex variant="default" className={S.tableContainer}>
              <DefaultTable
                className={S.table}
                data={devices}
                columns={columns}
                defaultPageSize={15}
                pages={pages}
                defaultSorted={[
                  {
                    id : 'createdAt',
                    desc : false
                  }
                ]}
                onPageChange={page => this.setPage( page )}
                onPageSizeChange={perPage => this.setPageSize( perPage )}
                loading={loading} />

            </Flex>
          </Flex>

          <ExportInventoryTableDialog
            onClose={this.onCloseDialog}
            errorMessage={exportError && exportError.message}
            onExport={this.onExport}
            show={showDialog} />

        </Content.Area>
      </Content>
    )
  }
}

export default connect(
  state => ( {
    inventoryDevices : state.inventory.inventoryDevices,
    idsPerPage : state.inventory.idsPerPage,
    lookup : state.inventory.lookup,
    loading : state.inventory.loading,
    pages : state.inventory.pages,
  } ),
  dispatch => ( {
    getInventoryDevices : ( query, page, perPage ) => dispatch( InventoryActions.getInventoryDevices( query, page, perPage ) ),
    exportInventory : ( start, end, columns ) => dispatch( InventoryActions.exportInventory( start, end, columns ) ),
    clear : () => dispatch( InventoryActions.clearInventory() )
  } )
)( Inventory )
