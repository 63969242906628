import React from 'react'
import { connect } from 'react-redux'
import { Flex } from '@leverege/ui-elements'
import DefaultTable from '../../lib/table/DefaultTable'
import { Track } from '../../../../accessors'
import S from './DeviceGroups.less'

const DeviceGroups = ( { groups } ) => {
  const columns = [
    { Header : 'Name', id : 'name', accessor : Track.name },
    { Header : 'Devices', accessor : 'devices' },
    { Header : 'Main Unit', id : 'main', accessor : Track.mainBinary, className : S.itemName },
    { Header : 'Host', id : 'host', accessor : Track.hostBinary, className : S.itemName },
    { Header : 'Sensor', id : 'sensor', accessor : Track.sensorBinary, className : S.itemName },
    { Header : 'Repeater', id : 'repeater', accessor : Track.repeaterBinary, className : S.itemName },
  ]

  return (
    <Flex variant="colM" className={S.contentArea}>
      <DefaultTable
        className={S.table}
        data={groups}
        columns={columns}
        defaultPageSize={15}
        loading={false} />
    </Flex>
  )
}

export default connect( ( ) => ( { } ) )( DeviceGroups )
