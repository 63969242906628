module.exports = {
  "hierarchy": {
    "small": [
      "breadcrumbs-small"
    ]
  },
  "props": {
    "default": {
      "separatorIcon": "fa fa-chevron-right"
    },
    "small": {
      "separatorIcon": "fa fa-chevron-right"
    }
  }
}