module.exports = {
  "hierarchy": {
    "small": [
      "toggleButton-small"
    ],
    "link": [
      "toggleButton-link"
    ],
    "navLink": [
      "toggleButton-navLink"
    ],
    "logoutLink": [
      "toggleButton-link",
      "toggleButton-logoutLink"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "link": {},
    "navLink": {},
    "logoutLink": {}
  }
}