module.exports = {
  "hierarchy": {
    "success": [
      "toast-success"
    ],
    "error": [
      "toast-error"
    ],
    "warn": [
      "toast-warn"
    ],
    "info": [
      "toast-info"
    ]
  },
  "props": {
    "default": {},
    "success": {},
    "error": {},
    "warn": {},
    "info": {}
  }
}