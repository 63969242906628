import React, { Component } from 'react'
import { Button, Flex, Text, TextInput } from '@leverege/ui-elements'
import S from './Paginator.less'


export default class Pagination extends Component {

  constructor( props ) {
    super()

    this.state = {
      data : props.data,
      page : 0,
      pageSize : 15
    }

    props.onPageChange( 0 )
    props.onPageSizeChange( 15 )
  }

  componentWillReceiveProps( nextProps ) {
    const { data } = this.state

    if ( data !== nextProps.data || data.length !== nextProps.data.length ) {
      this.setState( {
        data : nextProps.data
      } )
    }
  }

  nextPage() {
    const { page, pageSize, data } = this.state
    const { onPageChange, pages } = this.props

    const newPage = ( pages - 1 ) > page ?
      page + 1 : data.length > pageSize && ( ( page + 1 ) * pageSize ) < data.length ?
        page + 1 : page

    this.setState( { page : newPage } )
    onPageChange( newPage )
  }

  previousPage() {
    const { page } = this.state
    const { onPageChange } = this.props

    const newPage = page ? page - 1 : page

    this.setState( { page : newPage } )
    onPageChange( newPage )
  }

  onPageChange( page ) {
    const { onPageChange } = this.props

    const newPage = Number( page )

    this.setState( { page : newPage } )
    onPageChange( newPage )
  }

  onPageSizeChange( pageSize ) {
    const { onPageChange, onPageSizeChange } = this.props

    const newPageSize = Number( pageSize )

    this.setState( { page : 0, pageSize : newPageSize } )
    onPageChange( 0 )
    onPageSizeChange( newPageSize )
  }

  getVisibleRowsInfo() {
    const { page, pageSize, data } = this.state
    
    let total
    total = page * pageSize + pageSize

    if ( data.length > 0 ) {

      if ( ( page * pageSize + pageSize ) > data.length ) {
        return `${page * pageSize + 1} - ${total}`
      }
      return `${page * pageSize + 1} - ${page * pageSize + pageSize}`
    }
    return `0 of 0`
  }

  render() {
    const { page, pageSize, data } = this.state

    return (
      <Flex variant="paginator" className={S.paginator}>
        <Flex variant="rowLA">
          <TextInput variant="paginator"
            type="number"
            value={String( pageSize )}
            min={1}
            max={data.length}
            onChange={( { value } ) => this.onPageSizeChange( value )}
            className={S.paginatorInput} />
          <Text>rows per page</Text>
        </Flex>
        <Flex variant="rowLA">
          <Button variant="pageBack" onClick={() => this.previousPage()} />
          <TextInput variant="paginator"
            type="number"
            min={0}
            max={Math.floor( data.length / pageSize )}
            value={String( page + 1 )}
            className={S.paginatorInput} />
          <Button variant="pageNext" onClick={( { value } ) => this.nextPage( value )} />
        </Flex>
        <Text>{this.getVisibleRowsInfo()}</Text>
      </Flex>
    )
  }
}
