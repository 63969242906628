import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Text, Content, Form, Flex, Label, Toast, Button } from '@leverege/ui-elements'
import ArrayUtil from '@leverege/array-util'
import { remove } from 'lodash'

import DeviceListEditor from './DeviceListEditor'
import FirmwareListEditor from './FirmwareListEditor'
import TrackService from '../../../services/TrackService'
import { FirmwareActions, TrackActions } from '../../../actions'
import Spinner from '../lib/spinner/Spinner'
import S from './DeploymentTrackEditor.less'

let validator
class DeploymentTrackEditor extends React.Component {

  constructor( props ) {
    super( props )

    this.state = {
      key : Date.now(),
      loading : false,
      groupSelected : {}
    }

    this.validator = {
      fields : {
        name : {

        },
        devices : {

        },
        firmwares : {

        }
      }
    }
  }

  componentDidMount() {
    const { getAllFirmwares } = this.props
    
    getAllFirmwares()
    this.getGroupSelected()
  }

  onClose = () => {
    const { dispatch } = this.props
    dispatch( push( '/groups' ) )
  }

  async getGroupSelected() {
    const { trackId } = this.props
    const trackService = new TrackService()
    try {
      const data = await trackService.getTrackGroupSelected( trackId )
      this.setState( { groupSelected : data } )
    } catch ( error ) {
      // TODO: add error dialog
    }
  }

  arrayDevicesDiff = ( devices ) => {
    const { added, removed } = devices
    let { same } = devices
    const sameDevs = added.filter( add => removed.find( rem => add.id === rem.id ) )

    if ( sameDevs && sameDevs.length > 0 ) {
      remove( added, add => sameDevs.find( same => same.id === add.id ) )
      remove( removed, rem => sameDevs.find( same => same.id === rem.id ) )
      same = same.concat( sameDevs )
    }

    return { added, removed, same }
  }

  onSubmit = ( values ) => {
    if ( values ) {
      const { value } = values
      const { dispatch, trackId, afterGroupUpdated = () => {} } = this.props

      let devices = ArrayUtil.diff( validator.getDefaultData().devices, value.devices )
      devices = this.arrayDevicesDiff( devices )

      const firmwares = ArrayUtil.diff( validator.getDefaultData().firmwares, value.firmwares )
      const data = {
        ...value,
        devices,
        firmwares
      }

      let promise

      if ( !trackId ) {
        promise = dispatch( TrackActions.createTrack( data ) )
          .then( ( { track, devices } ) => {
            devices.forEach( ( item ) => {
              if ( item.status && item.status >= 300 ) {
                let message = item.message

                if ( item.status === 404 ) {
                  message = `Unknown device: ${item.message.substring( item.message.indexOf( '/' ) + 1 )}`
                }
                Toast.error( message, { containerId : 'toastDefault', autoClose : false } )
              }
            } )

            this.setState( { key : Date.now() } )
          } )
      } else {
        promise = dispatch( TrackActions.updateTrack( trackId, data ) )
          .then( ( res ) => {
            this.setState( { key : Date.now() } )
          } )
      }

      this.setState( { loading : true } )

      promise
        .then( () => {
          Toast.success(
            `Successfully ${!trackId ? 'added' : 'updated'} group`,
            { containerId : 'toastDefault' }
          )

          this.setState( { loading : false } )

          afterGroupUpdated()
        } )
        .catch( ( err ) => {
          Toast.error(
            err.message,
            { autoClose : 8000, containerId : 'toastDefault' }
          )

          this.setState( { loading : false } )
        } )
    }
  }


  renderForm = ( v ) => {
    const { loading, } = this.state
    const { trackId, onClose = () => {}, firmware } = this.props
    const { loading : firmwareLoading, activeFirmwares : firmwares = [] } = firmware

    const isAdd = !trackId
    validator = v

    return (
      <Content relative className={S.content}>
        <Content.Header className={S.header}>
          <Flex direction="row" className={S.headerRow} justify="space-between">
            <Text className={S.headerTitle}>{isAdd ? 'New Group' : 'Edit Group'}</Text>
            <img src="/images/cross.png" alt="close" className={S.crossButton} onClick={() => onClose()} />
          </Flex>
        </Content.Header>
        <Content.Area className={S.contentArea}>
          { ( loading || firmwareLoading ) && <Spinner /> }
          { !loading && !firmwareLoading && (
            <Flex direction="column" className={S.editBlock}>
              <Flex variant="inputGroup">
                <Label variant="fixedWidth" htmlFor="name">Name</Label>
                <Form.TextInput variant="portalForm" name="name" />
              </Flex>
              <Flex variant="inputGroup">
                <DeviceListEditor trackId={trackId} name="devices" />
              </Flex>
              <Flex variant="inputGroup">
                <FirmwareListEditor trackId={trackId} name="firmwares" firmwares={firmwares} />
              </Flex>
            </Flex>
          ) }
        </Content.Area>
        <Content.Footer className={S.footer}>
          <Flex variant="buttons" className={S.footerRow}>
            <Form.Submit onClick={() => this.onSubmit()}>{isAdd ? 'Add Group' : 'Save changes'}</Form.Submit>
            <Button onClick={() => onClose()}>Close</Button>
          </Flex>
        </Content.Footer>
      </Content>
    )
  }

  render() {
    const { trackId, trackList } = this.props
    const { key, groupSelected } = this.state
    let data = {}


    if ( trackId ) {
      const track = trackList.find( t => t.id === trackId )

      data = {
        ...track,
        devices : groupSelected && groupSelected.devices && groupSelected.devices.items,
        firmwares : groupSelected && groupSelected.firmwares && groupSelected.firmwares.items
      }
    }

    return (
      <React.Fragment>
        <Form
          className={S.form}
          key={key}
          eventData={trackId}
          validator={this.validator}
          render={this.renderForm}
          onSubmit={this.onSubmit}
          data={data} />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ( {
  getAllFirmwares : () => dispatch( FirmwareActions.getAllFirmwares() ),
  dispatch  
} )

export default connect( state => ( {
  trackList : state.track.trackList,
  model : state.data,
  firmware : state.firmware,
  ...state.track
} ), mapDispatchToProps )( DeploymentTrackEditor )
