// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginator_WH0ud {
  width: 100%;
  height: 64px;
  min-height: 64px;
}
.paginator_WH0ud .paginatorInput_qiNjG {
  width: 72px;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/lib/table/Paginator.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AACF;AAJA;EAMI,WAAA;AACJ","sourcesContent":[".paginator {\n  width: 100%;\n  height: 64px;\n  min-height: 64px;\n\n  .paginatorInput {\n    width: 72px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": `paginator_WH0ud`,
	"paginatorInput": `paginatorInput_qiNjG`
};
export default ___CSS_LOADER_EXPORT___;
