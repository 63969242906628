import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Button, Flex, Text } from '@leverege/ui-elements'
import { doneWithBackupCodes } from '../../../actions/UserActions'

import S from './TwoFactor.css'

function OneTimeCodes( props ) {
  const { auth, doneWithBackupCodes } = props

  const onSubmit = async () => {
    doneWithBackupCodes( auth.profile, auth.idToken )
  }

  return (
    <React.Fragment>
      <Text variant="contentTitle" style={{ marginBottom : '1vh' }}>Save These Backup Codes</Text>
      <Text variant="default" style={{ marginBottom : '1vh', wordWrap : 'break-word', maxWidth : '400px' }}>
        Save or print these codes and store them in a safe place.
        {' '}
        If you have trouble receiving a security code in the future,
        {' '}
        you can use one of these codes instead.
      </Text>
      <Flex
        justify="space-between"
        align="center"
        className={classnames( S.cardSection, S.codes )}>
        <Flex style={{ flexWrap : 'wrap' }}>
          {Object.keys( auth.oneTimeCodes ).map( ( code, index ) => {
            return (
              <div key={code} className={S.code}>
                {index + 1}
                .
                {' '}
                {code}
              </div>
            )
          } )}
        </Flex>
      </Flex>
      <Button 
        onClick={onSubmit}
        className={classnames( S.goToShip )}>
        Go to SHIP
      </Button>
    </React.Fragment>
  )
}

export default connect(
  state => ( {
    auth : state.auth,
  } ),
  dispatch => ( {
    doneWithBackupCodes : ( profile, idToken ) => dispatch( doneWithBackupCodes( profile, idToken ) ),
  } )
)( OneTimeCodes )
