// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_WZOZn {
  display: flex;
  flex-direction: row;
}
.pill__iNac {
  background: #E7EAEF;
  color: #394756;
  border-radius: 12px;
  height: 28px;
  padding: 4px 8px;
  margin: 0 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/firmware/Firmware/FirmwareTypePills.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAEA;EACE,mBAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;AAAF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n}\n\n.pill {\n  background: #E7EAEF;\n  color: #394756;\n  border-radius: 12px;\n  height: 28px;\n  padding: 4px 8px;\n  margin: 0 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container_WZOZn`,
	"pill": `pill__iNac`
};
export default ___CSS_LOADER_EXPORT___;
