import React, { Component } from 'react'
import { Button, Content, Dialog, Flex, Text, DateTimeRangePicker } from '@leverege/ui-elements'
import moment from 'moment'

import S from './ExportInventoryTableDialog.less'

class ExportInventoryTableDialog extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      start : '',
      end : '',
    }
  }

  getStartAndEnd = ( event ) => {
    if ( event.value === 'today' ) {
      const start = moment( ).subtract( 1, 'day' ).startOf( 'day' ).valueOf()
      const end = Date.now()

      this.setState( { start, end } )

    } else if ( event.value === 'yesterday' ) {
      const start = moment().subtract( 1, 'day' ).startOf( 'day' ).valueOf()
      const end = moment( ).subtract( 1, 'day' ).endOf( 'day' ).valueOf()

      this.setState( { start, end } )
    } else if ( event.value === 'last7Days' ) {
      const start = moment().subtract( 7, 'day' ).valueOf()
      const end = Date.now()

      this.setState( { start, end } )
    } else if ( event.value === 'last30Days' ) {
      const start = moment().subtract( 30, 'day' ).valueOf()
      const end = Date.now()

      this.setState( { start, end } )
    } else if ( event.value === 'last24Hours' ) {
      const start = moment().subtract( 1, 'day' ).valueOf()
      const end = Date.now()

      this.setState( { start, end } )
    } else {
      const start = moment( event.value.start ).valueOf()
      const end = moment( event.value.end ).valueOf()

      this.setState( { start, end } )
    }
  }

  render() {
    const { start, end } = this.state
    const { show, onClose, onExport, errorMessage } = this.props
    return (
      <Dialog show={show} onClose={onClose} className={S.exportDialog}>
        <Content relative>
          <Content.Header>
            <Text className={S.exportTitle}>Export Devices</Text>
          </Content.Header>
          <Content.Area>
            <Flex variant="colM">
              <Flex>
                <Text className={S.exportSubTitle}>Export device data of devices manufactured within the date range selected below.</Text>
              </Flex>
            </Flex>
            <Flex variant="colM">
              <Flex>
                <Text className={S.dateRangeLabel}>Select Date Range</Text>
              </Flex>
              <Flex>
                <DateTimeRangePicker
                  dateFormat="MM-dd-yy"
                  onChange={event => this.getStartAndEnd( event )} />
              </Flex>
              <Flex>
                {errorMessage ? <Text className={S.errorMessage}>There is no device information to export for this date range. Please select a new date range.</Text> : <span> </span>}
              </Flex>
            </Flex>
          </Content.Area>
          <Content.Footer>
            <Flex variant="buttons" className={!errorMessage ? S.flexButtonsNoError : S.flexButtonsError}>
              <Button onClick={() => onExport( start, end )}>Export</Button>
              <Button variant="cancelButton" onClick={onClose}>Close</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </Dialog>
    )
  }
}

export default ExportInventoryTableDialog
