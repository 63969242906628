const Constants = require( '../actions/Constants' );

const initialState = {
  loading : false,
  inventoryDevices : [],
  idsPerPage : {},
  lookup : {},
  pages : {},
}

function inventory( state = initialState, action ) {

  switch ( action.type ) {

    case Constants.INVENTORY_LOADING: {
      const { loading } = action.payload
      return {
        ...state,
        loading
      }
    }

    case Constants.INVENTORY_CLEAR: {
      return {
        ...initialState
      }
    }

    case Constants.INVENTORY_LOADED: {
      const { items, total, perPage, page } = action.payload
      const ids = items.map( c => c.id )
      const lookup = {}
      ids.forEach( ( id, index ) => {
        lookup[id] = items[index]
      } )
      const nPages = Math.ceil( total / perPage )
      /* const index = nPages.findIndex( p => p === page )
      const nIndex = nPages.findIndex( p => p === nextPage )
      if ( nIndex < 0 && index >= 0 && nextPage ) {
        nPages.splice( index + 1, 0, nextPage )
      }*/
      return {
        ...state,
        loading : false,
        pages : nPages,
        idsPerPage : {
          ...state.idsPerPage,
          [page] : ids,
        },
        lookup : {
          ...lookup,
          ...state.lookup
        }
      }
    }

    default:
      return state
  }

}

module.exports = inventory
