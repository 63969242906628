module.exports = {
  "hierarchy": {
    "small": [
      "label-small"
    ],
    "left": [
      "label-left"
    ],
    "fixedWidth": [
      "label-fixedWidth"
    ],
    "title": [
      "label-title"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "left": {},
    "fixedWidth": {},
    "title": {}
  }
}