import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Flex, Toast } from '@leverege/ui-elements'

import TableWithSelectableRowsAndActions from '../../lib/table/TableWithSelectableRowsAndActions'
import Spinner from '../../lib/spinner/Spinner'
import ConfirmationDialog from '../../lib/dialog/ConfirmationDialog'

import DeviceNMEAAccessors from '../../../../accessors/DeviceNMEA'
import DeviceActions from '../../../../actions/DeviceActions'
import Nmea from '../../../../util/Nmea'

import S from './DeviceGroups.less'

const DeviceNMEA = ( { device, getDevice, onChangeDropdownOptions, deleteNmeaChannels } ) => {
  const [ nmea, setNmea ] = useState( Nmea.getNmeaData( device?.data?.vessel?.nmea, device?.dataMetadata?.vessel?.nmea ) )
  const [ loading, setLoading ] = useState( true )
  const [ selected, setSelected ] = useState( [] )
  const [ showDialog, setShowDialog ] = useState( false )

  useEffect( () => {
    setNmea( Nmea.getNmeaData( device?.data?.vessel?.nmea, device?.dataMetadata?.vessel?.nmea ) )
  }, [ device ] )

  useEffect( () => {
    const fetchData = async () => {
      if ( !nmea ) {
        await getDevice()
      }
      setLoading( false )
      setSelected( [] )
    }
    fetchData()
  }, [ getDevice ] )

  useEffect( () => {
    let dropdownOptions = []

    const qty = selected.length
    if ( qty > 0 ) {
      const label = qty === 1 ? 'Delete Channel (1)' : `Delete Channels (${qty})`
      dropdownOptions = [
        { key : 1, name : label, onClick : () => setShowDialog( true ) }
      ]
    }

    onChangeDropdownOptions( dropdownOptions )
  }, [ selected ] )

  const deleteChannels = async () => {
    try {
      const channels = selected.map( c => c.channel )
      setLoading( true )
      await deleteNmeaChannels( channels )
      await getDevice( device?.id )
      setLoading( false )
      setShowDialog( false )

      const msg = `The NMEA Channel${selected.length > 1 ? 's were' : ' was'} deleted successfully`
      Toast.success( msg, { containerId : 'toastDefault' } )
    } catch ( err ) {
      console.error( err )
      Toast.error(
        `Error on deleting the NMEA Channel${selected.length > 1 ? 's' : ''}`,
        { containerId : 'toastDefault' }
      )
    }
  }

  const columns = [
    { Header : 'Channel Number', id : 'channel', accessor : 'channel' },
    { Header : 'Alias', accessor : 'alias' },
    { Header : 'Type', id : 'type', accessor : DeviceNMEAAccessors.type },
    { Header : 'Label', id : 'label', accessor : DeviceNMEAAccessors.label },
    { Header : 'Last Update', id : 'lastUpdate', accessor : DeviceNMEAAccessors.lastUpdate },
    { Header : 'Show in App', id : 'display', accessor : DeviceNMEAAccessors.display },
    { Header : 'Class Code', accessor : 'classCode' },
    { Header : 'Function Code', accessor : 'functionCode' },
    { Header : 'Manufacturer Code', accessor : 'manufacturerCode' }
  ]

  const qty = selected.length
  let removalText = `Are you sure you want to remove ${qty === 1 ? 'this NMEA Channel' : `these ${qty} NMEA channels`}`
  removalText = `${removalText}? This action can not be undone.`

  return (
    <Flex variant="colM" className={S.contentArea}>
      {loading ? <Spinner /> : (
        <TableWithSelectableRowsAndActions
          className={S.table}
          data={nmea}
          columns={columns}
          loading={false}
          onSelectionChange={setSelected} />
      )}

      <ConfirmationDialog
        show={showDialog}
        headerText={`Delete NMEA Channel${qty > 1 ? 's' : ''}`}
        bodyText={removalText}
        onDeny={() => setShowDialog( false )}
        onConfirm={() => deleteChannels()} />
    </Flex>
  )
}

export default connect(
  () => ( {} ),
  dispatch => ( {
    deleteNmeaChannels : channels => dispatch( DeviceActions.deleteNmeaChannels( channels ) )
  } )
)( DeviceNMEA )
