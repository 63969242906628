import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content, Flex, PortalOverlay, Toast } from '@leverege/ui-elements'
import Search from '../../lib/input/Search'
import S from './Users.less'
import TableWithSelectableRowsAndActions from '../../lib/table/TableWithSelectableRowsAndActions'
import { getAllUsers, deleteUser, deleteMultipleUsers } from '../../../../actions/UserActions'
import { isValidEmail } from '../../../../util/Email'
import UserEditor from './UserEditor'
import ActionDropdownBar from '../../lib/button/ActionDropdownBar'

class Users extends Component {

  static get OVERLAY_PORTAL_MODE() {
    return {
      HIDE : 'hide',
      EDIT : 'edit'
    }
  }

  state = {
    query : '',
    lastUsedQuery : '',
    selectedUsers : [],
    overlayPortalMode : Users.OVERLAY_PORTAL_MODE.HIDE,
    clickedUserId : null,
    dropdownOptions : []
  }

  search = () => {
    const { query } = this.state
    const { getAllUsers } = this.props
    
    if ( query && !isValidEmail( query ) ) {
      Toast.error(
        'Please insert a valid email!',
        { autoClose : 8000, containerId : 'toastDefault' }
      )
      return
    }

    getAllUsers( query )
    this.setState( { lastUsedQuery : query } )
  }

  searchQueryChange = ( { value } ) => {
    this.setState( { query : value } )
  }

  onDeviceSelectionChange( selectedUsers ) {
    this.setState( { selectedUsers } )

    let dropdownOptions = []

    // TODO: auth migration -> add/delete user means add/remove ship roles from existing firebase/platform user?
    // ...or, add/delete user means create/destroy firebase/platform user + add/remove ship roles?
    if ( selectedUsers.length === 0 ) {
      dropdownOptions = [
        { key : 1, name : 'Add User', value : 1, onClick : () => this.setPortalOverlayMode( Users.OVERLAY_PORTAL_MODE.EDIT ) }
      ]
    } else {
      dropdownOptions = [
        { key : 1, name : `Delete Users (${selectedUsers.length})`, value : 1, onClick : () => this.handleDelete() }
      ]
    }

    this.setState( { dropdownOptions } )
  }

  async handleDelete() {
    try {
      const { selectedUsers } = this.state
      const { deleteUser, deleteMultipleUsers } = this.props

      if ( selectedUsers.length === 1 ) {
        const userId = selectedUsers[0].id
        await deleteUser( userId )
        Toast.success(
          `Successfully removed SHIP permissions from ${selectedUsers[0].email}`,
          { containerId : 'toastDefault' }
        )
      } else {
        const userIds = selectedUsers.map( user => user.id )
        await deleteMultipleUsers( userIds )
        selectedUsers.forEach( selectedUser => Toast.success(
          `Successfully removed SHIP permissions from ${selectedUser.email}`,
          { containerId : 'toastDefault' }
        ) )

      }
      this.onDeviceSelectionChange( [] )
    } catch ( ex ) {
      Toast.error(
        ( ex && ex.message ) || 'An unknown error happened. Please, try again later.',
        { autoClose : 8000, containerId : 'toastDefault' }
      )
    }
  }

  getUserShipRoleIds = ( user ) => {
    return user?.roles?.filter( role => role?.name?.startsWith( 'Ship' ) )?.map( shipRole => shipRole?.id )
  }

  onClosePortalOverlay = () => {
    this.setState( {
      overlayPortalMode : Users.OVERLAY_PORTAL_MODE.HIDE,
      clickedUserId : null
    } )

    this.search()
  }

  onRowClicked = ( row ) => {
    this.setState( {
      overlayPortalMode : Users.OVERLAY_PORTAL_MODE.EDIT,
      clickedUserId : row.id
    } )
  }

  setPortalOverlayMode = ( overlayPortalMode ) => {
    this.setState( { overlayPortalMode } )
  }

  componentDidMount() {
    this.search()
    this.onDeviceSelectionChange( [] )
  }

  render() {
    const { query, lastUsedQuery, dropdownOptions, overlayPortalMode, clickedUserId } = this.state
    const { users, loading } = this.props

    const RoleCell = ( x ) => {
      const rolesArr = x?.original?.roles?.filter( r => r?.name?.startsWith( 'Ship' ) )
      // Default to ShipEngineer
      let roleName = 'ShipEngineer'
      const userRoleNames = rolesArr?.map( r => r.name )
      if ( userRoleNames?.includes( 'ShipAdmin' ) ) {
        roleName = 'ShipAdmin'
      } else if ( userRoleNames?.includes( 'ShipSupport' ) ) {
        roleName = 'ShipSupport'
      }
      return rolesArr?.find( r => r?.name === roleName ).displayName
    }

    const columns = [
      { Header : 'Email', id : 'email', accessor : 'email' },
      { Header : 'Role', id : 'role', Cell : RoleCell },
    ]
    
    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <Flex direction="column" className={S.mainColumn}>
            <Flex direction="row" variant="rowL" className={S.searchControlsRow}>
              <Search
                placeholder="Search by Email"
                disabled={lastUsedQuery === query}
                value={query}
                onChange={this.searchQueryChange}
                onSearch={this.search} />
              <ActionDropdownBar options={dropdownOptions} />
            </Flex>

            <Flex variant="default" className={S.tableContainer}>
              <TableWithSelectableRowsAndActions
                className={S.table}
                data={users}
                columns={columns}
                defaultPageSize={15}
                onSelectionChange={devices => this.onDeviceSelectionChange( devices )}
                onRowClick={row => this.onRowClicked( row )} 
                loading={loading} />
            </Flex>

            <PortalOverlay
              show={overlayPortalMode !== Users.OVERLAY_PORTAL_MODE.HIDE}
              targetId="mainScreen"
              animation="slideFromRight"
              position="right">
              <div>
                {
                  overlayPortalMode === Users.OVERLAY_PORTAL_MODE.EDIT && (
                    <UserEditor 
                      userId={clickedUserId} 
                      onClose={this.onClosePortalOverlay} />
                  )
                }
              </div>
            </PortalOverlay>

          </Flex>
        </Content.Area>
      </Content>
    )
  }
}

export default connect( 
  state => ( {
    users : state.user.users,
    loading : state.user.loading
  } ),
  dispatch => ( {
    getAllUsers : query => dispatch( getAllUsers( query ) ),
    deleteUser : userId => dispatch( deleteUser( userId ) ),
    deleteMultipleUsers : usersIds => dispatch( deleteMultipleUsers( usersIds ) )
  } )
)( Users )
