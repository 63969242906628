import React from 'react';
import { Flex } from '@leverege/ui-elements'
import S from './Spinner.less'


export default class Spinner extends React.Component {
  render() {
    return (
      <Flex className={S.spinnerContainer} justify="center">
        <i className="fa fa-spinner fa-spin fa-2x fa-fw" />
      </Flex>
    );
  }
}
