import Path from '@leverege/path'
import { Boat, Format } from '@leverege/siren-marine-format'
import { format } from 'date-fns'
import compareVersions from 'compare-versions'
import { isMarine } from '../util/Flavors'
import Util from '../util/Util'

const esn = ( sensor ) => {
  return sensor?.data?.mobileId?.toUpperCase() || ''
}

const name = new Path( 'data/name' ).get

const sensorType = new Path( 'data/type' ).get

const formatUpdateTime = ( value ) => {
  // 'updated' is raw timestamp e.g. 1677776690779
  const updated = value?.updateTime
  if ( updated ) {
    return format( updated, 'E MMM do yyyy HH:mm:ss' )
  }
  return ''
}

const sensorLastUpdate = sensor => formatUpdateTime( sensor?.data )


const firmwareVersion = ( sensor, { device } ) => {

  if ( sensor.data ) {
    const isS3P = device?.esn?.length > 10
    const { firmwareVersion } = sensor.data
    if ( !firmwareVersion || firmwareVersion === '' ) {
      return 'Not Reported'
    }

    if ( !isS3P ) {
      return firmwareVersion
    }

    const parsedVersion = Util.splitByLen( firmwareVersion, 2 )
      .map( part => `${part}`.padStart( 2, '0' ) )
      .map( part => part.toUpperCase() )
      .map( part => Util.replaceAll( part, /[^0-9]/, '9' ) )
      .join( '.' )
    return `00.${parsedVersion}`
  } 
  return ''
  
}

const value = ( sensor ) => {
  if ( sensor.data ) {
    const value = Boat.getWirelessSensorValue( sensor.data )
    const type = sensor.data.type
    return Format.wirelessSensor( value, type )
  } 
  return ''
  
}

const rssi = ( sensor, { device } ) => {

  if ( !sensor.data ) return ''
  const isS3P = device?.esn?.length > 10

  if ( !isS3P ) {
    return Format.rssi( sensor.data.rssi )
  } 
      
  const deviceMainFirmwareVersionNumber = device.data.hasOwnProperty( 'versionInfo' ) ? device.data.versionInfo.files.main : '0.0.0'
  const deviceMainFirmwareVersionNumberCheckAgainst = isMarine ? process.env.FIRMWARE_VERSION_SIRENMARINE : process.env.FIRMWARE_VERSION_POWERTECH
  const mainFirmwareVersionNumberOutdated = compareVersions.compare( deviceMainFirmwareVersionNumber, deviceMainFirmwareVersionNumberCheckAgainst, '<=' )

  if ( mainFirmwareVersionNumberOutdated ) {
    return Format.rssi( sensor.data.rssi )
  } 

  return sensor.data.rssiV2  
}

const battery = ( sensor ) => {
  const voltage = sensor?.data?.internalBattery?.voltage
  if ( voltage ) {
    return Format.volts( voltage )
  }
  return ''
}

module.exports = {
  esn,
  name,
  sensorType,
  sensorLastUpdate,
  firmwareVersion,
  value,
  rssi,
  battery
}
