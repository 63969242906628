import React from 'react'
import { connect } from 'react-redux'
import { Flex } from '@leverege/ui-elements'
import { capitalize } from 'lodash'

import DefaultTable from '../../lib/table/DefaultTable'
import S from './DeviceFirmware.less'
import { DeviceFirmware as DeviceFirmwareAcessor } from '../../../../accessors'
import FirmwareTypePills from '../../firmware/Firmware/FirmwareTypePills'

const DeviceFirmware = ( { firmwareVersionHistory } ) => {
  let items
  if ( firmwareVersionHistory !== undefined ) {
    const firmwareHistory = firmwareVersionHistory
      .map( ( { version } ) => version )

    items = firmwareHistory.map( ( fHist ) => {
      const keys = Object.keys( fHist )
      return { ...fHist[keys[0]], binaries : fHist }
    } )
  }
  const columns = [
    { Header : 'Name', accessor : 'version', id : 'version' },
    { Header : 'Status', Cell : x => capitalize( String( x.original.status ) ) },
    { Header : 'Date', accessor : DeviceFirmwareAcessor.sent, id : 'datetime' },
    {
      Header : 'Type',
      id : 'type',
      width : 400,
      Cell : x => <FirmwareTypePills binaries={x.original.binaries} />
    }
  ]

  return (
    <Flex variant="colM" className={S.contentArea}>
      <DefaultTable
        className={S.table}
        data={items}
        columns={columns}
        loading={false} />
    </Flex>
  )
}

export default connect( ( ) => ( { } ) )( DeviceFirmware )
