import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, Text, Toast, Button, Content, TextInput } from '@leverege/ui-elements'
import { CustomerActions } from '../../../actions'
import S from './CustomerEditor.less'


class CustomerEditor extends Component {

  state = {
    email : ''
  }

  onSubmit = async () => {
    const { dispatch, customerId, afterSaveChanges = () => {} } = this.props
    const { email } = this.state

    if ( customerId ) {
      Toast.error(
        'Edit customer functionality currently is not supported',
        { autoClose : 8000, containerId : 'toastDefault' }
      )
    } else {
      try {
        const res = await dispatch( CustomerActions.create( { email } ) )
        const { id, message } = res

        if ( id ) {
          Toast.success(
            'Successfully created customer',
            { containerId : 'toastDefault' }
          )

          afterSaveChanges( id )
        } else {
          Toast.error( message, { containerId : 'toastDefault' } )
        }
      } catch ( err ) {
        Toast.error(
          err.message,
          { autoClose : 8000, containerId : 'toastDefault' }
        )
      }
    }
  }

  render() {
    const { lookup, customerId, onClose } = this.props
    const customer = customerId ? lookup[customerId] : {}

    return (
      <React.Fragment>
        <Content relative className={S.content}>
          <Content.Header className={S.header}>
            <Flex direction="row" className={S.headerRow} justify="space-between">
              <Text className={S.headerTitle}>
                Edit&nbsp;
                {customer.email}
              </Text>
              <div onClick={() => onClose()}>
                <img src="/images/cross.png" alt="close" className={S.crossButton} />
              </div>
            </Flex>
          </Content.Header>
          <Content.Area className={S.contentArea}>
            <Flex direction="column" className={S.infoBlock}>
              <Flex direction="row" className={S.infoTitle}>
                Email
              </Flex>
              <TextInput className={S.info} value={customer.email} />
            </Flex>
          </Content.Area>
          <Content.Footer className={S.footer}>
            <Flex variant="buttons" className={S.footerRow}>
              <Button onClick={() => this.onSubmit()}>{customerId ? 'Save Changes' : 'Add Customer'}</Button>
              <Button onClick={() => onClose()}>Close</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </React.Fragment>
    )
  }
}

export default connect( state => ( {
  lookup : state.customer.lookup,
  author : state.auth.profile && state.auth.profile.email
} ) )( CustomerEditor )
