// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.propertyGrid_IVc9m {
  background-color: var(--color-background2);
}
.propertyGrid_IVc9m.propertyGrid-vertical_Eq8e4 {
  background-color: var(--color-background2);
}
.propertyGrid_IVc9m.propertyGrid-twoColumns_G8AZT {
  background-color: var(--color-background2);
}
`, "",{"version":3,"sources":["webpack://./src/theme/PropertyGrid.css"],"names":[],"mappings":"AAAA;EACE,0CAAA;AACF;AACA;EACE,0CAAA;AACF;AACA;EACE,0CAAA;AACF","sourcesContent":[".propertyGrid {\n  background-color: var( --color-background2 );\n}\n.propertyGrid.propertyGrid-vertical {\n  background-color: var( --color-background2 );\n}\n.propertyGrid.propertyGrid-twoColumns {\n  background-color: var( --color-background2 );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"propertyGrid": `propertyGrid_IVc9m`,
	"propertyGrid-vertical": `propertyGrid-vertical_Eq8e4`,
	"propertyGrid-twoColumns": `propertyGrid-twoColumns_G8AZT`
};
export default ___CSS_LOADER_EXPORT___;
