import Path from '@leverege/path'
import { format, parseISO } from 'date-fns'

const name = new Path( 'email' ).get
const email = new Path( 'email' ).get

const getParseData = ( field ) => {
  return ( data ) => {
    try {
      const time = new Path( field ).get( data )      
      if ( time ) {        
        const raw = parseISO( new Date( time ).toISOString() )         
        if ( raw ) {
          return format( raw, 'MM/dd/yyyy HH:mm' )
        }
      }
    } catch ( e ) {}
    return '-'
  }
}
const createdAt = getParseData( 'creationTime' )
const lastSignIn = getParseData( 'lastSignInTime' )

const ITEM_TYPE = 'customer'
const ITEMS_TARGETED = `${ITEM_TYPE}sTargeted`
const ITEMS_SELECTED = `${ITEM_TYPE}sSelected`

module.exports = {
  name,
  email,
  createdAt,
  lastSignIn,
  ITEM_TYPE,
  ITEMS_TARGETED,
  ITEMS_SELECTED
}
