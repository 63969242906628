const { Constants } = require( '@leverege/api-redux' );
const { getSystemFromBrowserUrl } = require( '../util/System' )

module.exports = {
  AUTH_LOGGED_IN : Constants.AUTH_LOGGED_IN,
  AUTH_LOGGED_OUT : Constants.AUTH_LOGGED_OUT,
  AUTH_INVALID_USER_OR_PASSWORD : 'Invalid user or password.',
  AUTH_TWO_FACTOR_PREACTIVATE_SUCCESS : Constants.AUTH_TWO_FACTOR_PREACTIVATE_SUCCESS,
  AUTH_TWO_FACTOR_PREACTIVATE_ERROR : Constants.AUTH_TWO_FACTOR_PREACTIVATE_ERROR,
  AUTH_TWO_FACTOR_ACTIVATE_SUCCESS : Constants.AUTH_TWO_FACTOR_ACTIVATE_SUCCESS,
  AUTH_TWO_FACTOR_ACTIVATE_ERROR : Constants.AUTH_TWO_FACTOR_ACTIVATE_ERROR,
  PASSWORD_EMAIL_SENT : 'Password reset email sent',
  INTERFACE_LIST : Constants.INTERFACE_LIST,
  INTERFACE_STATUS : Constants.INTERFACE_STATUS,
  QUERY_OPTIMIZE_DOWNLOAD : Constants.QUERY_OPTIMIZE_DOWNLOAD,

  CUSTOMERS_LOADING : 'CUSTOMERS_LOADING',
  CUSTOMERS_LOADED : 'CUSTOMERS_LOADED',
  CUSTOMERS_CLEAR : 'CUSTOMERS_CLEAR',
  CUSTOMERS_DEVICES_LOADED : 'CUSTOMERS_DEVICES_LOADED',
  SET_CUSTOMER_DATA : 'SET_CUSTOMER_DATA',

  SET_LOGGED_USER : 'SET_LOGGED_USER',
  DEVICES_LOADING : 'DEVICES_LOADING',
  DEVICES_LOADED : 'DEVICES_LOADED',
  SET_SELECTED_DEVICE : 'SET_SELECTED_DEVICE',

  INVENTORY_LOADING : 'INVENTORY_LOADING',
  INVENTORY_LOADED : 'INVENTORY_LOADED',
  INVENTORY_CLEAR : 'INVENTORY_CLEAR',
  INVENTORY_NETWORK_ID : 'siren-marine-inventory',

  FIRMWARE_LOADING : 'FIRMWARE_LOADING',
  FIRMWARE_LOADED : 'FIRMWARE_LOADED',

  USERS_LOADING : 'USERS_LOADING',
  USERS_LOADED : 'USERS_LOADED',
  USERS_CLEAR : 'USERS_CLEAR',
  SET_USERS_ROLES : 'SET_USERS_ROLES',

  GET_BOAT_HISTORY : 'GET_BOAT_HISTORY',
  GET_BOAT_HISTORY_LOADING : 'GET_BOAT_HISTORY_LOADING',
  GET_BOAT_HISTORY_LOADED : 'GET_BOAT_HISTORY_LOADED',

  TRACKS_LOADING : 'TRACKS_LOADING',
  TRACKS_LOADED : 'TRACKS_LOADED',
  TRACKS_BY_DEVICE_LOADING : 'TRACKS_BY_DEVICE_LOADING',
  TRACKS_BY_DEVICE_LOADED : 'TRACKS_BY_DEVICE_LOADED',
  TRACKS_DEVICES_LOADED : 'TRACKS_DEVICES_LOADED',
  TRACKS_DEVICES_LOADING : 'TRACKS_DEVICES_LOADING',
  TRACKS_FIRMWARES_LOADED : 'TRACKS_FIRMWARES_LOADED',
  TRACKS_FIRMWARES_LOADING : 'TRACKS_FIRMWARES_LOADING',

  SET_TWO_FACTOR_SETTINGS : 'SET_TWO_FACTOR_SETTINGS',

  NOT_AVAILABLE : 'Not Available',
  LOADING : 'Loading...',
  SYSTEM_ID : getSystemFromBrowserUrl(),
  PROJECT_ID : process.env.PROJECT_ID,
}
