/**
 * Authz role ids that correspond to 'ShipAdmin', 'ShipSupport' and 'ShipEngineer'
 */ 
const ROLE_ID = {
  ShipAdmin : parseInt( process.env.ROLE_ID_SHIP_ADMIN ),
  ShipSupport : parseInt( process.env.ROLE_ID_SHIP_SUPPORT ),
  ShipEngineer : parseInt( process.env.ROLE_ID_SHIP_ENGINEER ),
}

/**
 * Authz permission ids required by all roles/views in SHIP
 */
const COMMON_PERMISSION_IDS = [
  // required for UserActions.getUserInfo
  parseInt( process.env.PERMISSION_ID_IMAGINE_USER_READ ),
  // required for UserActions.getRoles
  parseInt( process.env.PERMISSION_ID_IMAGINE_ROLE_LIST ),
]

/** 
 * Authz permission ids that correspond to the different views in SHIP e.g. 'Devices' screen -> `ShipDeviceManager`,
 * plus the permissions that are required within each view e.g. 'Devices' screen requires 'Imagine Device sendMessage' permission.
 *
 * Ok to add duplicate authz permissions for each 'Ship<Screen>Manager' permission, will be de-duped by getAuthzIds util function.
 * e.g. if both the Device and Firmware screens require the 'Imagine List Firmware Version' permission, 
 * then specify `process.env.PERMISSION_ID_IMAGINE_LIST_FIRMWARE_VERSION` in both the `ShipDeviceManager` and 
 * `ShipFirmwareManager` arrays.
 */ 
const PERMISSION_IDS = {
  ShipCustomerManager : [
    // required to render "Customers" screen
    parseInt( process.env.PERMISSION_ID_SHIP_CUSTOMER_MANAGER ),
  ],
  ShipDeviceManager : [
    // required to render "Devices" screen
    parseInt( process.env.PERMISSION_ID_SHIP_DEVICE_MANAGER ),
    // required for DeviceActions.sendCommand
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_SEND_MESSAGE ),
    // required for FirmwareActions.getAllFirmwares
    parseInt( process.env.PERMISSION_ID_IMAGINE_LIST_FIRMWARE_VERSION ),
    // required for HistoryActions.getFirmwareVersionHistory
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_READ_HISTORY ),
    // required for:
    //   TrackActions.getTrackByDeviceId
    parseInt( process.env.PERMISSION_ID_PROJECT_LIST_DEVICE_IN_DEPLOYMENT_TRACK ),
    // required for:
    //   DeviceActions.getDevices
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_LIST ),
    // required for DeviceActions.getDevice
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_READ ),
    // required for DeviceActions.getDeviceSensors
    parseInt( process.env.PERMISSION_ID_PROJECT_SIREN3_LIST_WIRELESS ),
    // required for DeviceActions.deleteNmeaChannels
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_UPDATE ),
    // required for DeviceActions.deleteNmeaChannels
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_UPDATE_DATA ),
    // required for DeviceActions.deleteNmeaChannels
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_UPDATE_ANY_DATA ),
  ],
  ShipFirmwareManager : [
    // required to render "Firmware" screen
    parseInt( process.env.PERMISSION_ID_SHIP_FIRMWARE_MANAGER ),
    // required for DeviceActions.getDevicesByNetworkAlias
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_READ ),
    // required for:
    //   FirmwareActions.getAllFirmwares
    //   FirmwareActions.sendFirmwareToArchive
    //   FirmwareActions.sendFirmwareToActive
    parseInt( process.env.PERMISSION_ID_IMAGINE_LIST_FIRMWARE_VERSION ),
    // required for:
    //   FirmwareActions.sendFirmwareInboundMessage
    //   FirmwareActions.sendFirmwareOutboundMessages
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_UPDATE ),
    // required for:
    //   FirmwareActions.sendFirmwareInboundMessage
    //   FirmwareActions.sendFirmwareOutboundMessages
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_UPDATE_DATA ),
    // required for:
    //   FirmwareActions.sendFirmwareToArchive
    //   FirmwareActions.sendFirmwareToActive
    //   FirmwareActions.sendFirmwareInboundMessage
    //   FirmwareActions.sendFirmwareOutboundMessages
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_UPDATE_ANY_DATA ),
  ],
  ShipGroupManager : [
    // required to render "Groups" screen
    parseInt( process.env.PERMISSION_ID_SHIP_GROUP_MANAGER ),
    // required for DeviceActions.getDevicesByNetworkAlias
    parseInt( process.env.PERMISSION_ID_IMAGINE_DEVICE_READ ),
    // required for FirmwareActions.getAllFirmwares
    parseInt( process.env.PERMISSION_ID_IMAGINE_LIST_FIRMWARE_VERSION ),
    // required for:
    //   TrackActions.getTrackList
    //   TrackActions.getTrackGroups
    parseInt( process.env.PERMISSION_ID_PROJECT_LIST_DEPLOYMENT_TRACK ),
    // required for:
    //   TrackActions.getTrackDevices
    //   TrackActions.getTrackGroups
    //   TrackActions.getTrackByDeviceId
    //   TrackService.getTrackGroupSelected
    parseInt( process.env.PERMISSION_ID_PROJECT_LIST_DEVICE_IN_DEPLOYMENT_TRACK ),
    // required for:
    //   TrackActions.getTrackFirmwares
    //   TrackActions.getTrackGroups
    //   TrackService.getTrackGroupSelected
    parseInt( process.env.PERMISSION_ID_PROJECT_LIST_FIRMWARE_IN_DEPLOYMENT_TRACK ),
    // required for:
    //   TrackActions.createTrack
    parseInt( process.env.PERMISSION_ID_PROJECT_DEPLOYMENT_TRACK_CREATE ),
    // required for:
    //   TrackService.updateTrack
    parseInt( process.env.PERMISSION_ID_PROJECT_DEPLOYMENT_TRACK_UPDATE ),
    // required for:
    //   TrackService.deleteTrack
    parseInt( process.env.PERMISSION_ID_PROJECT_DEPLOYMENT_TRACK_DELETE ),
    // required for:
    //   TrackService.addFirmwareToTrack
    parseInt( process.env.PERMISSION_ID_PROJECT_CREATE_FIRMWARE_IN_DEPLOYMENT_TRACK ),
    // required for:
    //   TrackService.removeFirmwareFromTrack
    parseInt( process.env.PERMISSION_ID_PROJECT_DELETE_FIRMWARE_FROM_DEPLOYMENT_TRACK ),
    // required for:
    //   TrackService.addDeviceToTrack
    parseInt( process.env.PERMISSION_ID_PROJECT_CREATE_DEVICE_IN_DEPLOYMENT_TRACK ),
    // required for:
    //   TrackService.removeDeviceFromTrack
    parseInt( process.env.PERMISSION_ID_PROJECT_DELETE_DEVICE_FROM_DEPLOYMENT_TRACK ),
  ],
  ShipInventoryManager : [
    // required to render "Inventory" screen
    parseInt( process.env.PERMISSION_ID_SHIP_INVENTORY_MANAGER ),
    // required for InventoryActions.getInventoryDevices
    parseInt( process.env.PERMISSION_ID_PROJECT_LIST_INVENTORY_DEVICE ),
  ],
  ShipSettingManager : [
    // required to render "Settings" screen
    parseInt( process.env.PERMISSION_ID_SHIP_SETTING_MANAGER ),
    // required for:
    //   UserActions.updateRoles
    //   UserActions.deleteUser
    //   UserActions.deleteMultipleUsers
    parseInt( process.env.PERMISSION_ID_IMAGINE_ROLE_READ ),
    // required for UserActions.getAllUsers
    parseInt( process.env.PERMISSION_ID_IMAGINE_USER_LIST ),
    // required for:
    //   UserActions.updateRole
    //   UserActions.deleteUser
    //   UserActions.deleteMultipleUsers
    parseInt( process.env.PERMISSION_ID_IMAGINE_USER_ASSIGN_ROLES ),
  ],
}

/**
 * Given a SHIP specific role e.g. `'ShipAdmin'` and an array of SHIP permission names that the role *should* have,
 * return the authz role id of the SHIP role and an array of authz permission ids associated with all of the specified
 * SHIP permission names.
 * @param {*} shipRoleName String e.g. `'ShipAdmin'`, `'ShipSupport'`, `'ShipEngineer'`
 * @param {*} permissionNames Array of strings e.g. [ `'ShipDeviceManager'`, `'ShipGroupManager'`, `'read'` ]
 * @returns 
 */
function getAuthzIds( shipRoleName, permissionNames ) {
  // Get authz role id of role e.g. 'ShipAdmin'
  const roleId = ROLE_ID[shipRoleName]
  // Given *all* the role's permissions, we only want the 'Ship' ones selected in the settings menu e.g. 'ShipDeviceManager'
  const shipPermissionNames = permissionNames.filter( pName => pName.startsWith( 'Ship' ) )

  const permissionIds = [ ...COMMON_PERMISSION_IDS ]
  // Create list of authz permission ids that each 'Ship' permission needs e.g. 'ShipDeviceManager' requires itself plus 'Imagine Device sendMessage', etc...
  shipPermissionNames.forEach( pName => permissionIds.push( ...PERMISSION_IDS[pName] ) )

  // Get rid of duplicate permission ids that may be necessary for 2+ 'Ship' permissions
  const uniquePermissionIds = Array.from( new Set( permissionIds ) )

  return { roleId, permissionIds : uniquePermissionIds }
}

module.exports = {
  ROLE_ID,
  PERMISSION_IDS,
  getAuthzIds,
}
