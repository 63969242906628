module.exports = {
  "hierarchy": {},
  "props": {
    "default": {
      "filterButtonIconOff": "fa fa-filter",
      "filterButtonIconOn": "fa fa-filter",
      "filterButtonVariant": "linkSmall|link",
      "filterCancelButtonText": "Cancel",
      "filterCancelButtonVariant": "linkDestructive",
      "filterCheckboxVariant": "small",
      "filterClearButtonVariant": "linkDestructive",
      "filterOkayButtonText": "Apply",
      "filterPaneVariant": "tableFilter",
      "filterable": false,
      "paginate": false,
      "paginationDropdownVariant": "small",
      "paginationNextButtonIcon": "fa fa-chevron-right",
      "paginationPreviousButtonIcon": "fa fa-chevron-left",
      "sortable": false
    }
  }
}