import { System, ServerApi } from '@leverege/siren-marine-systems'

const flavors = {
  sirenmarine : process.env.MARINE_SYSTEM_ID,
  powertech : process.env.POWERTECH_SYSTEM_ID,
}

const system = new System( { flavors } )
const systemId = system.getSystemIdFromBrowserUrl()
// const systemId = flavors.powertech
const isMarine = system.isMarine( systemId )

const apiServerUrl = process.env.SIREN_API_HOST
const serverApi = new ServerApi( { system, apiServerUrl } )

module.exports = {
  system,
  systemId,
  isMarine,
  serverApi
}
