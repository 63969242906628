module.exports = {
  "hierarchy": {
    "small": [
      "checkbox-small"
    ],
    "partial": [
      "checkbox-small",
      "checkbox-partial"
    ],
    "row": [
      "checkbox-table",
      "checkbox-row"
    ]
  },
  "props": {
    "default": {
      "iconOn": "fa fa-check"
    },
    "small": {
      "iconOn": "fa fa-check"
    },
    "partial": {
      "iconOn": "fa fa-minus",
      "iconOff": "fa fa-minus"
    },
    "row": {
      "iconOn": "fa fa-check",
      "iconOff": "fa fa-check"
    }
  }
}