import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content, Flex, Button, Text, TextInput, Toast } from '@leverege/ui-elements'
import { CustomerActions } from '../../../../actions'
import Spinner from '../../lib/spinner/Spinner'
import S from './DeviceCustomerMove.less'


class DeviceAssignCustomer extends Component {

  state = {
    email : '',
    loading : false
  }

  onCustomerEmailChange = ( { value } ) => {
    this.setState( { email : value } )
  }

  assignCustomerToDevice = async () => {
    const { email } = this.state
    const { device, dispatch, afterCustomerAssigned = () => {} } = this.props

    this.setState( { loading : true } )

    try {
      const { message, status } = await dispatch( CustomerActions.addCustomerToDevice( device.id, email ) )

      if ( status === 200 ) {
        Toast.success( message, { containerId : 'toastDefault' } )
        this.setState( { loading : false } )
        afterCustomerAssigned();
      } else {
        Toast.error( message, { autoClose : 8000, containerId : 'toastDefault' } )
        this.setState( { loading : false } )

      }
    } catch ( { message } ) {
      Toast.error( message, { autoClose : 8000, containerId : 'toastDefault' } )
      this.setState( { loading : false } )
    }
  }

  render() {
    const { email, loading } = this.state
    const {
      onClose = () => {}
    } = this.props

    return (
      <React.Fragment>
        <Content relative className={S.content}>
          <Content.Header className={S.header}>
            <Flex direction="row" className={S.headerRow} justify="space-between">
              <Text className={S.headerTitle}>
                Assign Customer
              </Text>
              <img src="/images/cross.png" alt="close" className={S.crossButton} onClick={() => onClose()} />
            </Flex>
          </Content.Header>
          <Content.Area className={S.contentArea}>
            { loading && <Spinner /> }
            { !loading && (
              <Flex direction="column">
                <Text>Customer Email</Text>
                <TextInput
                  hint="Customer Email"
                  className={S.emailInput}
                  onChange={this.onCustomerEmailChange}
                  value={email} />
              </Flex>
            ) }
          </Content.Area>
          <Content.Footer className={S.footer}>
            <Flex variant="buttons" className={S.footerRow}>
              <Button onClick={this.assignCustomerToDevice}>Assign Customer</Button>
              <Button onClick={() => onClose()}>Close</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </React.Fragment>
    )
  }
}


export default connect( ( state, props ) => ( {} ) )( DeviceAssignCustomer )
