import React from 'react'
import { connect } from 'react-redux'
import { Content } from '@leverege/ui-elements'
import { Firmware } from '../../../../accessors'
import TableWithSelectableRowsAndActions from '../../lib/table/TableWithSelectableRowsAndActions'
import FirmwareTypePills from './FirmwareTypePills'
import SendFirmwareDialog from '../SendFirmwareDialog'
import { FirmwareActions } from '../../../../actions'

import S from './FirmwareList.less'


class ArchivedFirmwareList extends React.Component {

  state = {
    showDialog : false,
    dialogType : '',
    loading : false,
    selectedArchivedFirmwares : []
  }

  onFirmwareSelectionChange = async ( firmwares ) => {
    await this.setState( { selectedArchivedFirmwares : firmwares } )
    this.changeDropdownOptions()
  }

  onRowClick = ( row ) => {
    this.onSelect( { itemData : row.id } )
  }

    onShowDialog = ( value ) => {
      this.setState( {
        showDialog : true,
        dialogType : value
      } )
    }

  onCloseDialog = () => {
    this.setState( {
      showDialog : false,
      dialogType : null
    } )
  }

  changeDropdownOptions = () => {
    const { selectedArchivedFirmwares } = this.state
    const { onChangeDropdownOptions } = this.props

    let dropdownOptions = []

    if ( selectedArchivedFirmwares.length > 0 ) {
      dropdownOptions = [
        { key : 1, name : `Unarchive Firmware (${selectedArchivedFirmwares.length})`, onClick : () => this.onShowDialog( 'unarchive' ) }
      ]
    }

    onChangeDropdownOptions( dropdownOptions )
  }

  componentDidMount = () => {

    this.changeDropdownOptions()

  }

  render() {

    const { showDialog, dialogType, selectedArchivedFirmwares } = this.state
    const { archivedFirmwares } = this.props

    const columns = [
      { Header : 'Name', id : 'name', accessor : Firmware.name, width : 100 },
      {
        Header : 'Uploaded',
        id : 'uploaded',
        accessor : Firmware.uploaded,
        width : 150,
        sortMethod : ( a, b ) => {
          const x = new Date( b ).getTime()
          const y = new Date( a ).getTime()
          return y > x ? 1 : -1;
        }
      },
      { Header : 'Author', id : 'author', accessor : Firmware.author, width : 220 },
      { Header : 'Notes', id : 'notes', accessor : Firmware.notes, width : 300 },
      {
        Header : 'Type',
        id : 'type',
        width : 500,
        Cell : x => <FirmwareTypePills binaries={x && x.original && x.original.data && x.original.data.binaries} />
      }
    ]

    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <TableWithSelectableRowsAndActions
            className={S.table}
            data={archivedFirmwares}
            columns={columns}
            loading={false}
            onSelectionChange={this.onFirmwareSelectionChange}
            onRowClick={row => this.onRowClick( row )} />
          <SendFirmwareDialog
            type={dialogType}
            onClose={this.onCloseDialog}
            firmware={selectedArchivedFirmwares[0]}
            firmwares={selectedArchivedFirmwares}
            show={showDialog} />
        </Content.Area>
      </Content>
    )
  }
}

export default connect( state => ( {
  archivedFirmwares : state.firmware.archivedFirmwares,
  loading : state.firmware.loading
} ) )( ArchivedFirmwareList )
