// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sendToArchiveTitle_kvFJL {
  font-style: normal;
  font-weight: 800;
  font-size: 20px !important;
  line-height: 24px;
  color: #3E4243 !important;
  padding: 7px 0px 0px 7px;
}
.sendToArchiveSubTitle_rQnH5 {
  font-style: normal;
  font-size: 16px !important;
  line-height: 24px;
  color: #29323D !important;
  padding: 10px 0px 0px 7px;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/firmware/SendToArchiveDialog.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,gBAAA;EACA,0BAAA;EACA,iBAAA;EACA,yBAAA;EACA,wBAAA;AAAJ;AAGA;EAEI,kBAAA;EACA,0BAAA;EACA,iBAAA;EACA,yBAAA;EACA,yBAAA;AAFJ","sourcesContent":[".sendToArchiveTitle {\n    //font-family: Avenir;\n    font-style: normal;\n    font-weight: 800;\n    font-size: 20px !important;\n    line-height: 24px;\n    color: #3E4243 !important;\n    padding: 7px 0px 0px 7px;\n}\n\n.sendToArchiveSubTitle {\n    //font-family: Avenir;\n    font-style: normal;\n    font-size: 16px !important;\n    line-height: 24px;\n    color: #29323D !important;\n    padding: 10px 0px 0px 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sendToArchiveTitle": `sendToArchiveTitle_kvFJL`,
	"sendToArchiveSubTitle": `sendToArchiveSubTitle_rQnH5`
};
export default ___CSS_LOADER_EXPORT___;
