import System from './System'

class ServerApi {

  constructor( { serverUrl } ) {
    this.serverUrl = serverUrl
  }

  getToken = () => localStorage.getItem( 'id_token' )

  setToken( token ) {
    return localStorage.setItem( 'id_token', token );
  }

  getHeaders = () => ( {
    'Content-Type' : 'application/json',
    Authorization : `Bearer ${this.getToken()}`
  } )

  getOpt = ( opt, method ) => ( {
    ...opt,
    method,
    headers : this.getHeaders()
  } )

  getBaseUrl() {
    const systemAlias = System.getSystemFromBrowserUrl()
    const flavor = systemAlias.replace( '@', '' )
    return `${this.serverUrl}/v2/${flavor}`
  }

  async get( endpoint, opt ) {
    const response = await fetch( `${this.getBaseUrl()}/${endpoint}`, this.getOpt( opt ) )
    return response.json()
  }

  async post( endpoint, opt ) {
    const response = await fetch( `${this.getBaseUrl()}/${endpoint}`, this.getOpt( opt, 'POST' ) )
    return response.json()
  }

  async postFormData( endpoint, opt ) {
    const db = this.getOpt( opt, 'POST' )
    delete db.headers['Content-Type']
    const response = await fetch( `${this.getBaseUrl()}/${endpoint}`, db )
    return response.json()
  }

  async put( endpoint, opt ) {
    const response = await fetch( `${this.getBaseUrl()}/${endpoint}`, this.getOpt( opt, 'PUT' ) )
    return response.json()
  }

  async delete( endpoint, opt ) {
    const response = await fetch( `${this.getBaseUrl()}/${endpoint}`, this.getOpt( opt, 'DELETE' ) )
    if ( response && !response.ok ) throw response
    return response.json()
  }

  async patch( endpoint, opt ) {
    const response = await fetch( `${this.getBaseUrl()}/${endpoint}`, this.getOpt( opt, 'PATCH' ) )
    if ( response && !response.ok ) throw response
    return response.json()
  }

}

export default ServerApi
