import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content, Flex, Button, Toggle, Toast } from '@leverege/ui-elements'
import { updateRoles } from '../../../../actions/UserActions'
import { menuItems } from '../../menu-items'

import S from './Settings.less'

class Settings extends Component {

  onFieldValueChange = ( { data, value } ) => {
    data = data.split( '.' )
    const role = data[0]
    const permission = data[1]

    const { usersRoles } = this.state
    let newPermissionsArr
    if ( value ) {
      // add permission
      newPermissionsArr = [ ...usersRoles[role], permission ]
    } else {
      // remove permission
      newPermissionsArr = [ ...usersRoles[role] ]
      const i = newPermissionsArr.indexOf( permission )
      newPermissionsArr.splice( i, 1 )
    }

    this.setState( {
      usersRoles : {
        ...usersRoles,
        [role] : newPermissionsArr
      }
    } )
  }

   applyChanges = async () => {
     const { usersRoles } = this.state
     const { updateRoles } = this.props
     try {
       await updateRoles( usersRoles )

       Toast.success(
         'The permissions have been updated successfully!',
         { containerId : 'toastDefault' }
       )
     } catch ( err ) {
       console.log( err )
       Toast.error(
         'An error ocurred. Please try again later.',
         { autoClose : 8000, containerId : 'toastDefault' }
       )
     }
   }

  componentWillMount = () => {
    const { usersRoles } = this.props
    this.setState( { usersRoles } )
  }

  render() {
    const { usersRoles } = this.state

    // TODO: auth migration -> add disabled greyed out option for ui-elements <Toggle />
    const PermissionToggle = ( { title, role, permission } ) => { 
      return (
        <Flex key={`div-toggle-${role}-${permission}`} direction="row" justify="space-between" variant="default">
          <Flex direction="column">
            <div className={S.accountConfigTitle}>{`Can Access "${title}" Page`}</div>
          </Flex>
          <Flex direction="row-reverse">
            <Toggle
              disabled={role === 'ShipAdmin' && permission === 'ShipSettingManager'}
              variant="small"
              className={S.toggleButton}
              value={usersRoles[role].includes( permission )}
              eventData={`${role}.${permission}`}
              onChange={this.onFieldValueChange} />
          </Flex>
        </Flex>
      )
    }

    const Toggles = ( { role } ) => {
      return (
        <React.Fragment>
          {menuItems.map( menuItem => (
            <PermissionToggle key={`toggle-${role}-${menuItem.permission}`} role={role} title={menuItem.title} permission={menuItem.permission} />
          ) )}
        </React.Fragment>
      )
    }

    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <Flex direction="column" className={S.mainColumn}>

            <Flex direction="row" className={S.accountItemTitle}>
              Engineer Settings
            </Flex>
            <Toggles role="ShipEngineer" />
            <div className={S.hr} />

            <Flex direction="row" className={S.accountItemTitle}>
              Support Settings
            </Flex>
            <Toggles role="ShipSupport" />
            <div className={S.hr} />

            <Flex direction="row" className={S.accountItemTitle}>
              Admin Settings
            </Flex>
            <Toggles role="ShipAdmin" />

          </Flex>

          <Content.Footer className={S.footer}>
            <Flex variant="buttons">
              <Button onClick={this.applyChanges}>Apply Changes</Button>
            </Flex>
          </Content.Footer>

        </Content.Area>
      </Content>
    )
  }
}

export default connect(
  state => ( {
    usersRoles : state.user.usersRoles
  } ),
  dispatch => ( {
    updateRoles : roles => dispatch( updateRoles( roles ) )
  } )
)( Settings )
