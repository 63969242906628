import React, { Component } from 'react'
import { Content, Flex, Button, Text } from '@leverege/ui-elements'

import S from './DeviceAlertRecipientDetails.less'

class DeviceAlertRecipientDetails extends Component {

  render() {
    const {
      alertRecipient,
      onClose = () => { }
    } = this.props

    return (
      <React.Fragment>
        <Content relative className={S.content}>
          <Content.Header className={S.header}>
            <Flex direction="row" className={S.headerRow} justify="space-between">
              <Text className={S.headerTitle}>
                {( alertRecipient && alertRecipient.name ) || 'Alert Recipient'}
              </Text>
              <img src="/images/cross.png" alt="close" className={S.crossButton} onClick={() => onClose()} />
            </Flex>

          </Content.Header>
          <Content.Area className={S.contentArea}>
            <Flex direction="column" className={S.infoBlock}>

              <Flex direction="row" className={S.infoTitle}>
                Email
              </Flex>
              <Flex direction="row" className={S.info}>
                <Flex direction="column">
                  {alertRecipient.email}
                </Flex>
              </Flex>

              <Flex direction="row" className={S.infoTitle}>
                Email Enabled?
              </Flex>
              <Flex direction="row" className={S.info}>
                <Flex direction="column">
                  {alertRecipient.emailEnabled ? 'Yes' : 'No'}
                </Flex>
              </Flex>

              <Flex direction="row" className={S.infoTitle}>
                Phone
              </Flex>
              <Flex direction="row" className={S.info}>
                <Flex direction="column">
                  {alertRecipient.phone}
                </Flex>
              </Flex>

              <Flex direction="row" className={S.infoTitle}>
                Phone Enabled?
              </Flex>
              <Flex direction="row" className={S.info}>
                <Flex direction="column">
                  {alertRecipient.phoneEnabled ? 'Yes' : 'No'}
                </Flex>
              </Flex>

              <Flex direction="row" className={S.infoTitle}>
                Daily Status Reports Enabled?
              </Flex>
              <Flex direction="row" className={S.info}>
                <Flex direction="column">
                  {alertRecipient.dailyReportsEnabled ? 'Yes' : 'No'}
                </Flex>
              </Flex>
              
            </Flex>
          </Content.Area>
          <Content.Footer className={S.footer}>
            <Flex variant="buttons" className={S.footerRow}>
              <Button onClick={() => onClose()}>Close</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </React.Fragment>
    )
  }
}

export default DeviceAlertRecipientDetails
