import React, { Component } from 'react';
import { Button, Flex, TextInput } from '@leverege/ui-elements'
import S from './Search.less'


class Search extends Component {

  render() {
    const {
      value,
      placeholder,
      onChange = () => {},
      onSearch = () => {}
    } = this.props

    return (
      <Flex direction="row" className={S.container}>
        <TextInput
          changeOnReturn={false}
          variant="search"
          hint={placeholder}
          value={value}
          eventData="query"
          onKeyDown={( { key } ) => {
            if ( key === 'Enter' ) {
              onSearch( value )
            }
          }}
          onChange={onChange}
          className={S.searchInput} />
      </Flex>
    );
  }
}


export default Search
