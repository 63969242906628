import React from 'react'
import { connect } from 'react-redux'
import { Flex, Content, Button, Form, Text, Toast } from '@leverege/ui-elements'

import { Firmware, Track } from '../../../accessors'
import { FirmwareActions } from '../../../actions'
import TrackSelector from './TrackSelector'

class SendToTrackDialog extends React.Component {

  constructor( props ) {
    super( props )

    this.state = {
      data : null,
      selectedTrack : null,
      firmwares : []
    }

    this.validator = {
      fields : {
        esn : {

        }
      }
    }

  }

  componentDidMount() {
    const { activeFirmwares } = this.props
    const firmwares = activeFirmwares.reduce( ( acc, row ) => {
      acc[row.id] = row
      return acc
    }, {} )

    this.setState( { firmwares } )
  }

  onSelectFirmware = ( { value : trackId } ) => {
    this.setState( { selectedTrack : trackId } )
  }

  onSubmit = ( { data, value } ) => {
    const { onClose, firmware, dispatch } = this.props
    const { selectedTrack, firmwares } = this.state

    dispatch( FirmwareActions.sendFirmwareToTrack( firmware.id, selectedTrack ) )
      .then( ( res ) => {
        if ( res.status >= 300 ) {
          Toast.error(
            `Failed to send firmware to group ${selectedTrack}. ${res.message}`,
            { containerId : 'toastDefault' }
          )
        } else {
          const track = firmwares[selectedTrack]

          Toast.success(
            `Sent firmware ${Firmware.name( firmware )} to ${Track.name( track )}`,
            { containerId : 'toastDefault' }
          )
          onClose()
        }
      } )
  }

  renderForm = () => {
    const { onClose, firmware, activeFirmwares } = this.props
    const { selectedTrack } = this.state
    const title = `Send firmware ${Firmware.name( firmware, '' )}`

    return (
      <Content relative>
        <Content.Header>
          <Text variant="instructions">{title}</Text>
        </Content.Header>
        <Content.Area style={{ overflow : 'visible' }}>
          <Flex variant="colM" className="formContent">
            <TrackSelector
              tracks={activeFirmwares}
              selectedTrack={selectedTrack}
              onSelect={this.onSelectFirmware} />
          </Flex>
        </Content.Area>
        <Content.Footer>
          <Flex align="center" justify="flex-end" variant="rowM">
            <Button variant="hollow" type="button" onClick={onClose}>Close</Button>
            <Form.Submit>Send</Form.Submit>
          </Flex>
        </Content.Footer>
      </Content>
    )
  }

  render() {
    const { data } = this.state

    return (
      <Form
        render={this.renderForm}
        validator={this.validator}
        onSubmit={this.onSubmit}
        data={data} />
    )
  }
}


export default connect( state => ( {
  model : state.data,
  ...state.firmware
} ) )( SendToTrackDialog )
