// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lockIcon_iffxm {
  display: inline;
  height: 20px;
  width: 20px;
}
.header_aU_r1 {
  display: grid;
  grid-template-columns: 30px 1fr;
  margin-top: 1vh;
  margin-bottom: 2vh;
}
.contentTitle_NFqy7 {
  margin-bottom: 2vh;
}
.contentText_xemky {
  margin-bottom: 0.5vh;
}
.textInput_UeCiR {
  margin-bottom: 1vh;
}
.button_lKh1X {
  margin-top: 1vh;
  margin-bottom: 2vh;
}
.resendCodeText_oZWM6 {
  margin-bottom: 1vh;
}
.resendCode_N2Ixe {
  color: #28548D;
  font-weight: bold;
}
.resendCode_N2Ixe:hover {
  cursor: pointer;
}
.codes_mR5iy {
  background-color: var(--color-base025);
  font-family: monospace;
  border: 1px solid var(--color-base200);
  padding: var(--size-padMedium);
  border-radius: var(--size-padMedium);
}
.code_20GEi {
  width: 50%;
  text-align: center;
  white-space: nowrap;
}
.cardSection_lf1rP {
  max-width: 400px;
}
.goToShip_uwqDC {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.errorText_Eg8XW {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/login/twoFactor/TwoFactor.css"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;AACF;AAEA;EACE,aAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;AAAF;AAGA;EACE,kBAAA;AADF;AAIA;EACE,oBAAA;AAFF;AAKA;EACE,kBAAA;AAHF;AAMA;EACE,eAAA;EACA,kBAAA;AAJF;AAOA;EACE,kBAAA;AALF;AAQA;EACE,cAAA;EACA,iBAAA;AANF;AASA;EACE,eAAA;AAPF;AAUA;EACE,sCAAA;EACA,sBAAA;EACA,sCAAA;EACA,8BAAA;EACA,oCAAA;AARF;AAWA;EACE,UAAA;EACA,kBAAA;EACA,mBAAA;AATF;AAYA;EACE,gBAAA;AAVF;AAaA;EACE,eAAA;EACA,kBAAA;AAXF;AAcA;EACE,kBAAA;AAZF","sourcesContent":[".lockIcon {\n  display: inline;\n  height: 20px;\n  width: 20px;\n}\n\n.header {\n  display: grid;\n  grid-template-columns: 30px 1fr;\n  margin-top: 1vh;\n  margin-bottom: 2vh;\n}\n\n.contentTitle {\n  margin-bottom: 2vh;\n}\n\n.contentText {\n  margin-bottom: 0.5vh;\n}\n\n.textInput {\n  margin-bottom: 1vh;\n}\n\n.button {\n  margin-top: 1vh;\n  margin-bottom: 2vh;\n}\n\n.resendCodeText {\n  margin-bottom: 1vh;\n}\n\n.resendCode {\n  color: #28548D;\n  font-weight: bold;\n}\n\n.resendCode:hover {\n  cursor: pointer;\n}\n\n.codes {\n  background-color: var(--color-base025);\n  font-family: monospace;\n  border: 1px solid var(--color-base200);\n  padding: var(--size-padMedium);\n  border-radius: var(--size-padMedium);\n}\n\n.code {\n  width: 50%;\n  text-align: center;\n  white-space: nowrap;\n}\n\n.cardSection {\n  max-width: 400px;\n}\n\n.goToShip {\n  margin-top: 2vh;\n  margin-bottom: 2vh;\n}\n\n.errorText {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lockIcon": `lockIcon_iffxm`,
	"header": `header_aU_r1`,
	"contentTitle": `contentTitle_NFqy7`,
	"contentText": `contentText_xemky`,
	"textInput": `textInput_UeCiR`,
	"button": `button_lKh1X`,
	"resendCodeText": `resendCodeText_oZWM6`,
	"resendCode": `resendCode_N2Ixe`,
	"codes": `codes_mR5iy`,
	"code": `code_20GEi`,
	"cardSection": `cardSection_lf1rP`,
	"goToShip": `goToShip_uwqDC`,
	"errorText": `errorText_Eg8XW`
};
export default ___CSS_LOADER_EXPORT___;
