import React, { Component } from 'react'
import { Content, Flex, Button, Text } from '@leverege/ui-elements'
import { format } from 'date-fns'
import S from './FirmwareDetails.less'


class FirmwareDetails extends Component {

  render() {
    const {
      firmware,
      onUpdateFirmware = () => {},
      onClose = () => {} } = this.props

    return (
      <React.Fragment>
        <Content relative className={S.content}>
          <Content.Header className={S.header}>
            <Flex direction="row" className={S.headerRow} justify="space-between">
              <Text className={S.headerTitle}>
                {firmware && firmware.data && firmware.data.version ? `Version ${firmware.data.version}` : 'Unamed Version'}
              </Text>
              <img src="/images/cross.png" alt="close" className={S.crossButton} onClick={() => onClose()} />
            </Flex>
          </Content.Header>
          <Content.Area className={S.contentArea}>
            <Flex direction="column" className={S.infoBlock}>
              <Flex direction="row" className={S.infoTitle}>
                Firmware Includes
              </Flex>
              <Flex direction="row" className={S.info}>
                <Flex direction="column">
                  { firmware && firmware.data && firmware.data.binaries && firmware.data.binaries.main && <div>Main Unit</div>}
                  { firmware && firmware.data && firmware.data.binaries && firmware.data.binaries.leaf && <div>Wireless Sensor</div>}
                  { firmware && firmware.data && firmware.data.binaries && firmware.data.binaries.host && <div>Wireless Host</div>}
                  { firmware && firmware.data && firmware.data.binaries && firmware.data.binaries.repeater && <div>Wireless Repeater</div>}
                </Flex>
              </Flex>
              <Flex direction="row" className={S.infoTitle}>
                Notes
              </Flex>
              <Flex direction="row" className={S.info}>
                {firmware && firmware.data && firmware.data.notes}
              </Flex>
              {/* <Flex direction="row" className={S.infoTitle}>
                Devices
              </Flex>
              <Flex direction="row" className={S.info}></Flex>*/}
              <Flex direction="row" className={S.infoTitle}>
                Author
              </Flex>
              <Flex direction="row" className={S.info} justify="space-between">
                <div>{firmware && firmware.data && firmware.data.author}</div>
                <div className={S.uploadDate}>{format( firmware && firmware.created, 'MM/dd/yyyy' )}</div>
              </Flex>
            </Flex>
          </Content.Area>
          <Content.Footer className={S.footer}>
            <Flex variant="buttons" className={S.footerRow}>
              <Button onClick={() => onUpdateFirmware( firmware )}>Update Firmware</Button>
              <Button onClick={() => onClose()}>Close</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </React.Fragment>
    )
  }
}

export default FirmwareDetails
