module.exports = {
  "hierarchy": {
    "dialog": [
      "pane-dialog"
    ]
  },
  "props": {
    "default": {},
    "dialog": {}
  }
}