const Path = require( '@leverege/path' )

const ENGINES_LABELS = {
  1 : [
    'Engine'
  ],
  2 : [
    'Port',
    'Starboard'
  ],
  3 : [
    'Port',
    'Center',
    'Starboard'
  ],
  4 : [
    'Port',
    'Port Center',
    'Stbd Center',
    'Stbd'
  ],
  5 : [
    'Engine P',
    'Engine Ps',
    'Engine C',
    'Engine Ss',
    'Engine S'
  ],
  6 : [
    'Engine P',
    'Engine Ps',
    'Engine Cent P',
    'Engine Cent S',
    'Engine Ss',
    'Engine S'
  ]
}

const getNmeaChannelDisplay = ( meta, channel, isActiveNmeaType ) => {
  return Path.get( `vessel/nmea/${channel}/display`, meta, isActiveNmeaType )
}

const getNmeaAlias = ( meta, channel, type, totalEngines, engineCount ) => {
  const alias = Path.get( `vessel/nmea/${channel}/alias`, meta )

  if ( !alias && type === 'engine' ) {
    return ENGINES_LABELS[totalEngines] && ENGINES_LABELS[totalEngines][engineCount]
  }

  return alias
}

const getLabel = ( model ) => {
  return Path.get( 'label', model )
}

const getClassCode = ( model ) => {
  return Path.get( 'device/classCode', model )
}

const getFunctionCode = ( model ) => {
  return Path.get( 'device/functionCode', model )
}

const getManufacturerCode = ( model ) => {
  return Path.get( 'device/manufacturerCode', model )
}

const getNmeaData = ( nmea, meta ) => {
  if ( !nmea ) return []
  const activeNmeaTypes = [ 'engine', 'tank', 'wind', 'environmental', 'location', 'generator', 'subchannel' ]

  const engines = Object.values( nmea ).filter( x => x?.hasOwnProperty( 'engine' ) )
  const totalEngines = engines.length
  let engineCount = 0

  return Object
    .keys( nmea )
    .filter( channel => channel !== 'active' && channel !== 'msTime' )
    .map( ( channel ) => {
      const nmeaItem = nmea[channel]

      const activeNmeaType = activeNmeaTypes.find( t => nmeaItem?.hasOwnProperty( t ) )

      const display = getNmeaChannelDisplay( meta, channel, !!activeNmeaType )
      const alias = getNmeaAlias( meta, channel, activeNmeaType, totalEngines, engineCount ) || null
      if ( activeNmeaType === 'engine' ) {
        engineCount++
      }

      const nmeaData = {
        channel,
        type : activeNmeaType === 'subchannel' ? 'switchback' : activeNmeaType,
        label : getLabel( nmeaItem ),
        display,
        alias,
        classCode : getClassCode( nmeaItem ),
        functionCode : getFunctionCode( nmeaItem ),
        manufacturerCode : getManufacturerCode( nmeaItem ),
        lastUpdate : Path.get( 'msTime', nmeaItem )
      }
      return nmeaData
    } )
}

module.exports = {
  getNmeaData,
}
