module.exports = {
  "hierarchy": {
    "small": [
      "text-small"
    ],
    "dialogMessage": [
      "text-dialogMessage"
    ],
    "listLabel": [
      "text-listLabel"
    ],
    "sectionTitle": [
      "text-sectionTitle"
    ],
    "error": [
      "text-small",
      "text-error"
    ],
    "instructions": [
      "text-instructions"
    ],
    "dialogTitle": [
      "text-instructions",
      "text-dialogTitle"
    ],
    "portalForm": [
      "text-portalForm"
    ],
    "contentTitle": [
      "text-contentTitle"
    ],
    "contentInfo": [
      "text-contentInfo"
    ],
    "hintText": [
      "text-hintText"
    ],
    "headerText": [
      "text-headerText"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "dialogMessage": {},
    "listLabel": {},
    "sectionTitle": {},
    "error": {},
    "instructions": {},
    "dialogTitle": {},
    "portalForm": {},
    "contentTitle": {},
    "contentInfo": {},
    "hintText": {},
    "headerText": {}
  }
}