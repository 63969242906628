const Api = require( '@leverege/api' )
const { logout } = require( './Auth' )
const Constants = require( './Constants' )
const { getAuthzIds, ROLE_ID } = require( '../util/Authz' )

function hasShipRole( user ) {
  return user?.roles?.filter( r => r?.name?.startsWith( 'Ship' ) )?.length >= 1
}

function getUserInfo( userId ) {
  return async ( dispatch, getState, { api } ) => {
    // Requires "Imagine -> User -> read" authz perm
    const user = await api.project( Constants.PROJECT_ID ).user( userId ).get()
    if ( !user || !hasShipRole( user ) ) {
      dispatch( logout() )
      throw Error( 'User does not exist or does not have a Ship role' )
    }
    dispatch( { type : Constants.SET_LOGGED_USER, user } )
  }
}

function getAllUsers( emailQuery = '' ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.USERS_LOADING } )
    const projectId = Constants.PROJECT_ID

    // Requires "Imagine -> User -> list" authz perm
    const res = await api.project( projectId ).users().list(
      { role : [ 
        { name : 'ShipAdmin', projectId }, 
        { name : 'ShipSupport', projectId }, 
        { name : 'ShipEngineer', projectId } 
      ] } 
    )
    let users = res?.items

    if ( !users || !Array.isArray( users ) ) { 
      users = [] 
    } else if ( emailQuery ) {
      users = users.filter( u => u.username === emailQuery || u.email === emailQuery )
    }

    dispatch( { type : Constants.USERS_LOADED, payload : users } )
  }
}

function createUser( user ) {
  return async ( dispatch, getState, { serverApi } ) => {
    // LS-1641 keep, performs server side validation and uses default password for user creation
    const res = await serverApi.post( 'admin-user', { body : JSON.stringify( user ) } )
    dispatch( getAllUsers() )
    return res
  }
}

function updateRole( userId, roleId ) {
  return async ( dispatch, getState, { api } ) => {
    const projectId = Constants.PROJECT_ID

    // Get user object - requires "Imagine -> User -> read" authz perm
    const user = await api.project( projectId ).user( userId ).get()

    // Remove existing ship roles - requires "Imagine -> User -> assignRoles" authz perm
    const existingShipRoleIds = user.roles.filter( r => r.name.startsWith( 'Ship' ) ).map( r => r.id )
    await api.project( projectId ).user( userId ).removeRoles( existingShipRoleIds )

    // Add new role 
    // Requires:
    //   1. "Imagine -> User -> assignRoles" authz perm
    //   2. Requesting user has target role assignment e.g. to assign somebody 'Ship Engineer', the requestor must have 'Ship Engineer'
    let roleIds = [ roleId ]
    // ShipAdmins should have every other ship role too e.g. ShipEngineer and ShipSupport
    if ( roleId === ROLE_ID.ShipAdmin ) {
      roleIds = Object.values( ROLE_ID )
    }
    await api.project( projectId ).user( userId ).addRoles( roleIds ) 

    dispatch( getAllUsers() )
  }
}

function deleteUser( userId ) {
  return async ( dispatch, getState, { api } ) => {
    const projectId = Constants.PROJECT_ID

    // Get user object - requires "Imagine -> User -> read" authz perm
    const user = await api.project( projectId ).user( userId ).get()

    // Remove existing ship roles - requires "Imagine -> User -> assignRoles" authz perm
    const existingShipRoleIds = user.roles.filter( r => r.name.startsWith( 'Ship' ) ).map( r => r.id )
    await api.project( projectId ).user( userId ).removeRoles( existingShipRoleIds )

    dispatch( getAllUsers() )
  }
}

function deleteMultipleUsers( userIds ) {
  return async ( dispatch, getState, { api } ) => {
    const projectId = Constants.PROJECT_ID

    // Get user object - requires "Imagine -> User -> read" authz perm
    const getUserFns = userIds.map( id => () => api.project( projectId ).user( id ).get() )
    const users = await Promise.all( getUserFns.map( f => f() ) )

    const removeShipRolesFns = []
    users.forEach( ( user ) => {
      // Remove existing ship roles - requires "Imagine -> User -> assignRoles" authz perm
      const shipRoleIds = user.roles.filter( r => r.name.startsWith( 'Ship' ) ).map( r => r.id )
      removeShipRolesFns.push( () => api.project( projectId ).user( user.id ).removeRoles( shipRoleIds ) )
    } )
    await Promise.all( removeShipRolesFns.map( f => f() ) )    

    dispatch( getAllUsers() )
  }
}

function getRoles() {
  return async ( dispatch, getState, { api } ) => {
    // Returns
    // {
    //   ShipAdmin : [ ShipDeviceManager, ... ],
    //   ...
    // }
    const projectId = Constants.PROJECT_ID

    // Requires "Imagine -> Role -> list" authz perm
    const rolesRes = await api.project( projectId ).roles().list( { includePermissions : true } )
    const shipRolesArr = rolesRes?.items?.filter( r => r?.name?.startsWith( 'Ship' ) )
    if ( !shipRolesArr?.length > 0 ) {
      console.error( 'Ship Roles Not Found' )
    }
    const shipRoles = { }
    shipRolesArr.forEach( ( role ) => {
      shipRoles[role.name] = role.permissions.map( p => p.name )
    } )

    dispatch( { type : Constants.SET_USERS_ROLES, payload : shipRoles } )
  }
}

function updateRoles( usersRoles ) {
  return async ( dispatch, getState, { api } ) => {
    const p = []
    Object.entries( usersRoles ).forEach( ( [ shipRoleName, permissionNames ] ) => {
      const { roleId, permissionIds } = getAuthzIds( shipRoleName, permissionNames )
      p.push( async () => {
        await api
          .project( Constants.PROJECT_ID )
          .role( roleId )
          .setPermissions( permissionIds ) 
      } )
    } )
    await Promise.all( p.map( p => p() ) )
    dispatch( getRoles() )
  }
}

function preActivateTwoFactorSuccess( userProfile ) {
  return async ( dispatch, getState ) => {
    dispatch( { type : Constants.AUTH_TWO_FACTOR_PREACTIVATE_SUCCESS, profile : userProfile } )
  }
}

function preActivateTwoFactorError( errorMessage ) {
  return async ( dispatch, getState ) => {
    dispatch( { type : Constants.AUTH_TWO_FACTOR_PREACTIVATE_ERROR, message : errorMessage } )
  }
}

function preActivateTwoFactor( token, userId, email, password ) {
  return async ( dispatch, getState, { api } ) => {
    try {
      const apiWithTwoFactorEnrollmentToken = Api.init( {
        host : api.options.host,
        projectId : api.options.projectId
      } )
      apiWithTwoFactorEnrollmentToken.setToken( token )
      const r = await apiWithTwoFactorEnrollmentToken.user( userId ).preActivateTwoFactorMethod( 'email', { email, password } )
      dispatch( preActivateTwoFactorSuccess( r.user ) )
    } catch ( err ) {
      console.error( err )
      dispatch( preActivateTwoFactorError( err.message ) )
    }
  }
}

function activateTwoFactorSuccess( profile, idToken, oneTimeCodes ) {
  return async ( dispatch, getState ) => {
    dispatch( { 
      type : Constants.AUTH_TWO_FACTOR_ACTIVATE_SUCCESS, 
      profile,
      idToken,
      oneTimeCodes,
    } )
  }
}

function activateTwoFactorError( errorMessage ) {
  return async ( dispatch, getState ) => {
    dispatch( { type : Constants.AUTH_TWO_FACTOR_ACTIVATE_ERROR, message : errorMessage } )
  }
}
  
function activateTwoFactor( token, userId, code ) {
  return async ( dispatch, getState, { api } ) => {
    try {
      const apiWithTwoFactorEnrollmentToken = Api.init( {
        host : api.options.host,
        projectId : api.options.projectId
      } )
      apiWithTwoFactorEnrollmentToken.setToken( token )
      const r = await api.user( userId ).activateTwoFactorMethod( 'email', code )
      // Update localStorage with login token
      window.localStorage.setItem( 'id_token', r.idToken )
      dispatch( activateTwoFactorSuccess( 
        r.profile,
        r.idToken,
        r.oneTimeCodes,
      ) )
    } catch ( err ) {
      console.error( err )
      dispatch( activateTwoFactorError( err.message ) )
    }
  }
}
  
function doneWithBackupCodes( profile, idToken ) {
  return async ( dispatch, getState ) => {
    dispatch( { type : Constants.AUTH_LOGGED_IN, profile, idToken } )
  }
}

module.exports = {
  getUserInfo,
  getAllUsers,
  createUser,
  updateRole,
  deleteUser,
  deleteMultipleUsers,
  getRoles,
  updateRoles,
  preActivateTwoFactor,
  activateTwoFactor,
  doneWithBackupCodes,
}
