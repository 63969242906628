import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Interface } from '@leverege/api-redux'
import { Flex, Content } from '@leverege/ui-elements'
import Constants from '../../../actions/Constants'
import Tabs from '../lib/tab/Tabs' 
import FirmwareList from './Firmware/FirmwareList'
import ArchivedFirmwareList from './Firmware/ArchivedFirmwareList'
import Search from '../lib/input/Search'
import ActionDropdownBar from '../lib/button/ActionDropdownBar'
import S from './Firmware.less'
import { FirmwareActions } from '../../../actions'

class Firmware extends Component {

  state = {
    query : '',
    lastUsedQuery : '',
    dropdownOptions : []
  }

  componentDidMount() {
    this.search()
  }

  search = ( ) => {
    const { query } = this.state
    const { getAllFirmwares } = this.props
    getAllFirmwares()
    this.setState( { lastUsedQuery : query } )
  }

  searchQueryChange = ( { value } ) => {
    this.setState( { query : value } )
  }

  onTabChanged = () => {
    this.onChangeDropdownOptions( [] )
  }

  onChangeDropdownOptions = dropdownOptions => this.setState( { dropdownOptions } )

  render() {
    const { query, lastUsedQuery, dropdownOptions } = this.state
    const { model, firmwareId } = this.props

    const tabsConfig = [
      { key : 1, tabId : 'firmwares', title : 'Firmware', icon : '/images/svg/white/export.svg', getComponent : () => <FirmwareList firmwareId={firmwareId} model={model} onChangeDropdownOptions={this.onChangeDropdownOptions} /> },
      { key : 2, tabId : 'archivedFirmwares', title : 'Archived', icon : '/images/svg/white/time.svg', getComponent : () => <ArchivedFirmwareList firmwareId={firmwareId} model={model} onChangeDropdownOptions={this.onChangeDropdownOptions} /> }
    ]

    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <Flex direction="column" className={S.mainColumn}>
            <Flex direction="row" variant="rowM" className={S.topControlsRow}>
              <Search
                placeholder="Search by Version"
                disabled={lastUsedQuery === query}
                value={query}
                onChange={this.searchQueryChange}
                onSearch={this.search} />

              <ActionDropdownBar options={dropdownOptions} />
            </Flex>
            <Tabs tabsConfig={tabsConfig} onTabChanged={this.onTabChanged} />
          </Flex>
        </Content.Area>
      </Content>
    )
  }
}

const mapDispatchToProps = dispatch => ( {
  getAllFirmwares : () => dispatch( FirmwareActions.getAllFirmwares() )
} )

export default connect( ( state, props ) => ( {
  model : state.data,
  firmwareId : props.match.params.firmwareId,
  ui : state.ui,
  ...state.firmware
} ), mapDispatchToProps )( Firmware )
