import React from 'react'
import { Dropdown } from '@leverege/ui-elements'

const ActionDropdownBar = ( { options } ) => ( 
  <Dropdown 
    variant="actions"
    disabled={options.length === 0}
    data={options}
    placeholder="Actions"
    textField="name"
    onChange={e => e.value.onClick()} />
)

export default ActionDropdownBar
