// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs_gHBqO {
  font: normal 15px/20px var(--fontFamily-primary);
  color: #c1c6c7;
  padding: var(--size-padMedium);
}
.breadcrumbs_gHBqO > .link_IZlRc {
  font: 15px/20px var(--fontFamily-primary);
  color: #c1c6c7;
  padding: 0px var(--size-padMedium);
  display: block;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.breadcrumbs_gHBqO > .link_IZlRc:hover {
  text-decoration: underline;
}
.breadcrumbs_gHBqO > .link_IZlRc:focus {
  text-decoration: underline;
}
.breadcrumbs_gHBqO > .separator_WqqHG {
  color: #c1c6c7;
  font-size: var(--size-fontSmall);
  padding-left: var(--size-padLarge);
  padding-right: var(--size-padLarge);
}
.breadcrumbs_gHBqO > .current_D1mtA {
  font: 15px/20px var(--fontFamily-primary);
  color: #717476;
  padding-left: var(--size-padMedium);
}
.breadcrumbs_gHBqO.breadcrumbs-small_dnpuw {
  font-size: var(--size-fontSmall);
}
`, "",{"version":3,"sources":["webpack://./src/theme/Breadcrumbs.css"],"names":[],"mappings":"AAAA;EACE,gDAAA;EACA,cAAA;EACA,8BAAA;AACF;AACA;EACE,yCAAA;EACA,cAAA;EACA,kCAAA;EACA,cAAA;EACA,6BAAA;EACA,qBAAA;EACA,sBAAA;EACA,uBAAA;EACA,wBAAA;AACF;AACA;EACE,0BAAA;AACF;AACA;EACE,0BAAA;AACF;AACA;EACE,cAAA;EACA,gCAAA;EACA,kCAAA;EACA,mCAAA;AACF;AACA;EACE,yCAAA;EACA,cAAA;EACA,mCAAA;AACF;AACA;EACE,gCAAA;AACF","sourcesContent":[".breadcrumbs {\n  font: normal 15px/20px var( --fontFamily-primary );\n  color: #c1c6c7;\n  padding: var( --size-padMedium );\n}\n.breadcrumbs > .link {\n  font: 15px/20px var( --fontFamily-primary );\n  color: #c1c6c7;\n  padding: 0px var( --size-padMedium );\n  display: block;\n  background-color: transparent;\n  border-top-width: 0px;\n  border-left-width: 0px;\n  border-right-width: 0px;\n  border-bottom-width: 0px;\n}\n.breadcrumbs > .link:hover {\n  text-decoration: underline;\n}\n.breadcrumbs > .link:focus {\n  text-decoration: underline;\n}\n.breadcrumbs > .separator {\n  color: #c1c6c7;\n  font-size: var( --size-fontSmall );\n  padding-left: var( --size-padLarge );\n  padding-right: var( --size-padLarge );\n}\n.breadcrumbs > .current {\n  font: 15px/20px var( --fontFamily-primary );\n  color: #717476;\n  padding-left: var( --size-padMedium );\n}\n.breadcrumbs.breadcrumbs-small {\n  font-size: var( --size-fontSmall );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `breadcrumbs_gHBqO`,
	"link": `link_IZlRc`,
	"separator": `separator_WqqHG`,
	"current": `current_D1mtA`,
	"breadcrumbs-small": `breadcrumbs-small_dnpuw`
};
export default ___CSS_LOADER_EXPORT___;
