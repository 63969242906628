// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentArea_ihHbn {
  height: 100%;
}
.contentArea_ihHbn .tableArea__5b9q {
  height: 100%;
}
.contentArea_ihHbn .tableArea__5b9q .table_xybv_ {
  height: 100%;
}
.contentArea_ihHbn .noAssignedContentArea_LoK1w {
  padding-top: 36px;
  padding-left: 40px;
}
.contentArea_ihHbn .noAssignedContentArea_LoK1w .info_appmX {
  width: 460px;
  padding-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/devices/Device/DeviceCustomers.less"],"names":[],"mappings":"AACA;EACE,YAAA;AAAF;AADA;EAII,YAAA;AAAJ;AAJA;EAOM,YAAA;AAAN;AAPA;EAYI,iBAAA;EACA,kBAAA;AAFJ;AAXA;EAgBM,YAAA;EACA,oBAAA;AAFN","sourcesContent":["\n.contentArea {\n  height: 100%;\n\n  .tableArea {\n    height: 100%;\n\n    .table {\n      height: 100%;\n    }\n  }\n\n  .noAssignedContentArea {\n    padding-top: 36px;\n    padding-left: 40px;\n\n    .info {\n      width: 460px;\n      padding-bottom: 16px;\n    }\n  }\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentArea": `contentArea_ihHbn`,
	"tableArea": `tableArea__5b9q`,
	"table": `table_xybv_`,
	"noAssignedContentArea": `noAssignedContentArea_LoK1w`,
	"info": `info_appmX`
};
export default ___CSS_LOADER_EXPORT___;
