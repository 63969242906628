// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list_lF6gl {
  font-weight: 600;
  font-family: var(--fontFamily-primary);
}
.list_lF6gl.list-tracks_YUvvE {
  padding: 0px;
}
.list_lF6gl.list-tracks_YUvvE > .listItem_DMHLA {
  padding: var(--size-padLarge);
}
.list_lF6gl.list-tracks_YUvvE > .listItem_DMHLA[data-list-item-selected=true] {
  background-color: var(--color-background3);
}
`, "",{"version":3,"sources":["webpack://./src/theme/List.css"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,sCAAA;AACF;AACA;EACE,YAAA;AACF;AACA;EACE,6BAAA;AACF;AACA;EACE,0CAAA;AACF","sourcesContent":[".list {\n  font-weight: 600;\n  font-family: var( --fontFamily-primary );\n}\n.list.list-tracks {\n  padding: 0px;\n}\n.list.list-tracks > .listItem {\n  padding: var( --size-padLarge );\n}\n.list.list-tracks > .listItem[data-list-item-selected=true] {\n  background-color: var( --color-background3 );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `list_lF6gl`,
	"list-tracks": `list-tracks_YUvvE`,
	"listItem": `listItem_DMHLA`
};
export default ___CSS_LOADER_EXPORT___;
