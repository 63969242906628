import React from 'react'
import { connect } from 'react-redux'
import { Content, Login, Flex } from '@leverege/ui-elements'
import {
  EmailListItem,
  EmailSMSConfirmation,
  EmailPreActivate,
} from '@leverege/molten/screens'

import { Validator } from '@leverege/validator'
import { Auth, Users } from '@leverege/api-redux'
import Enroll from './twoFactor/Enroll'
import { PROJECT_ID } from '../../actions/Constants'

import Flavor from '../../util/Flavors'

import S from './LoginScreen.less'

class LoginScreen extends React.Component {

  render() {
    const { login, loginWith2FA, generateTwoFactorCode, logout, reset, auth, userId, twoFactorSettings } = this.props
    const twoFactorMethods = [ {
      type : 'email',
      name : 'Email',
      description : 'Email Description',
      listItem : EmailListItem,
      confirmationComponent : EmailSMSConfirmation,
      preActivateComponent : EmailPreActivate,
      validatePreActivateData : ( data ) => {
        return Validator.isEmail( data != null && data.email )
      },
    } ]

    return (
      <Content>
        <Content.Area className={Flavor.isMarine ? S.area : S.ptArea}>
          <Flex direction="row" align="center" justify="center" vFill>
            {auth.requires2FAEnroll ? 
              (
                <Enroll />
              ) : (
                <Login
                  onLogin={login}
                  onLoginWith2FA={loginWith2FA}
                  onSelect2FAMethod={() => generateTwoFactorCode( userId, 'email' )}
                  // TODO: Fix "Back to Sign In" button without refreshing page - ticket LS-1196
                  onReset2FAVerify={() => window.location.reload()}
                  onLogout={logout}
                  loginByCodeEnabled={false}
                  twoFactorSettings={twoFactorSettings}
                  requires2FA={auth.requires2FA}
                  twoFactorMethods={twoFactorMethods}
                  onResetPassword={reset}
                  logo={Flavor.isMarine ? <img alt="Logo" className={S.logo} src="/images/logo.png" /> : <img alt="Logo" className={S.logo} src="/images/logo-powertech.png" />}
                  headerClassName={S.header}
                  className={S.pane}
                  userClassName={S.user}
                  passwordClassName={S.password}
                  loginClassName={S.login}
                  resetClassName={S.login}
                  errorText={auth.message} />
              )}
          </Flex>
        </Content.Area>
      </Content>
    )
  }
}

export default connect(
  state => ( {
    auth : state.auth,
    userId : state.auth?.profile?.id,
    twoFactorSettings : state.project?.twoFactorSettings
  } ),
  dispatch => ( {
    login : ( user, pass ) => { dispatch( Auth.login( user, pass, PROJECT_ID ) ) },
    loginWith2FA : ( twoFactorCode, selectedTwoFactorMethod, rememberMe ) => dispatch( Auth.loginWith2FA( twoFactorCode, selectedTwoFactorMethod, rememberMe, PROJECT_ID ) ),
    generateTwoFactorCode : ( userId, method ) => dispatch( Users.generateTwoFactorCode( userId, method ) ),
    logout : () => { dispatch( Auth.logout() ) },
    reset : ( email ) => { dispatch( Auth.forgotPassword( email, PROJECT_ID ) ) },
  } )
)( LoginScreen )
