import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, PortalOverlay, Text, Button, Toast } from '@leverege/ui-elements'
import TableWithSelectableRowsAndActions from '../../lib/table/TableWithSelectableRowsAndActions'
import DeviceCustomerMove from './DeviceCustomerMove'
import DeviceAssignCustomer from './DeviceAssignCustomer'
import { CustomerActions, DeviceActions } from '../../../../actions'
import ConfirmationDialog from '../../lib/dialog/ConfirmationDialog'
import Spinner from '../../lib/spinner/Spinner'
import S from './DeviceCustomers.less'


class DeviceCustomers extends Component {

  static get OVERLAY_PORTAL_MODE() {
    return {
      HIDE : 'hide',
      MOVE : 'move',
      ASSIGN : 'assign'
    }
  }

  state = {
    selectedCustomers : [],
    overlayPortalMode : DeviceCustomers.OVERLAY_PORTAL_MODE.HIDE,
    showDialog : false,
    loading : false
  }

  onCustomersSelectionChange = async ( selectedCustomers ) => {
    await this.setState( { selectedCustomers } )
    this.changeDropdownOptions()
  }

  setOverlayPortalMode = ( overlayPortalMode ) => {
    this.setState( { overlayPortalMode } )
  }

  removeCustomerFromDevice = async () => {
    const { selectedCustomers } = this.state
    const { device, dispatch } = this.props;

    this.setState( { showDialog : false, loading : true } )

    try {
      await dispatch( CustomerActions.removeCustomerFromDevice( selectedCustomers[0].id, device.id ) )
      await dispatch( DeviceActions.getDeviceUsers( device.id ) )
      this.setState( { loading : false } )

      Toast.success(
        'Successfully removed the customer from device',
        { containerId : 'toastDefault' }
      )
    } catch ( error ) {
      this.setState( { loading : false } )
    }
  }

  afterDeviceMoved = async () => {
    const { device, dispatch } = this.props;

    this.setOverlayPortalMode( DeviceCustomers.OVERLAY_PORTAL_MODE.HIDE )

    this.setState( { loading : true } )

    try {
      await dispatch( DeviceActions.getDeviceUsers( device.id ) )
      this.setState( { loading : false } )
    } catch ( error ) {
      this.setState( { loading : false } )
    }
  }

  afterCustomerAssigned = async () => {
    const { device, dispatch } = this.props;

    this.setOverlayPortalMode( DeviceCustomers.OVERLAY_PORTAL_MODE.HIDE )

    this.setState( { loading : true } )

    try {
      await dispatch( DeviceActions.getDeviceUsers( device.id ) )
      this.setState( { loading : false } )
    } catch ( error ) {
      this.setState( { loading : false } )
    }
  }

  changeDropdownOptions = () => {
    const { selectedCustomers } = this.state
    const { onChangeDropdownOptions } = this.props

    let dropdownOptions = []

    if ( selectedCustomers.length === 0 ) {
      dropdownOptions = dropdownOptions.concat( [
        { key : 1, name : 'Assign Customer', value : 1, onClick : () => this.setOverlayPortalMode( DeviceCustomers.OVERLAY_PORTAL_MODE.ASSIGN ) }
      ] ) 
    } else if ( selectedCustomers.length === 1 ) {
      dropdownOptions = dropdownOptions.concat( [
        { key : 2, name : `Remove Customer (${selectedCustomers.length})`, value : 2, onClick : () => this.setState( { showDialog : true } ) },
        { key : 3, name : `Move Device (${selectedCustomers.length})`, value : 3, onClick : () => this.setOverlayPortalMode( DeviceCustomers.OVERLAY_PORTAL_MODE.MOVE ) }
      ] ) 
    } else {
      dropdownOptions = dropdownOptions.concat( [
        { key : 2, name : `Remove Customer (${selectedCustomers.length})`, value : 2, onClick : () => this.setState( { showDialog : true } ) },
      ] )
    }


    onChangeDropdownOptions( dropdownOptions )
  }

  componentDidMount = async () => {
    const { dispatch, device } = this.props

    this.changeDropdownOptions()

    if ( !device.users ) {
      this.setState( { loading : true } )
      await dispatch( DeviceActions.getDeviceUsers( device.id ) )
      this.setState( { loading : false } )
    }
  }

  render() {
    const { overlayPortalMode, selectedCustomers, showDialog, loading } = this.state
    const { device } = this.props    

    const { users : customers } = device
    
    const columns = [
      { Header : 'Customers', accessor : 'email' }
    ]

    const listSelectedCustomersEmail = selectedCustomers.map( a => a.email )

    return (
      <Flex variant="colM" className={S.contentArea}>
        {loading && <Spinner />}
        {
          !loading && ( customers && customers.length > 0 ?
            (
              <Flex variant="colM" className={S.tableArea}>
                <TableWithSelectableRowsAndActions
                  className={S.table}
                  data={customers}
                  columns={columns}
                  loading={false}
                  onSelectionChange={customers => this.onCustomersSelectionChange( customers )} />
              </Flex>
            ) :
            (
              <Flex variant="colM" className={S.noAssignedContentArea}>
                <Flex variant="contentTitle">
                  <Text variant="sectionTitle">
                    Device Unassigned
                  </Text>
                </Flex>
                <Flex variant="rowM">
                  <Text variant="contentInfo" className={S.info}>
                    This device is not assigned to a customer
                  </Text>
                </Flex>
                <Flex variant="rowM">
                  <Button
                    variant="default"
                    onClick={() => this.setOverlayPortalMode( DeviceCustomers.OVERLAY_PORTAL_MODE.ASSIGN )}>
                    Assign Device
                  </Button>
                </Flex>
              </Flex>
            )
          )
        }
        <PortalOverlay
          show={overlayPortalMode !== DeviceCustomers.OVERLAY_PORTAL_MODE.HIDE}
          targetId="mainScreen"
          animation="slideFromRight"
          position="right">
          <div>
            {overlayPortalMode === DeviceCustomers.OVERLAY_PORTAL_MODE.MOVE && (
              <DeviceCustomerMove
                device={device}
                afterDeviceMoved={this.afterDeviceMoved}
                onClose={() => this.setOverlayPortalMode( DeviceCustomers.OVERLAY_PORTAL_MODE.HIDE )} />
            )}
            {overlayPortalMode === DeviceCustomers.OVERLAY_PORTAL_MODE.ASSIGN && (
              <DeviceAssignCustomer
                device={device}
                afterCustomerAssigned={this.afterCustomerAssigned}
                onClose={() => this.setOverlayPortalMode( DeviceCustomers.OVERLAY_PORTAL_MODE.HIDE )} />
            )}
          </div>
        </PortalOverlay>
        {selectedCustomers[0] && (
          <ConfirmationDialog
            show={showDialog}
            headerText={`Remove customer ${listSelectedCustomersEmail} from the device: ${device.esn}`}
            bodyText="Are you sure you want to remove this customer from the device?"
            onDeny={() => this.setState( { showDialog : false } )}
            onConfirm={() => this.removeCustomerFromDevice()} />
        )}
      </Flex>
    )
  }
}


export default connect( () => ( {} ) )( DeviceCustomers )
