import React from 'react'
import { Dialog } from '@leverege/ui-elements'

import SendToDeviceDialog from './SendToDeviceDialog'
import SendToDevicesDialog from './SendToDevicesDialog'
import SendToTrackDialog from './SendToTrackDialog'
import SendToArchiveDialog from './SendToArchiveDialog'
import SendToUnarchiveDialog from './SendToUnarchiveDialog'


export default function SendFirmwareDialog( { type, show, ...rest } ) {

  const { onClose } = rest

  const renderDialog = ( type ) => {
    switch ( type ) {
      case 'device': return <SendToDeviceDialog {...rest} />
      case 'devices': return <SendToDevicesDialog {...rest} />
      case 'track': return <SendToTrackDialog {...rest} />
      case 'archive': return <SendToArchiveDialog {...rest} />
      case 'unarchive': return <SendToUnarchiveDialog {...rest} />
      default: return <SendToTrackDialog {...rest} />
    }
  }

  return (
    <Dialog show={show} onClose={onClose}>
      {renderDialog( type )}
    </Dialog>
  )
}
