module.exports = {
  "hierarchy": {
    "base": [
      "table-base"
    ],
    "basePortalV2": [
      "table-base",
      "table-basePortalV2"
    ]
  },
  "props": {
    "default": {
      "sortable": false
    },
    "base": {
      "sortable": false
    },
    "basePortalV2": {
      "sortable": false
    }
  }
}