
function getSystemFromBrowserUrl() {
  return getSystemFromUrl( window.location.hostname )
}

/**
 * @param {String} url
 */
function getSystemFromUrl( url ) {
  if ( url.includes( 'localhost' ) ) {
    // Default when local
    return '@sirenmarine'
  }

  let siteId = url.split( '.' )
  siteId = Array.isArray( siteId ) ? siteId[0] : null

  if ( siteId && siteId.includes( 'powertech' ) ) {
    return '@powertech'
  }
  return '@sirenmarine'
}

module.exports = {
  getSystemFromBrowserUrl
}
