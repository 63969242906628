import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Content, Toast } from '@leverege/ui-elements'

import { Auth } from '@leverege/api-redux'
import MainScreen from './main/MainScreen'
// import SettingsScreen from './settings/SettingsScreen'
import AnalyticsScreen from './analytics/AnalyticsScreen'
import LoginScreen from '../login/LoginScreen'
import ResetScreen from '../login/ResetScreen'

import { Auth as AuthActions, UserActions } from '../../actions'
import S from './App.less'

class App extends React.Component {

  componentDidMount() {
    const { verify } = this.props
    verify()
  }

  render() {
    const { auth, router, getUserInfo, getRoles, getProjectTwoFactorSettings } = this.props

    getProjectTwoFactorSettings()

    if ( Auth.isVerifying( auth ) ) {
      return null
    }

    if ( !Auth.isLoggedIn( auth ) ) {
      return (
        <Switch location={router.location}>
          <Route path="/resetPassword" component={ResetScreen} />
          <Route component={LoginScreen} />
        </Switch>
      )
    }  
    getUserInfo( auth.profile.id )
    getRoles()
    

    return (
      <Content>
        <Toast containerId="toastDefault" autoClose={5000} />
        <Content.Area className={S.area}>
          <Switch location={router.location}>
            <Route path="/analytics" component={AnalyticsScreen} />
            {/* <Route path="/settings" component={SettingsScreen} />*/}
            <Route component={MainScreen} />
          </Switch>
        </Content.Area>
      </Content>
    )
  }
}


export default connect(
  state => ( {
    auth : state.auth,
    router : state.router
  } ),
  dispatch => ( {
    verify : arg => dispatch( Auth.verify( arg ) ),
    getUserInfo : userId => dispatch( UserActions.getUserInfo( userId ) ),
    getRoles : () => dispatch( UserActions.getRoles() ),
    // TODO: auth migration -> get project two factor settings from api-redux
    getProjectTwoFactorSettings : () => dispatch( AuthActions.getProjectTwoFactorSettings() )
  } ) 
)( App )
