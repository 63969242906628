import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Content, Flex, Text, Toast } from '@leverege/ui-elements'
import { Firmware } from '../../../accessors'
import { FirmwareActions } from '../../../actions'

import S from './SendToArchiveDialog.less'

class SendToArchiveDialog extends Component {

  constructor( props ) {
    super( props )
  }

  onSubmit = () => {
    const { onClose, firmware, firmwares, dispatch } = this.props

    try {
      let i;
      for ( i = 0; i < firmwares.length; i++ ) {
        const fw = firmwares[i]
        dispatch( FirmwareActions.sendFirmwareToArchive( fw ) ) 
        Toast.success( `Version ${Firmware.name( fw )} has been archived`,
          { containerId : 'toastDefault' }
        )
      }

    } catch ( err ) {
      let i;
      for ( i = 0; i < firmwares.length; i++ ) {
        const fw = firmwares[i]
        Toast.error( `Failed to archive Version ${Firmware.name( fw )}`,
          { containerId : 'toastDefault' }
        )
      }
    }
    onClose()
  }

  render() {
    const { show, onClose, firmware, firmwares } = this.props

    const listSelectedActiveFirmwares = firmwares.map( a => Firmware.name( a ) )

    return (
      <Content relative>
        <Content.Header>
          <Text className={S.sendToArchiveTitle}>Archive Firmwares</Text>
        </Content.Header>
        <Content.Area>
          <Flex variant="colM">
            <Flex>
              {listSelectedActiveFirmwares.length === 1 ? <Text className={S.sendToArchiveSubTitle}>{`Are you sure you want to archive Firmware Version ${listSelectedActiveFirmwares} ?`}</Text> :
              <Text className={S.sendToArchiveSubTitle}>{`Are you sure you want to archive Firmware Version(s) ${listSelectedActiveFirmwares} ?`}</Text>}
            </Flex>
          </Flex>
        </Content.Area>
        <Content.Footer>
          <Flex variant="buttons">
            <Button onClick={() => this.onSubmit()}>{`Archive (${listSelectedActiveFirmwares.length})`}</Button>
            <Button variant="cancelButton" type="button" onClick={onClose}>Close</Button>
          </Flex>
        </Content.Footer>
      </Content>
    )
  }
}

export default connect( state => ( {

} ) )( SendToArchiveDialog )
