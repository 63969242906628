module.exports = {
  "hierarchy": {},
  "props": {
    "default": {
      "selectionType": "tags",
      "checkVariant": "multiSelector|small",
      "popupVariant": "multiSelector|selector",
      "removeButtonVariant": "multiSelector",
      "tagInputVariant": "multiSelector",
      "toggleVariant": "multiSelector|selector"
    }
  }
}