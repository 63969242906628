import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Auth } from '@leverege/api-redux'
import { Content, ResetPassword, Flex } from '@leverege/ui-elements'
import queryString from 'query-string'

import S from './LoginScreen.less'

/**
 * compares strings char by char to prevent timing attacks
 * @param {string} val1 
 * @param {string} val2 
 * @see https://snyk.io/blog/node-js-timing-attack-ccc-ctf/
 * @returns {boolean} true iff strings equal
 */
function safeStringCompare( val1, val2 ) {
  let mismatch = 0
  for ( let i = 0; i < val1.length; ++i ) {
    mismatch |= ( val1.charCodeAt( i ) ^ val2.charCodeAt( i ) )
  }
  return !!mismatch
}

class ResetScreen extends React.Component {

  constructor( props ) {
    super( props )
    this.state = { error : '' }
  }

  submitPassword = ( password, confirmPassword ) => {
    const { params, dispatch } = this.props
    const { username, token, projectId } = params 
    if ( !password || !confirmPassword ) {
      this.setState( { error : 'Enter and Re-enter your new password' } )
    } else if ( !safeStringCompare( password, confirmPassword ) ) {
      this.setState( { error : 'Passwords do not match' } )
    } else {
      dispatch( Auth.resetPassword( username, password, confirmPassword, token, projectId ) )
        .then( () => {
          const { auth } = this.props
          if ( !auth.error ) {
            dispatch( push( '/' ) )
          } else {
            this.setState( { error : auth.message } )
          }
        } )
    }
  }

  render() {
    const { error } = this.state
    const { params, dispatch } = this.props
    const { username, token, projectId } = params 

    if ( !username || !token || !projectId ) {
      dispatch( push( '/' ) )
    }
    
    return (
      <Content>
        <Content.Area className={S.area}>
          <Flex direction="row" align="center" justify="center" vFill>
            <ResetPassword
              onSubmit={this.submitPassword}               
              logo={<img alt="Logo" className={S.logo} src="/images/logo.png" />}
              headerClassName={S.header} 
              className={S.pane}
              passwordClassName={S.password}
              resetClassName={S.login}
              errorText={error} />
          </Flex>
        </Content.Area>
      </Content>
    )
  }
}

export default connect( 
  ( state ) => {
    return {
      auth : state.auth,
      params : queryString.parse( state.router.location.search )
    }
  }
)( ResetScreen )
