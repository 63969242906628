import Constants, { INVENTORY_NETWORK_ID } from './Constants'
import System from '../util/System'

function getInventoryDevices( query, page, perPage ) {
  return async ( dispatch, getState, { api } ) => {

    dispatch( { type : Constants.INVENTORY_LOADING, payload : { loading : true } } )

    try {
      let total, count, items

      if ( !query ) {
        // Requires authz "Project -> Inventory Device -> List Inventory Device" permission
        ( { total, count, items } = await api.interface( Constants.SYSTEM_ID, 'inventory' ).list( { startIndex : page * perPage, perPage } ) )

        items = items.map( item => mapDeviceData( item ) )
      } else {
        // Requires authz "Project -> Inventory Device -> List Inventory Device" permission
        ( { items } = await api.interface( Constants.SYSTEM_ID, 'inventory' ).list( { perPage : 10_000 } ) )

        items = items.filter( ( deviceData ) => {
          const data = mapDeviceData( deviceData )
          const { esn, lastTester, iccid, imsi, firmwareNumber } = data
          return String( esn ).includes( query ) ||
            String( iccid ).includes( query ) ||
            String( imsi ).includes( query ) ||
            String( lastTester ).includes( query ) ||
            String( firmwareNumber ).includes( query )
        } )

        items = items.map( item => mapDeviceData( item ) )
        total = items.length
        count = items.length
        perPage = 15
      }

      dispatch( { type : Constants.INVENTORY_LOADED, payload : { total, count, items, page, perPage, } } )
    } catch ( error ) {
      dispatch( { type : Constants.INVENTORY_LOADED, payload : { items : [], page, perPage } } )
    }

    dispatch( { type : Constants.INVENTORY_LOADING, payload : { loading : false } } )
  }
}

function mapDeviceData( deviceData ) {
  return {
    id : deviceData?.id,
    lastModified : deviceData?.lastModified,
    name : deviceData?.name,
    esn : deviceData?.networkAliases?.[INVENTORY_NETWORK_ID]?.esn,
    iccid : deviceData?.networkAliases?.[INVENTORY_NETWORK_ID]?.iccid,
    imsi : deviceData?.networkAliases?.[INVENTORY_NETWORK_ID]?.imsi,
    lastTestResult : deviceData?.data?.testResult,
    lastTester : deviceData?.data?.tester,
    tester : deviceData?.data?.tester,
    created : deviceData?.data?.bornOn,
    firmwareNumber : deviceData?.data?.firmwareNumber,
  }
}

function exportInventory( start, end, columns, format = 'json' ) {
  return async ( dispatch, getState, { api } ) => {
    try {
      // Requires authz "Project -> Inventory Device -> List Inventory Device" permission
      const { items } = await api.interface( Constants.SYSTEM_ID, 'inventory' ).list( { perPage : 10_000 } )
      if ( items?.length === 0 ) {
        throw new Error( 'No data on the given range' )
      }

      let filteredData = items
        .map( item => mapDeviceData( item ) )
        .filter( ( item ) => {
          let filtered = true
          if ( start ) {
            filtered = filtered && item.created >= start
          }
          if ( end ) {
            filtered = filtered && item.created <= end
          }
          return filtered
        } )

      if ( format === 'csv' ) {
        filteredData = makeCsv( filteredData )
      }

      const headers = columns.map( column => column.Header ).join( ',' )
      const data = filteredData.map( ( filteredDevice ) => {
        return columns.map( ( column ) => {
          return column.accessor( filteredDevice )
        } ).join( ',' )
      } )
      const csvData = [ headers, ...data ].join( '\n' )
      const systemAlias = System.getSystemFromBrowserUrl()
      const flavor = systemAlias.replace( '@', '' )
      const filename = `${flavor}-${start || 'all'}-${end || 'now'}.csv`
      const blob = new Blob( [ csvData ], { type : 'text/csv;charset=utf-8;' } )
      if ( navigator.msSaveBlob ) {
        navigator.msSaveBlob( blob, filename )
      } else {
        const link = document.createElement( 'a' )
        if ( link.download !== undefined ) {
          const url = URL.createObjectURL( blob )
          link.setAttribute( 'href', url )
          link.setAttribute( 'download', filename )
          link.style.visibility = 'hidden'
          document.body.appendChild( link )
          link.click()
          document.body.removeChild( link )
        }
      }
    } catch ( error ) {
      throw new Error( 'No data on the given range' )
    }
  }
}

function makeCsv( filteredData ) {
  const fields = [
    { name : 'esn', title : 'ESN' },
    { name : 'iccid', title : 'ICCID' },
    { name : 'imsi', title : 'IMSI' },
    { name : 'lastTestResult', title : 'Testing Result' },
    { name : 'lastTester', title : 'Tester' },
    { name : 'created', title : 'Created At', accessor : item => new Date( item.created ).toISOString() },
    { name : 'firmwareNumber', title : 'Firmware Number' }
    
  ]
  const fieldsNames = fields.map( f => f.title || f.name )
  const headers = `"${fieldsNames.join( '","' )}"\n`
  const body = filteredData.reduce( ( lines, item ) => {
    let line = fields.map( ( field ) => {
      if ( field.accessor ) {
        return field.accessor( item )
      }
      return item[field.name] || ''
    } ).join( '","' )
    line = `"${line}"\n`
    return lines + line
  }, '' )
  return headers + body
}

function clearInventory() {
  return { type : Constants.INVENTORY_CLEAR }
}


module.exports = {
  getInventoryDevices,
  exportInventory,
  clearInventory,
}
