import Constants from '../actions/Constants'

const getAllFirmwareDataPath = () => `${Constants.SYSTEM_ID}/firmware`

const getDevicesTrackDataPathById = trackId => `${Constants.SYSTEM_ID}/track/${trackId}/devices`
const getFirmwaresTrackDataPathById = trackId => `${Constants.SYSTEM_ID}/track/${trackId}/firmwares`
const getAllTrackDataPath = () => `${Constants.SYSTEM_ID}/track`


function splitByLen( str, len ) {
  return str.split( '' ).reduce( ( acc, value, index ) => {
    const pos = Math.floor( index / len )
    acc[pos] = acc[pos] || ''
    acc[pos] += value
    return acc
  }, [] )
}

function replaceAll( str, reg, replacement ) {
  return str.split( '' ).map( p => p.replace( reg, replacement ) ).join( '' )
}

exports.replaceAll = replaceAll
exports.splitByLen = splitByLen
exports.getAllFirmwareDataPath = getAllFirmwareDataPath
exports.getAllTrackDataPath = getAllTrackDataPath
exports.getDevicesTrackDataPathById = getDevicesTrackDataPathById
exports.getFirmwaresTrackDataPathById = getFirmwaresTrackDataPathById
