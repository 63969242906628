import P from '@leverege/promise'
import Constants from './Constants'
import ImagineDevice from '../util/ImagineDevice'

function getDevicesByNetworkAlias( deviceIds ) {

  return async ( dispatch, getState, { api } ) => {
    const res = await P.mapLimit( deviceIds.split( ',' ), async ( deviceId ) => {
      let res

      try {
        res = await api.getDeviceByNetworkAlias( process.env.NETWORK_ID, 'esn', deviceId )
      } catch ( ex ) {
        console.error( ex )
      }

      if ( res ) {
        return api.interface( Constants.SYSTEM_ID, 'boat' ).obj( res.id ).get()
      }

      try {
        res = await api.getDeviceByNetworkAlias( process.env.NETWORK_ID, 'imsi', deviceId )
      } catch ( ex ) {
        console.error( ex )
        throw ex
      }

      return api.interface( Constants.SYSTEM_ID, 'boat' ).obj( res.id ).get()
    }, { handleReject : true, limit : 5 } )

    return res
  }
}

function getDevices( query ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.DEVICES_LOADING, payload : { loading : true } } )

    try {
      // requires "Imagine -> Device -> list" authz perm
      const path = `v1/system/${Constants.SYSTEM_ID}/device?search=${query}`
      const res = await api.get( path )
      const resDevices = res?.items
      if ( !resDevices?.length > 0 ) {
        return dispatch( { type : Constants.DEVICES_LOADED, payload : { devices : [] } } )
      }

      const devices = resDevices
        .filter( item => item.blueprintId === process.env.BOAT_BLUEPRINT_ID )
        .map( item => ( { 
          ...item, 
          esn : ImagineDevice.getESN( item ), 
          imsi : ImagineDevice.getIMSI( item ) 
        } ) )
        .filter( item => item.esn )
        .map( item => ImagineDevice.formatBoatDeviceData( item ) )
      dispatch( { type : Constants.DEVICES_LOADED, payload : { devices } } )
    } catch ( error ) {
      dispatch( { type : Constants.DEVICES_LOADED, payload : { devices : [] } } )
    }

    dispatch( { type : Constants.DEVICES_LOADING, payload : { loading : false } } )
  }
}

function getDevice( deviceId ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.SET_SELECTED_DEVICE, payload : {} } )
    try {
      // Requires "Imagine -> Device -> read" authz perm
      const deviceFromApi = await api.interface( Constants.SYSTEM_ID, 'boat' ).obj( deviceId ).get()
      const deviceEsn = ImagineDevice.getESN( deviceFromApi )
      const deviceData = ImagineDevice.formatBoatDeviceData( deviceFromApi )
      const device = { ...deviceFromApi, ...deviceData, esn : deviceEsn }

      dispatch( setSelectedDevice( device ) )
    } catch ( err ) {
      console.error( err )
    }
  }
}

function clearDeviceAlertRecipients( deviceId ) {
  return async ( dispatch, getState, { api } ) => {
    try {
      // Requires "Imagine -> Device -> write" authz perm
      // const res = await api.device( deviceId ).setValue( 'alertRecipients', [ ] )
      const res = await api.device( deviceId ).setValue( [
        { path : 'alertRecipients', value : [] },
        { path : 'email', value : '' },
        { path : 'emailEnabled', value : false },
        { path : 'phone', value : '' },
        { path : 'phoneEnabled', value : false },
        { path : 'dailyReportsEnabled', value : false },
      ] )
      console.log( 'Cleared alert recipients', res )
    } catch ( err ) {
      console.error( 'Err clearing alert recipients', err )
    }
  }
}

function sendCommand( id, msg ) {
  return async ( dispatch, getState, { api } ) => {
    try {
      // Imagine Device sendMessage authz perm -> if you have Ship Device Manager perm
      const res = await api.interface( Constants.SYSTEM_ID, 'boat' ).obj( id ).messages().send( msg )
      console.log( 'Sent msg', res )
    } catch ( err ) {
      console.log( 'Err sending command', err )
    }
  }
}

function getDeviceSensors() {
  return async ( dispatch, getState, { api } ) => {
    const { device : { selectedDevice } } = getState()
    if ( selectedDevice ) {
      const { id } = selectedDevice
      if ( id ) {
        // Requires "Project -> Siren3 -> List wireless in a Siren3" authz perm
        const sensorsRes = await api.interface( Constants.SYSTEM_ID, 'boat' ).obj( id ).grp( 'wireless' ).list()
        const sensors = sensorsRes.items

        selectedDevice.sensors = ( sensors ) || []
        dispatch( setSelectedDevice( selectedDevice ) )
      }
    }
  }
}

function getDeviceUsers() {
  return async ( dispatch, getState, { serverApi } ) => {
    const { device : { selectedDevice } } = getState()
    if ( selectedDevice ) {
      const { id } = selectedDevice
      if ( id ) {
        // LS-1641 keep, gets users from fleets (fb db)
        // reads from fb db paths:
        //   rt/:systemId/fleets/:fleetId
        //   rt/:systemId/dev/:deviceId/fleets
        const response = await serverApi.get( `device/${id}/users` )
        if ( !( response.status && response.status === 404 ) ) {
          selectedDevice.users = response
          dispatch( setSelectedDevice( selectedDevice ) )
        }
      }
    }
  }
}

function deleteNmeaChannels( channels ) {
  return async ( dispatch, getState, { api } ) => {
    const { device : { selectedDevice } } = getState()
    if ( selectedDevice ) {
      const { id } = selectedDevice
      if ( id ) {
        let dataKeys
        try {
          dataKeys = Object.keys( selectedDevice.data.vessel.nmea )
        } catch ( err ) {
          dataKeys = null
        }

        if ( dataKeys === null ) return

        channels.forEach( ( channel ) => { 
          if ( dataKeys.includes( channel ) ) {
            selectedDevice.data.vessel.nmea[channel] = null
          }
        } )

        // Requires "Imagine -> Device -> [update, updateData, updateAnyData]" authz perms
        await api.interface( Constants.SYSTEM_ID, 'boat' ).obj( id ).update( { 
          data : selectedDevice.data
        }, {
          resolveOnWritten : { writers : [ 'rt' ], maxWaitTime : 5000 },
          refresh : 'wait_for',
        } )
      }
    }
  }
}

function setSelectedDevice( device ) {
  return ( dispatch ) => {
    dispatch( { type : Constants.SET_SELECTED_DEVICE, payload : { device } } )
  }
}

module.exports = {
  sendCommand,
  getDevicesByNetworkAlias,
  getDevices,
  getDevice,
  getDeviceSensors,
  getDeviceUsers,
  setSelectedDevice,
  deleteNmeaChannels,
  clearDeviceAlertRecipients,
}
