// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle_lrnhJ {
  width: 56px;
  height: 28px;
  outline: none;
}
.toggle_lrnhJ .background_OJiho {
  height: 100%;
  border-radius: 28px;
  background-color: var(--color-background3);
}
.toggle_lrnhJ[data-disabled=true] .background_OJiho {
  background-color: var(--color-disabled);
}
.toggle_lrnhJ[data-value=true] .background_OJiho {
  background-color: var(--color-brand1);
}
.toggle_lrnhJ:focus[data-value=true] .background_OJiho {
  background-color: var(--color-brand3);
}
.toggle_lrnhJ:hover[data-value=true] .background_OJiho {
  background-color: var(--color-brand2);
}
.toggle_lrnhJ[data-disabled=true][data-value=true] .background_OJiho {
  background-color: var(--color-disabled);
}
.toggle_lrnhJ:active[data-value=true] .background_OJiho {
  background-color: var(--color-brand4);
}
.toggle_lrnhJ .handle_cc_xF {
  width: 24px;
  margin: 2px;
  height: 24px;
  outline: none;
  border-radius: 50%;
  background-color: var(--color-white);
}
.toggle_lrnhJ[data-value=true] .handle_cc_xF {
  transform: translate(28px, 0);
}
.toggle_lrnhJ.toggle-small_wJDPc {
  width: 40px;
  height: 24px;
}
.toggle_lrnhJ.toggle-small_wJDPc[data-value=true] {
  width: 40px;
  height: 24px;
}
.toggle_lrnhJ.toggle-small_wJDPc .background_OJiho {
  background-color: #d2d5d6;
}
.toggle_lrnhJ.toggle-small_wJDPc[data-value=true] .background_OJiho {
  background-color: #20cf86;
}
.toggle_lrnhJ.toggle-small_wJDPc .handle_cc_xF {
  width: 20px;
  height: 20px;
}
.toggle_lrnhJ.toggle-small_wJDPc[data-value=true] .handle_cc_xF {
  margin-left: -10px;
}
`, "",{"version":3,"sources":["webpack://./src/theme/Toggle.css"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;AACF;AACA;EACE,YAAA;EACA,mBAAA;EACA,0CAAA;AACF;AACA;EACE,uCAAA;AACF;AACA;EACE,qCAAA;AACF;AACA;EACE,qCAAA;AACF;AACA;EACE,qCAAA;AACF;AACA;EACE,uCAAA;AACF;AACA;EACE,qCAAA;AACF;AACA;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,oCAAA;AACF;AACA;EACE,6BAAA;AACF;AACA;EACE,WAAA;EACA,YAAA;AACF;AACA;EACE,WAAA;EACA,YAAA;AACF;AACA;EACE,yBAAA;AACF;AACA;EACE,yBAAA;AACF;AACA;EACE,WAAA;EACA,YAAA;AACF;AACA;EACE,kBAAA;AACF","sourcesContent":[".toggle {\n  width: 56px;\n  height: 28px;\n  outline: none;\n}\n.toggle .background {\n  height: 100%;\n  border-radius: 28px;\n  background-color: var( --color-background3 );\n}\n.toggle[data-disabled=true] .background {\n  background-color: var( --color-disabled );\n}\n.toggle[data-value=true] .background {\n  background-color: var( --color-brand1 );\n}\n.toggle:focus[data-value=true] .background {\n  background-color: var( --color-brand3 );\n}\n.toggle:hover[data-value=true] .background {\n  background-color: var( --color-brand2 );\n}\n.toggle[data-disabled=true][data-value=true] .background {\n  background-color: var( --color-disabled );\n}\n.toggle:active[data-value=true] .background {\n  background-color: var( --color-brand4 );\n}\n.toggle .handle {\n  width: 24px;\n  margin: 2px;\n  height: 24px;\n  outline: none;\n  border-radius: 50%;\n  background-color: var( --color-white );\n}\n.toggle[data-value=true] .handle {\n  transform: translate( 28px, 0 );\n}\n.toggle.toggle-small {\n  width: 40px;\n  height: 24px;\n}\n.toggle.toggle-small[data-value=true] {\n  width: 40px;\n  height: 24px;\n}\n.toggle.toggle-small .background {\n  background-color: #d2d5d6;\n}\n.toggle.toggle-small[data-value=true] .background {\n  background-color: #20cf86;\n}\n.toggle.toggle-small .handle {\n  width: 20px;\n  height: 20px;\n}\n.toggle.toggle-small[data-value=true] .handle {\n  margin-left: -10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `toggle_lrnhJ`,
	"background": `background_OJiho`,
	"handle": `handle_cc_xF`,
	"toggle-small": `toggle-small_wJDPc`
};
export default ___CSS_LOADER_EXPORT___;
