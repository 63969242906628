import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Flex, ToggleButton } from '@leverege/ui-elements'
import { getAllowedMenuItems, getUserRole } from '../menu-items'
import { Auth } from '../../../actions'

import S from './Menu.less'

const Menu = ( { router, user, navigate, usersRoles, logout } ) => {
  const path = router.location.pathname
  const role = getUserRole( user )
  const menus = getAllowedMenuItems( role, usersRoles )

  return (
    <Flex direction="column" align="start">
      <Flex variant="headerLogo" className={S.logoContainer}>
        <img alt="Logo" className={S.logo} src="/images/logo-IoT.png" style={{ paddingLeft : 20 }} />
      </Flex>
      <Flex direction="column" align="start" variant="sidebarMenu" className={S.menuList}>
        {menus.map( ( { value, eventData, title, icon }, i ) => (
          <Flex key={`menu-item-${eventData}-${i}`} justify="space-around" variant="rowM">
            <ToggleButton
              variant="navLink"
              value={value( path )}
              eventData={eventData}
              onChange={evt => navigate( evt.data )}>
              <img src={icon} alt={title} />
              <div style={{ paddingTop : 3 }}>{title}</div>
            </ToggleButton>
          </Flex>
        ) )}
      </Flex>
      <Flex>
        <ToggleButton 
          variant="logoutLink"
          onChange={logout}>
          <span style={{ fontWeight : 500, fontSize : '15px', lineHeight : '20px' }}>Logout</span>
          <img src="/images/svg/white/logout.svg" style={{ paddingLeft : 10, margin : '-3px' }} />
        </ToggleButton>
      </Flex>
    </Flex>
  )
}

export default connect(
  state => ( {
    router : state.router,
    user : state.user.user,
    usersRoles : state.user.usersRoles
  } ),
  dispatch => ( {
    navigate : to => dispatch( push( to ) ),
    logout : () => { dispatch( Auth.logout() ) },
  } )
)( Menu )
