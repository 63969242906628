import { Constants } from '../actions'

const INITIAL_STATE = {
  loading : false,
  firmwareVersionHistory : []
}

const history = ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    case Constants.GET_BOAT_HISTORY_LOADING:
      return { ...state, loading : true }
    case Constants.GET_BOAT_HISTORY:
      return { ...state, firmwareVersionHistory : action.payload.result, loading : false }        
    default:
      return state;
  }    
}


module.exports = history
