import Path from '@leverege/path'
import { format, parseISO } from 'date-fns'

import { NOT_AVAILABLE } from '../actions/Constants'

const vesselName = new Path( 'data/vessel/name' ).get
const name = new Path( 'data/name' ).get

const nameAndEsn = ( device ) => {
  const { esn, networkAliases : { 'siren-marine' : { esn : esn2 } } } = device
  const deviceName = name( device )
  let n = esn || esn2
  if ( deviceName !== esn ) {
    n = `${n} - ${deviceName}`
  }
  return n
}

const firmwareFileGetter = ( file ) => {
  return ( data ) => {

    const firmwares = new Path( 'data/versionInfo/files' ).get( data )
    const firmware = firmwares && firmwares[file]
    if ( firmware ) {
      return firmware
    }

    return NOT_AVAILABLE
  }
}

const mainFirmware = firmwareFileGetter( 'main' )
const hostFirmware = firmwareFileGetter( 'host' )
const sensorFirmware = firmwareFileGetter( 'leaf' )
const repeaterFirmware = firmwareFileGetter( 'repeater' )

const lastFirmwareSentToDevice = ( data ) => {
  const firmwares = new Path( 'firmware' ).get( data )

  let firmwareUpdate = NOT_AVAILABLE
  if ( firmwares && firmwares.length > 0 ) {
    const firmwareOrderedByTime = firmwares.sort( ( a, b ) => {
      if ( a.time > b.time ) return -1
      if ( a.time < b.time ) return 1
      return 0
    } )

    const mostRecentFirmwareUpdate = firmwareOrderedByTime[0]
    firmwareUpdate = format( mostRecentFirmwareUpdate.time, 'PPPP' )
    if ( mostRecentFirmwareUpdate.author ) {
      firmwareUpdate += ` By ${mostRecentFirmwareUpdate.author}`
    }
  }

  return firmwareUpdate
}

const checkIn = ( data ) => {
  const time = new Path( 'data/updateTime' ).get( data )

  if ( time ) {
    const raw = parseISO( new Date( time ).toISOString() ) // TODO

    if ( raw ) {
      return format( raw, 'MM/dd/yyyy HH:mm' )
    }
  }

  return NOT_AVAILABLE
}

const networkId = new Path( 'networkId' ).get

const esn = ( device ) => {
  const nId = networkId( device )
  return new Path( `networkAliases/${nId}/esn` ).get( device )
}

const iccid = ( device ) => {
  const nId = networkId( device )
  return new Path( `networkAliases/${nId}/iccid` ).get( device )
}

const imsi = ( device ) => {
  const nId = networkId( device )
  return new Path( `networkAliases/${nId}/imsi` ).get( device )
}

const getGPSInfo = ( device ) => {
  const gpsInfo = new Path( 'data/lmu/status' ).get( device )

  let gpsAntenna = NOT_AVAILABLE
  let gpsSelfTest = NOT_AVAILABLE
  let gpsReceiver = NOT_AVAILABLE
  if ( gpsInfo ) {
    const { GPSAntennaStatus, GPSReceiverSelfTest, GPSReceiverTracking } = gpsInfo
    gpsAntenna = GPSAntennaStatus ? 'OK' : 'Error'
    gpsSelfTest = GPSReceiverSelfTest ? 'OK' : 'Error'
    gpsReceiver = GPSReceiverTracking ? 'OK' : 'Error'
  }

  return { gpsAntenna, gpsSelfTest, gpsReceiver }
}

const getAlertRecipients = ( device ) => {
  let mainAlertRecipient = []
  let alertRecipients = []

  if ( device?.data?.mainAlertRecipient ) {
    const devMainAlertRecipient = new Path( 'data/mainAlertRecipient' ).get( device )
    if ( devMainAlertRecipient ) {
      mainAlertRecipient = [ {
        accountOwner : true,
        name : 'Account Owner',
        ...devMainAlertRecipient
      } ]
    }
  }
  if ( device?.data?.alertRecipients ) {
    const devAlertRecipients = Object.values( new Path( 'data/alertRecipients' ).get( device ) )
    if ( devAlertRecipients ) {
      alertRecipients = devAlertRecipients.map( rec => ( {
        accountOwner : false,
        email : rec?.email?.value || null,
        emailEnabled : rec?.email?.active || false,
        phone : rec?.mobile?.value || null,
        phoneEnabled : rec?.mobile?.active || false,
        dailyReportsEnabled : rec?.dailyReportsEnabled,
        name : rec?.name || null,
      } ) )
    }
  }

  alertRecipients = [ ...mainAlertRecipient, ...alertRecipients ]
  alertRecipients = alertRecipients.map( ( rec, i ) => ( { index : i, ...rec } ) )

  return alertRecipients
}

module.exports = {
  vesselName,
  name,
  nameAndEsn,
  checkIn,
  esn,
  iccid,
  imsi,
  networkId,
  mainFirmware,
  hostFirmware,
  sensorFirmware,
  repeaterFirmware,
  lastFirmwareSentToDevice,
  getGPSInfo,
  getAlertRecipients
}
