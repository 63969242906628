// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_pW2Q2 {
  color: var(--color-label1);
  border: 1px solid var(--color-border1);
  overflow: hidden;
  font-family: var(--fontFamily-primary);
}
.table_pW2Q2 .row_w8QR2 {
  border-bottom: 1px solid var(--color-border1);
}
.table_pW2Q2 .headerGroup__TnWa {
  z-index: 1;
  border-bottom: 1px solid var(--color-border1);
}
.table_pW2Q2 .headerCell_Pw5sJ {
  padding: var(--size-padMedium) var(--size-large);
  outline: none !important;
  border-right: 1px solid var(--color-border1);
}
.table_pW2Q2 .headerCell_Pw5sJ:last-child {
  border: none;
}
.table_pW2Q2 .headerCell_Pw5sJ.sortDesc_EWBpl {
  box-shadow: inset 0px -3px var(--color-brand1);
}
.table_pW2Q2 .headerCell_Pw5sJ.sortAsc_xgFD_ {
  box-shadow: inset 0px 3px var(--color-brand1);
}
.table_pW2Q2 .bodyCell_puyVu {
  padding: var(--size-padMedium) var(--size-large);
  border-right: 1px solid var(--color-border1);
}
.table_pW2Q2 .bodyCell_puyVu:last-child {
  border-right: none;
}
.table_pW2Q2 .bodyCell_puyVu:first-child {
  border-left: none;
}
`, "",{"version":3,"sources":["webpack://./src/theme/TableV2.css"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,sCAAA;EACA,gBAAA;EACA,sCAAA;AACF;AACA;EACE,6CAAA;AACF;AACA;EACE,UAAA;EACA,6CAAA;AACF;AACA;EACE,gDAAA;EACA,wBAAA;EACA,4CAAA;AACF;AACA;EACE,YAAA;AACF;AACA;EACE,8CAAA;AACF;AACA;EACE,6CAAA;AACF;AACA;EACE,gDAAA;EACA,4CAAA;AACF;AACA;EACE,kBAAA;AACF;AACA;EACE,iBAAA;AACF","sourcesContent":[".table {\n  color: var( --color-label1 );\n  border: 1px solid var( --color-border1 );\n  overflow: hidden;\n  font-family: var( --fontFamily-primary );\n}\n.table .row {\n  border-bottom: 1px solid var( --color-border1 );\n}\n.table .headerGroup {\n  z-index: 1;\n  border-bottom: 1px solid var( --color-border1 );\n}\n.table .headerCell {\n  padding: var( --size-padMedium ) var( --size-large );\n  outline: none !important;\n  border-right: 1px solid var( --color-border1 );\n}\n.table .headerCell:last-child {\n  border: none;\n}\n.table .headerCell.sortDesc {\n  box-shadow: inset 0px -3px var( --color-brand1 );\n}\n.table .headerCell.sortAsc {\n  box-shadow: inset 0px 3px var( --color-brand1 );\n}\n.table .bodyCell {\n  padding: var( --size-padMedium ) var( --size-large );\n  border-right: 1px solid var( --color-border1 );\n}\n.table .bodyCell:last-child {\n  border-right: none;\n}\n.table .bodyCell:first-child {\n  border-left: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `table_pW2Q2`,
	"row": `row_w8QR2`,
	"headerGroup": `headerGroup__TnWa`,
	"headerCell": `headerCell_Pw5sJ`,
	"sortDesc": `sortDesc_EWBpl`,
	"sortAsc": `sortAsc_xgFD_`,
	"bodyCell": `bodyCell_puyVu`
};
export default ___CSS_LOADER_EXPORT___;
