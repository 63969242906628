const { Constants, Auth, Users } = require( '@leverege/api-redux' )
const { SET_TWO_FACTOR_SETTINGS, PROJECT_ID } = require( './Constants' )

function login( username, password ) {
  return Auth.login( username, password, PROJECT_ID ) 
}

function loginWith2FA( twoFactorCode, selectedTwoFactorMethod, rememberMe ) {
  return async ( dispatch, getState, { serverApi } ) => {
    dispatch( Auth.loginWith2FA( twoFactorCode, selectedTwoFactorMethod, rememberMe, PROJECT_ID ) )
  }
}

async function verify() {
  const r = await Auth.verify()
  return r
}

function generateTwoFactorCode( userId, method ) {
  return ( dispatch, getState ) => {
    dispatch( Users.generateTwoFactorCode( userId, method ) )
  }
}

function getProjectTwoFactorSettings( ) {
  return async ( dispatch, getState, { projectId } ) => {
    // TODO: Get this object from firebase '$fbHost/project/$projectId/twoFactorOptions' ticket LS-1196
    dispatch( { 
      type : SET_TWO_FACTOR_SETTINGS,
      payload : {
        enabled : true,
        jwtTokenExpiration : '10m',
        rememberMeEnabled : false,
        rememberMeTokenExpiration : '30d',
        required : true,
        supportedProtocols : {
          'authenticator-app' : false,
          email : true,
          sms : false,
        }
      }
    } )
  }
}

function passwordResetEmail( emailAddress ) {
  return ( dispatch, getState ) => {
    const email = emailAddress.toLowerCase()
    return Auth.forgotPassword( email, PROJECT_ID )
  }
}

function changePassword( email, password, newPassword, done = () => { } ) {
  return Auth.changePassword( password, newPassword )
}

function logout() {
  return Auth.logout()
}

function isLoggedIn( auth ) {
  return Auth.isLoggedIn( auth )
}

function isVerifying( auth ) {
  return auth.loading === Constants.AUTH_VERIFYING
}

module.exports = {
  isLoggedIn,
  isVerifying,
  loginWith2FA,
  verify,
  generateTwoFactorCode,
  getProjectTwoFactorSettings,
  login,
  passwordResetEmail,
  changePassword,
  logout
}
