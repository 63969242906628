import React from 'react'
import { connect } from 'react-redux'
import Path from '@leverege/path'
import { Form, Flex, Text, Button, Label, TextArea, Toast } from '@leverege/ui-elements'
import { BeatLoader } from 'react-spinners'

import { Device } from '../../../accessors'
import { DeviceActions } from '../../../actions'

import S from './DeviceListEditor.less'

class DeviceListEditor extends React.Component {
  static contextType = Form.Context

  constructor( props ) {
    super( props )

    this.state = {
      adding : false,
      deviceId : '',
      checking : false,
      error : null
    }

    this.inputRef = React.createRef()
    this.reader = new FileReader()
    this.reader.onload = this.onFileLoaded
  }

  toggleAdding = () => {
    const { adding } = this.state
    this.setState( {
      adding : !adding,
      deviceId : '',
      error : null
    } )
  }

  toggleAddingCsv = () => {
    const { addingCsv } = this.state
    this.setState( {
      addingCsv : !addingCsv,
      deviceId : '',
      error : null
    } )
  }

  onChange = ( { value } ) => {
    this.setState( {
      deviceId : value
    } )
  }

  onAdd = () => {
    const { validator } = this.context
    let { deviceId } = this.state
    const { dispatch, name } = this.props

    const val = validator.value( name ) || []

    this.setState( { checking : true } )

    deviceId = deviceId.replace( /\s/g, '' )

    dispatch( DeviceActions.getDevicesByNetworkAlias( deviceId ) )
      .then( ( res ) => {

        const devices = []

        res.forEach( ( item ) => {
          if ( item.status && item.status >= 300 ) {
            let message = item.message

            if ( item.status === 404 ) {
              message = `Unknown device: ${item.message.substring( item.message.indexOf( '/' ) + 1 )}`
            }
            Toast.error( message, { containerId : 'toastDefault', autoClose : false } )
          } else if ( val.find( dev => dev.id === item.id ) ) {
            const esn = Device.esn( item )
            Toast.info( `${esn} is already added to this group.`, { containerId : 'toastDefault', autoClose : false } )
          } else {
            devices.push( item )
          }
        } )

        this.setState( {
          checking : false,
          adding : false,
          error : null,
          deviceId : ''
        } )

        validator.onInputChange( {
          data : name,
          value : [
            ...val,
            ...devices
          ]
        } )
      } )
  }

  onRemove = ( { data, value } ) => {
    const { name } = this.props
    const { validator } = this.context
    const val = validator.value( name )

    const remainingDevices = val.filter( item => item.id !== data )

    validator.onInputChange( {
      value : remainingDevices,
      data : name
    } )
  }

  onFileLoaded = async () => {
    const { validator } = this.context
    const { dispatch, name } = this.props

    const val = validator.value( name ) || []

    await this.setState( { checking : true } )

    const reg = new RegExp( '^[0-9]*$' )
    const fileContent = this.reader.result
    const esns = fileContent
      .split( '\n' )
      .map( line => line
        .replace( /(\r\n|\n|\r)/gm, '' )
        .replace( /"/g, '' )
      )
      .filter( line => reg.test( line ) )
      .filter( line => line !== '' )

    if ( esns.length === 0 ) {
      Toast.error( 'We couldn\'t identify valid ESNs in this file.', { containerId : 'toastDefault', autoClose : false } )
      await this.setState( { checking : false } )
      return
    }

    const promises = esns.map( esn => dispatch( DeviceActions.getDevicesByNetworkAlias( esn ) ) )
    const res = await Promise.all( promises )

    const devices = []
    res.forEach( ( r ) => {
      const item = r[0]
      if ( item.status && item.status >= 300 ) {
        let message = item.message

        if ( item.status === 404 ) {
          message = `Unknown device: ${item.message.substring( item.message.indexOf( '/' ) + 1 )}`
        }
        Toast.error( message, { containerId : 'toastDefault', autoClose : false } )
      } else {
        devices.push( item )
      }
    } )

    this.setState( {
      checking : false,
      addingCsv : false,
      error : null,
      deviceId : ''
    } )

    validator.onInputChange( {
      data : name,
      value : [
        ...val,
        ...devices
      ]
    } )
  }

  onFileError = () => {
    Toast.error( 'Error on selecting the CSV file', { containerId : 'toastDefault', autoClose : false } )
  }

  onFileChanged = () => {
    const files = Path.get( 'current/files', this.inputRef )

    if ( !files || files.length === 0 ) {
      Toast.error( 'Invalid file', { containerId : 'toastDefault', autoClose : false } )
      return
    }

    const file = files[0]
    const ext = file.name.split( '.' ).pop()
    if ( ext !== 'csv' ) {
      Toast.error( 'Please select a CSV file', { containerId : 'toastDefault', autoClose : false } )
      return
    }

    this.reader.readAsText( file )
  }

  render() {
    const { name } = this.props
    const { adding, addingCsv, deviceId, checking, error } = this.state
    const { validator } = this.context
    const val = validator.value( name )

    return (
      <Flex variant="colM">
        <Flex align="center" variant="rowM">
          <Label variant="fixedWidth" htmlFor="name">Devices</Label>
          {( !adding && !addingCsv ) && (
            <React.Fragment>
              <Button onClick={this.toggleAdding} variant="small">Add</Button>
              <Button onClick={this.toggleAddingCsv} variant="small">Upload CSV</Button>
            </React.Fragment>
          )}
        </Flex>
        {adding && (
          <Flex variant="colM">
            <Flex align="center" variant="rowM">
              <TextArea
                variant="portalForm"
                value={deviceId}
                onChange={this.onChange}
                hint="Enter ESNs, separated by commas" />
              {!checking ? (
                <React.Fragment>
                  <Button variant="small" onClick={this.toggleAdding}>Cancel</Button>
                  <Button variant="small" onClick={this.onAdd}>Add</Button>
                </React.Fragment>
              ) : (
                <BeatLoader size="10" color="blue" />
              )}
            </Flex>
            {error && <Text variant="error">{error}</Text>}
          </Flex>
        )}
        {addingCsv && (
          <Flex variant="colM">
            <Flex align="center" variant="rowM">
              <input
                type="file"
                name="csv-file"
                ref={this.inputRef}
                onChange={this.onFileChanged} />
              {!checking && <Button variant="small" onClick={this.toggleAddingCsv}>Cancel</Button>}
            </Flex>
            <Text variant="hintText">Your file must include a header that says "ESN"</Text>
            {checking && <BeatLoader size="10" color="blue" />}
            {error && <Text variant="error">{error}</Text>}
          </Flex>
        )}
        <Flex variant="colM" className={S.deviceList}>
          {val && val.length > 0 ? val
            .sort( ( a, b ) => {
              const nameA = Device.vesselName( a )
              const nameB = Device.vesselName( b )

              if ( nameA > nameB ) {
                return 1
              }

              if ( nameB > nameA ) {
                return -1
              }

              return 0
            } )
            .map( ( item ) => {

              return (
                <Flex key={item.id} align="center" justify="space-between" style={{ marginLeft : 16 }}>
                  <Text>{Device.vesselName( item, item.name )}</Text>
                  <Button eventData={item.id} onClick={this.onRemove} variant="remove" />
                </Flex>
              )
            } ) : (
              <Text>No Devices</Text>
          )}
        </Flex>
      </Flex>
    )
  }
}

export default connect( state => ( {
  model : state.data
} ) )( DeviceListEditor )
