import { Interface } from '@leverege/api-redux'
import Constants from './Constants'

function createFirmwareVersion( data ) {
  return async ( dispatch, getState, { serverApi } ) => {
    const formData = Object
      .entries( data )
      .reduce( ( prev, curr ) => {
        if ( curr[1] ) {
          prev.append( curr[0], curr[1] )
        }

        return prev
      }, new FormData() )

    try {
      const json = await serverApi.postFormData( 'firmware', { body : formData } )
      return json
    } catch ( ex ) {
      console.error( ex )

      return ex
    }
  }
}

async function getDeviceByESN( api, esn ) {
  try {
    return await api.getDeviceByNetworkAlias( process.env.NETWORK_ID, 'esn', esn )
  } catch ( ex ) {
    return ex
  }
}

async function sendFirmware( portalServerUrl, api, firmwareId, deviceId ) {
  try {
    const firmware = await api.interface( Constants.SYSTEM_ID, 'firmware' ).obj( firmwareId ).get()

    const outboundMessageResults = await sendFirmwareOutboundMessages( portalServerUrl, api, deviceId, firmware )
    if ( outboundMessageResults?.[0]?.status !== 200 ) throw new Error( 'Invalid request' )

    const updateRes = await updateDeviceFirmwareData( portalServerUrl, api, deviceId, firmware )
    if ( !updateRes.created ) {
      console.error( `Firmware update sent to device, but device data not update for device with id ${deviceId}` )
    }

    return {
      deviceId,
      ...outboundMessageResults[0],
    }
  } catch ( ex ) {
    console.error( { deviceId, ex } )
    return {
      deviceId,
      ex
    }
  }
}

async function sendFirmwareOutboundMessages( portalServerUrl, api, deviceId, firmware ) {
  const { binaries, version } = firmware.data
  const versionBytes = version.split( '.' ).map( v => parseInt( v, 10 ) )

  const promiseCbArray = Object.keys( binaries ).map( ( file ) => {
    const message = {
      path : 'vessel/firmware',
      value : {
        firmwareVersion : Buffer.from( versionBytes ).toString( 'hex' ),
        firmwareUrl : `${portalServerUrl}/v1/firmware/${firmware.id}/${file}.bin`,
      }
    }

    // LS-1762 This causes api-server to publish a message to the siren-marine-outbound topic
    // with the message firmware data. Sometimes udp server doesn't pick up those messages. Strangely,
    // udp server always seems to pick up identical messages produced from api.device().message()
    // So if messages from this call aren't being picked up, try api.device().message()
    return () => api
      .interface( Constants.SYSTEM_ID, 'boat' )
      .obj( deviceId )
      .messages()
      .send( message )
  } )
  return Promise.all( promiseCbArray.map( fn => fn() ) )
}

/**
  * Gets an inboundDataEvent message to be routed.
  * @param {Object} firmwareData
  */
async function updateDeviceFirmwareData( portalServerUrl, api, deviceId, firmware ) {
  const time = Date.now()
  const { binaries, version } = firmware.data
  const versionBytes = version.split( '.' ).map( v => parseInt( v, 10 ) )

  //  Update device data on firebase
  const updateObj = {
    data : {
      version : { },
      vessel : { 
        firmware : { 
          firmwareVersion : Buffer.from( versionBytes ).toString( 'hex' ),
        }
      }
    }
  }

  Object.keys( binaries ).forEach( ( file ) => {
    updateObj.data.version[file] = { }
    updateObj.data.version[file].time = time
    updateObj.data.version[file].status = 'sent'
    updateObj.data.version[file].firmwareId = firmware.id
    updateObj.data.version[file].version = version
    updateObj.data.version[file].url = `/v1/firmware/${firmware.id}/${file}.bin`
    updateObj.data.vessel.firmware.firmwareUrl = `${portalServerUrl}/v1/firmware/${firmware.id}/${file}.bin`
  } )

  // Updating data publishes a message on the device route
  // requires "Imagine -> Device -> [update, updateData, updateAnyData]" authz perms
  return api.interface( Constants.SYSTEM_ID, 'boat' ).obj( deviceId ).update( updateObj )
}

function sendFirmwareToDevice( firmwareId, esn ) {
  return async ( dispatch, getState, { api, portalServerUrl } ) => {
    const device = await getDeviceByESN( api, esn )
    return sendFirmware( portalServerUrl, api, firmwareId, device.id )
  }
}

function sendFirmwareToDevices( firmwareId, devicesIds ) {
  return async ( dispatch, getState, { api, portalServerUrl } ) => {
    return Promise.all( devicesIds.map( devId => sendFirmware( portalServerUrl, api, firmwareId, devId ) ) )
  }
}

/**
 * TODO: The UI functionality to call this is broken.
 * If Siren wants to keep the groups feature then the UI dropdown needs to be fixed
 * and this functionality needs to be tested!!!
 * 
 * Commented out portion of this function is WIP switch to only @leverege/api usage
 * as part of LS-1762
 * @param {*} firmwareId 
 * @param {*} trackId 
 * @returns 
 */
function sendFirmwareToTrack( firmwareId, trackId ) {
  return async ( dispatch, getState, { serverApi, portalServerUrl } ) => {
    try {
      return await serverApi.post( `firmware/${firmwareId}/track/${trackId}` )

      // const trackDevicesRes = await api.interface( Constants.SYSTEM_ID, 'track' )
      //   .obj( trackId )
      //   .grp( 'devices' )
      //   .obj( '*' )
      //   .get()
      // const trackDevices = trackDevicesRes.items
      // if ( !trackDevices ) throw new Error( 'No devices in group' )

      // const sendFirmwareToDevicesP = trackDevices.map( device => () => sendFirmware( portalServerUrl, api, firmwareId, device.id ) )
      // return Promise.all( sendFirmwareToDevicesP.map( p => p() ) )
    } catch ( ex ) {
      return ex
    }
  }
}

function getAllFirmwares() {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.FIRMWARE_LOADING, payload : { loading : true } } )
    try {
      // Requires "Imagine -> Firmware Version -> List Firmware Version" authz permission
      const allFirmwaresUnfiltered = await api.interface( Constants.SYSTEM_ID, 'firmware' ).list( { perPage : 1000 } )

      const activeFirmwaresFiltered = allFirmwaresUnfiltered.items.filter( ( el ) => {
        return !( el.data && el.data.archived )
      } )
      const archivedFirmwaresFiltered = allFirmwaresUnfiltered.items.filter( ( el ) => {
        return ( el.data && el.data.archived )
      } )

      dispatch( { type : Constants.FIRMWARE_LOADED, payload : { activeFirmwares : activeFirmwaresFiltered, archivedFirmwares : archivedFirmwaresFiltered } } )

    } catch ( error ) {
      dispatch( { type : Constants.FIRMWARE_LOADED, payload : { activeFirmwares : [], archivedFirmwares : [] } } )
    }
    dispatch( { type : Constants.FIRMWARE_LOADING, payload : { loading : false } } )

  }
}

function sendFirmwareToArchive( selectedActiveFirmware ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.FIRMWARE_LOADING, payload : { loading : true } } )

    try {
      // Requires "Imagine -> Device -> updateAnyData" authz permission
      await api.interface( Constants.SYSTEM_ID, 'firmware' ).obj( selectedActiveFirmware.id ).update( {
        data : {
          archived : true
        }
      }, {
        resolveOnWritten : { writers : [ 'rt' ], maxWaitTime : 5000 },
        refresh : 'wait_for',
      } )

      // Requires "Imagine -> Firmware Version -> List Firmware Version" authz permission
      const allFirmwaresUnfiltered = await api.interface( Constants.SYSTEM_ID, 'firmware' ).list( { perPage : 1000 } )

      const activeFirmwaresFiltered = allFirmwaresUnfiltered.items.filter( ( el ) => {
        return !( el.data && el.data.archived )
      } )
      const archivedFirmwaresFiltered = allFirmwaresUnfiltered.items.filter( ( el ) => {
        return ( el.data && el.data.archived )
      } )

      dispatch( { type : Constants.FIRMWARE_LOADED, payload : { activeFirmwares : activeFirmwaresFiltered, archivedFirmwares : archivedFirmwaresFiltered } } )
    } catch ( error ) {
      dispatch( { type : Constants.FIRMWARE_LOADED, payload : { activeFirmwares : [], archivedFirmwares : [] } } )
    }
    dispatch( { type : Constants.FIRMWARE_LOADING, payload : { loading : false } } )
  }
}

function sendFirmwareToActive( selectedArchivedFirmware ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.FIRMWARE_LOADING, payload : { loading : true } } )
    try {
      // Requires "Imagine -> Device -> updateAnyData" authz permission
      await api.interface( Constants.SYSTEM_ID, 'firmware' ).obj( selectedArchivedFirmware.id ).update( {
        data : {
          archived : false
        }
      }, {
        resolveOnWritten : { writers : [ 'rt' ], maxWaitTime : 5000 },
        refresh : 'wait_for',
      } )

      // Requires "Imagine -> Firmware Version -> List Firmware Version" authz permission
      const allFirmwaresUnfiltered = await api.interface( Constants.SYSTEM_ID, 'firmware' ).list( { perPage : 1000 } )

      const activeFirmwaresFiltered = allFirmwaresUnfiltered.items.filter( ( el ) => {
        return !( el.data && el.data.archived )
      } )
      const archivedFirmwaresFiltered = allFirmwaresUnfiltered.items.filter( ( el ) => {
        return ( el.data && el.data.archived )
      } )
      dispatch( { type : Constants.FIRMWARE_LOADED, payload : { activeFirmwares : activeFirmwaresFiltered, archivedFirmwares : archivedFirmwaresFiltered } } )
    } catch ( error ) {
      dispatch( { type : Constants.FIRMWARE_LOADED, payload : { activeFirmwares : [], archivedFirmwares : [] } } )
    }
    dispatch( { type : Constants.FIRMWARE_LOADING, payload : { loading : false } } )
  }
}

function downloadFirmwareFile( firmwareId, name, file ) {
  return async ( dispatch, getState, { portalServerUrl } ) => {
    const fileUrl = `${portalServerUrl}/v2/firmware/${firmwareId}/${file}.bin`
    const link = document.createElement( 'a' )
    if ( typeof link.download === 'string' ) {
      document.body.appendChild( link ) // Firefox requires the link to be in the body
      link.download = `v${name}-${file}.bin`
      link.href = fileUrl
      link.click()
      document.body.removeChild( link ) // remove the link when done
    } else {
      window.location.replace( fileUrl )
    }
  }
}

function updateFirmware( firmwareId, data = {} ) {
  const { notes } = data

  return async ( dispatch, getState ) => {
    const firmware = Interface
      .create( Constants.SYSTEM_ID, 'firmware' )
      .obj( firmwareId )
    return dispatch(
      firmware.update( { data : { notes } } )
    )
  }
}


exports.createFirmwareVersion = createFirmwareVersion
exports.updateFirmware = updateFirmware
exports.downloadFirmwareFile = downloadFirmwareFile
exports.sendFirmwareToDevice = sendFirmwareToDevice
exports.sendFirmwareToDevices = sendFirmwareToDevices
exports.sendFirmwareToTrack = sendFirmwareToTrack
exports.getAllFirmwares = getAllFirmwares
exports.sendFirmwareToArchive = sendFirmwareToArchive
exports.sendFirmwareToActive = sendFirmwareToActive
