// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiSelector_Mxp6a {
  padding-left: 0.35em;
  padding-right: 0.35em;
  padding-bottom: var(--size-padSmall);
}
.multiSelector_Mxp6a .tag_u5Trq {
  border: 1px solid var(--color-background3);
  padding: 0.35em;
  margin-top: var(--size-padSmall);
  margin-left: var(--size-padSmall);
  border-radius: var(--size-padSmall);
  background-color: var(--color-background3);
}
`, "",{"version":3,"sources":["webpack://./src/theme/MultiSelector.css"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,qBAAA;EACA,oCAAA;AACF;AACA;EACE,0CAAA;EACA,eAAA;EACA,gCAAA;EACA,iCAAA;EACA,mCAAA;EACA,0CAAA;AACF","sourcesContent":[".multiSelector {\n  padding-left: 0.35em;\n  padding-right: 0.35em;\n  padding-bottom: var( --size-padSmall );\n}\n.multiSelector .tag {\n  border: 1px solid var( --color-background3 );\n  padding: 0.35em;\n  margin-top: var( --size-padSmall );\n  margin-left: var( --size-padSmall );\n  border-radius: var( --size-padSmall );\n  background-color: var( --color-background3 );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiSelector": `multiSelector_Mxp6a`,
	"tag": `tag_u5Trq`
};
export default ___CSS_LOADER_EXPORT___;
