import React from 'react'
import Desktop from './components/desktop/App'
 
export default class BootApp extends React.Component {
  
  componentDidMount() {

    const url = window.location.hostname

    function getFaviconEl() {
      return document.getElementById( 'favicon' )
    }

    function handleLocalhost() {
      const favicon = getFaviconEl();
      favicon.href = '/favicons/favicon-sm.png'
    }

    function handleSirenmarine() {
      const favicon = getFaviconEl();
      favicon.href = '/favicons/favicon-sm.png'
    }

    function handlePowertech() {
      const favicon = getFaviconEl();
      favicon.href = '/favicons/favicon-pt.png'
    }

    if ( url.includes( 'localhost' ) ) {
      handleLocalhost()
      return
    } 

    let siteId = url.split( '.' )
    siteId = Array.isArray( siteId ) ? siteId[0] : null
    
    if ( siteId && siteId.includes( 'powertech' ) ) {
      handlePowertech()
            
    } else {
      handleSirenmarine()
      
    }


  }
  
  render() {    
    return <Desktop />
  }
}
