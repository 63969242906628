const Path = require( '@leverege/path' )

const getNetworkAliasItem = ( model, key, prePath, dVal ) => {
  const networkId = Path.get( `${prePath}/networkId`, model, dVal )
  return Path.get( `${prePath}/networkAliases/${networkId}/${key}`, model, dVal )
}

const getESN = ( model, prePath = '', dVal ) => {
  return getNetworkAliasItem( model, 'esn', prePath, dVal )
}

const getIMSI = ( model, prePath = '', dVal ) => {
  return getNetworkAliasItem( model, 'imsi', prePath, dVal )
}

const getName = ( model, prePath = '', dVal ) => {
  return Path.get( `${prePath}/vessel/name`, model, dVal )
}

const getMainAlertRecipient = ( model, prePath = '' ) => {
  const email = Path.get( `${prePath}/email`, model )
  const emailEnabled = Path.get( `${prePath}/emailEnabled`, model, false )
  const dailyReportsEnabled = Path.get( `${prePath}/dailyReportsEnabled`, model, false )
  const phone = Path.get( `${prePath}/phone`, model )
  const phoneEnabled = Path.get( `${prePath}/phoneEnabled`, model, false )

  return {
    email, emailEnabled, dailyReportsEnabled, phone, phoneEnabled
  }
}

const getFirmware = ( model, prePath = '', dVal = [] ) => {
  const firmware = Path.get( `${prePath}/version`, model, [] )
  return Object.keys( firmware ).map( key => ( { type : key, ...firmware[key] } ) )
}

const formatBoatDeviceData = ( boatDevice ) => {
  const name = getName( boatDevice.data )
  const firmware = getFirmware( boatDevice.data )
  
  return {
    ...boatDevice,
    name,
    firmware,
    data : {
      ...boatDevice.data,
      name,
      versionInfo : boatDevice?.data?.vessel?.versionInfo,
      mainAlertRecipient : getMainAlertRecipient( boatDevice?.data ),
    }
  }
}

module.exports = {
  getESN,
  getIMSI,
  formatBoatDeviceData,
}
