// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deviceList_Fx2vm {
  width: 250px;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/tracks/DeviceListEditor.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF","sourcesContent":[".deviceList {\n  width: 250px;\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deviceList": `deviceList_Fx2vm`
};
export default ___CSS_LOADER_EXPORT___;
