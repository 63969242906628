import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Button, Text, TextInput } from '@leverege/ui-elements'
import { activateTwoFactor, preActivateTwoFactor } from '../../../actions/UserActions'

import S from './TwoFactor.css'

function EmailActivate( props ) {
  const { auth, enrollData, onChange, activateTwoFactor, preActivateTwoFactor } = props

  const onSubmit = async () => {
    activateTwoFactor( auth.idToken, auth.profile.id, enrollData.code )
  }

  const interceptOnChange = ( { data, value, isReturn } ) => {
    onChange( { data, value } )
    if ( isReturn ) {
      activateTwoFactor( 
        auth.idToken, 
        auth.profile.id, 
        data === 'code' ? value : enrollData.code 
      )
    } 
  }

  const onResendCode = async () => {
    preActivateTwoFactor( auth.idToken, auth.profile.id, enrollData.email, enrollData.password )
  }

  return (
    <React.Fragment>
      <Text 
        variant="contentTitle"
        hint="Enter Six Digit Code"
        className={classnames( S.contentTitle )}>
        Enter Six Digit Code
      </Text>
      <Text 
        variant="default"
        className={classnames( S.contentText )}>
        We sent a security code to
        {' '}
        {enrollData.email}
      </Text>
      <TextInput
        hint="Enter Six Digit Code"
        value={enrollData.code}
        onChange={interceptOnChange}
        eventData="code"
        variant="formInput"
        alwaysFireOnReturn
        className={classnames( S.textInput )} />
      <Text
        className={classnames( S.resendCodeText )}>
        Didn&apos;t receive a code? 
        {' '}
        <span 
          onClick={onResendCode}
          className={classnames( S.resendCode )}>
          Resend Code
        </span>
      </Text>
      {auth.error ? (
        <Text 
          variant="error"
          className={classnames( S.errorText )}>
          {auth.message}
        </Text>
      ) : null}
      <Button 
        onClick={onSubmit}
        type="submit"
        className={classnames( S.button )}>
        Next
      </Button>
    </React.Fragment>
  )
}

export default connect(
  state => ( {
    auth : state.auth,
  } ),
  dispatch => ( {
    activateTwoFactor : ( token, userId, code ) => dispatch( activateTwoFactor( token, userId, code ) ),
    preActivateTwoFactor : ( token, userId, email, password ) => dispatch( preActivateTwoFactor( token, userId, email, password ) )
  } )
)( EmailActivate )
