import Path from '@leverege/path'
import compare from 'semver/functions/compare'
import capitalize from 'lodash/capitalize'

const name = new Path( 'data/name' ).get
const binaries = ( data ) => {
  const binaries = {}
  const files = [ 'main', 'host', 'leaf', 'repeater' ]
  files.forEach( ( file ) => {
    binaries[file] = {
      name : 'Not Available',
      version : 'Not Available',
      file : capitalize( file )
    }
  } )
  if ( !data.firmwares ) {
    return binaries
  }
  data.firmwares
    .sort( ( f1, f2 ) => {
      return compare( f1.data.version, f2.data.version ) 
    } )
    .filter( f => f && f.data !== null )
    .forEach( ( firmware ) => {      
      Object.keys( firmware.data.binaries ).forEach( ( file ) => {
        binaries[file] = {
          name : firmware.name,
          version : firmware.data.version,
          file : capitalize( file )
        }
      } )
    } )
  return binaries
}

const getBinary = ( data, name ) => {
  return new Path( `${name}/name` ).get( binaries( data ) )    
}

const mainBinary = ( data ) => {
  return getBinary( data, 'main' )
}

const sensorBinary = ( data ) => {  
  return getBinary( data, 'leaf' )
}

const hostBinary = ( data ) => {
  return getBinary( data, 'host' )
}

const repeaterBinary = ( data ) => {  
  return getBinary( data, 'repeater' )
}

const ITEM_TYPE = 'track'
const ITEMS_TARGETED = `${ITEM_TYPE}sTargeted`
const ITEMS_SELECTED = `${ITEM_TYPE}sSelected`

module.exports = {
  name,
  binaries,
  mainBinary,
  sensorBinary,
  hostBinary,
  repeaterBinary,
  ITEM_TYPE,
  ITEMS_SELECTED,
  ITEMS_TARGETED
}
