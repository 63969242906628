// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainer_PKTGR {
  height: 40px;
  background: #FFFFFF;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border: 1px solid #CED6DE;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  color: #28548D;
}
.inputContainer_PKTGR input[type=file] {
  width: 0.1px;
  visibility: hidden;
}
.inputContainer_PKTGR:hover {
  border: 1px solid #28548D;
}
.inputContainer_PKTGR .clipImage_Teorh {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0 !important;
}
.inputContainer_PKTGR .fileNameContainer_jGiP1 {
  overflow: hidden;
  margin: 0 8px 0 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/lib/input/FileUploader.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,6BAAA;EACA,8BAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;AAbA;EAeI,YAAA;EACA,kBAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;AApBA;EAwBI,WAAA;EACA,YAAA;EACA,4BAAA;AADJ;AAzBA;EA8BI,gBAAA;EACA,4BAAA;AAFJ","sourcesContent":[".inputContainer {\n  height: 40px;\n  background: #FFFFFF;\n  padding-left: 10px !important;\n  padding-right: 10px !important;\n  border: 1px solid #CED6DE;\n  box-sizing: border-box;\n  border-radius: 4px;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 15px;\n  line-height: 20px;\n  color: #28548D;\n\n  input[type=file] {\n    width: 0.1px;\n    visibility: hidden;\n  }\n\n  &:hover {\n    border: 1px solid #28548D;\n  }\n\n  .clipImage {\n    width: 24px;\n    height: 24px;\n    margin: 0 8px 0 0 !important;\n  }\n\n  .fileNameContainer {\n    overflow: hidden;\n    margin: 0 8px 0 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `inputContainer_PKTGR`,
	"clipImage": `clipImage_Teorh`,
	"fileNameContainer": `fileNameContainer_jGiP1`
};
export default ___CSS_LOADER_EXPORT___;
