// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radioButton_tCINn {
  width: 32px;
  color: var(--color-brand1);
  height: 32px;
  font-size: var(--size-fontMedium);
}
.radioButton_tCINn .appearance_UURAC {
  border: 1px solid var(--color-brand1);
  outline: none;
  transition: background-color 0.2s, color 0.2s, transform 0.2s;
  border-radius: 100%;
  background-color: transparent;
}
.radioButton_tCINn .appearance_UURAC .icon_Z_wcJ {
  transition: background-color 0.2s, color 0.2s, transform 0.2s;
}
.radioButton_tCINn[data-value=true] {
  color: var(--color-white);
}
.radioButton_tCINn[data-value=true] .appearance_UURAC {
  background-color: var(--color-action1);
}
.radioButton_tCINn[data-value=true] input:focus ~ .appearance_UURAC {
  background-color: var(--color-brand2);
}
.radioButton_tCINn:hover input ~ .appearance_UURAC {
  background-color: var(--color-brand3);
}
.radioButton_tCINn:active[data-value=true] .appearance_UURAC {
  background-color: var(--color-brand4);
}
.radioButton_tCINn[disabled] .appearance_UURAC {
  border: 1px solid var(--color-disabled);
}
.radioButton_tCINn[disabled][data-value=true] .appearance_UURAC {
  background-color: var(--color-disabled);
}
.radioButton_tCINn.radioButton-small_FwBvi {
  width: 20px;
  height: 20px;
  font-size: var(--size-fontSmall);
}
`, "",{"version":3,"sources":["webpack://./src/theme/RadioButton.css"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,0BAAA;EACA,YAAA;EACA,iCAAA;AACF;AACA;EACE,qCAAA;EACA,aAAA;EACA,6DAAA;EACA,mBAAA;EACA,6BAAA;AACF;AACA;EACE,6DAAA;AACF;AACA;EACE,yBAAA;AACF;AACA;EACE,sCAAA;AACF;AACA;EACE,qCAAA;AACF;AACA;EACE,qCAAA;AACF;AACA;EACE,qCAAA;AACF;AACA;EACE,uCAAA;AACF;AACA;EACE,uCAAA;AACF;AACA;EACE,WAAA;EACA,YAAA;EACA,gCAAA;AACF","sourcesContent":[".radioButton {\n  width: 32px;\n  color: var( --color-brand1 );\n  height: 32px;\n  font-size: var( --size-fontMedium );\n}\n.radioButton .appearance {\n  border: 1px solid var( --color-brand1 );\n  outline: none;\n  transition: background-color 0.2s,color 0.2s,transform 0.2s;\n  border-radius: 100%;\n  background-color: transparent;\n}\n.radioButton .appearance .icon {\n  transition: background-color 0.2s,color 0.2s,transform 0.2s;\n}\n.radioButton[data-value=true] {\n  color: var( --color-white );\n}\n.radioButton[data-value=true] .appearance {\n  background-color: var( --color-action1 );\n}\n.radioButton[data-value=true] input:focus ~ .appearance {\n  background-color: var( --color-brand2 );\n}\n.radioButton:hover input ~ .appearance {\n  background-color: var( --color-brand3 );\n}\n.radioButton:active[data-value=true] .appearance {\n  background-color: var( --color-brand4 );\n}\n.radioButton[disabled] .appearance {\n  border: 1px solid var( --color-disabled );\n}\n.radioButton[disabled][data-value=true] .appearance {\n  background-color: var( --color-disabled );\n}\n.radioButton.radioButton-small {\n  width: 20px;\n  height: 20px;\n  font-size: var( --size-fontSmall );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButton": `radioButton_tCINn`,
	"appearance": `appearance_UURAC`,
	"icon": `icon_Z_wcJ`,
	"radioButton-small": `radioButton-small_FwBvi`
};
export default ___CSS_LOADER_EXPORT___;
