import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { AuthReducer, UsersReducer, InterfaceReducer } from '@leverege/api-redux'
import { SelectReducer, UIReducer } from '@leverege/ui-redux'
import UserReducer from './UserReducer'
import DeviceReducer from './DeviceReducer'
import FirmwareReducer from './FirmwareReducer'
import CustomerReducer from './CustomerReducer'
import InventoryReducer from './InventoryReducer'
import HistoryReducer from './HistoryReducer'
import TrackReducer from './TrackReducer'
import ProjectReducer from './ProjectReducer'

const reducers = ( { history } ) => combineReducers( {
  ui : UIReducer,
  auth : AuthReducer,
  select : SelectReducer,
  router : connectRouter( history ),
  users : UsersReducer,
  data : InterfaceReducer,
  user : UserReducer,
  device : DeviceReducer,
  firmware : FirmwareReducer,
  inventory : InventoryReducer,
  history : HistoryReducer,
  customer : CustomerReducer,
  track : TrackReducer,
  project : ProjectReducer
} )

module.exports = reducers
