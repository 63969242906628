import { format } from 'date-fns'

const esn = ( inventoryDevice ) => {
  return inventoryDevice.esn
}

const iccid = ( inventoryDevice ) => {
  return inventoryDevice.iccid
}

const createdAt = ( inventoryDevice ) => {
  const rawTime = inventoryDevice.created
  const formattedDate = format( rawTime, 'E MMM do yyyy HH:mm:ss' )
  return formattedDate
}

const testResult = ( inventoryDevice ) => {
  return inventoryDevice.lastTestResult
}

const tester = ( inventoryDevice ) => {
  return inventoryDevice.lastTester
}

const firmwareNumber = ( inventoryDevice ) => {
  return inventoryDevice.firmwareNumber
}

module.exports = {
  esn,
  iccid,
  createdAt,
  testResult,
  tester,
  firmwareNumber
}
