module.exports = {
  "hierarchy": {
    "vertical": [
      "propertyGrid-vertical"
    ],
    "twoColumns": [
      "propertyGrid-twoColumns"
    ]
  },
  "props": {
    "default": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "vertical": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": true
    },
    "twoColumns": {
      "columns": 2,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    }
  }
}