// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateTimePicker_Wd_ud .base_Ldz_M {
  border: 1px solid var(--color-border1);
  border-radius: var(--size-small);
}
`, "",{"version":3,"sources":["webpack://./src/theme/DateTimePickerV2.css"],"names":[],"mappings":"AAAA;EACE,sCAAA;EACA,gCAAA;AACF","sourcesContent":[".dateTimePicker .base {\n  border: 1px solid var( --color-border1 );\n  border-radius: var( --size-small );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateTimePicker": `dateTimePicker_Wd_ud`,
	"base": `base_Ldz_M`
};
export default ___CSS_LOADER_EXPORT___;
