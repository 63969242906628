import Constants from './Constants'

function getFirmwareVersionHistory( deviceId ) {
 
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.GET_BOAT_HISTORY_LOADING } );
    const period = 90 * 24 * 60 * 60 * 1000
    const params = {
      start : period,
      end : Date.now(),
      paths : [
        [ 'version', 'version', null, 'object' ],
        [ '$time', 'time' ],
      ],
      where : [
        [ 'version', '<>', null ]
      ],
      order : [
        [
          '$time',
          'DESC'
        ]
      ],
      perPage : 10000
    }
  
    try {
      // Requires "Imagine -> Device -> readHistory" authz permission
      const res = await api.interface( Constants.SYSTEM_ID, 'boat' ).obj( deviceId ).history( params )
      dispatch( {
        type : Constants.GET_BOAT_HISTORY,
        // TODO: What is this id? Replace with constant
        payload : { result : res.items, vesselId : '3bDxPfIFLfPfiOOI6CxPRi', start : params.start, end : params.end }
      } );
    } catch ( err ) {
      console.error( err )
      dispatch( { type : Constants.GET_BOAT_HISTORY_ERROR, err } );
    }
  }
}


module.exports = {
  getFirmwareVersionHistory  
}
