// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firmwareSelect_M08Y0 {
  width: 400px;
}
.item_UHlTE {
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/tracks/FirmwareListEditor.less"],"names":[],"mappings":"AACA;EACE,YAAA;AAAF;AAGA;EACE,0BAAA;AADF","sourcesContent":["\n.firmwareSelect {\n  width: 400px;\n}\n\n.item {\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firmwareSelect": `firmwareSelect_M08Y0`,
	"item": `item_UHlTE`
};
export default ___CSS_LOADER_EXPORT___;
