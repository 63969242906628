// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentArea_sojL6 {
  background: white;
  height: 100%;
}
.contentArea_sojL6 .mainColumn_a3s0F {
  height: 100%;
}
.contentArea_sojL6 .mainColumn_a3s0F .topControlsRow_Pixw5 {
  padding: 16px 16px 5px;
}
.contentArea_sojL6 .mainColumn_a3s0F .topControlsRow_Pixw5 .searchIcon_QbQ9P {
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 8px;
}
.contentArea_sojL6 .mainColumn_a3s0F .topControlsRow_Pixw5 .searchInput_Cn62X {
  width: 248px;
}
.contentArea_sojL6 .mainColumn_a3s0F .topControlsRow_Pixw5 .searchButton_MncaP {
  width: 82px;
}
.contentArea_sojL6 .mainColumn_a3s0F .tabBarRow_lwlnz {
  height: 100%;
}
.contentArea_sojL6 .mainColumn_a3s0F .tabBarRow_lwlnz .tabIcon_sQUql {
  filter: invert(50%) sepia(9%) saturate(122%) hue-rotate(161deg) brightness(88%) contrast(87%);
}
.contentArea_sojL6 .mainColumn_a3s0F .tabBarRow_lwlnz .activeTabIcon_TLkBA {
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/firmware/Firmware.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;AACF;AAHA;EAKI,YAAA;AACJ;AANA;EAQM,sBAAA;AACN;AATA;EAWQ,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AACR;AAfA;EAkBQ,YAAA;AAAR;AAlBA;EAsBQ,WAAA;AADR;AArBA;EA2BM,YAAA;AAHN;AAxBA;EA8BQ,6FAAA;AAHR;AA3BA;EAkCQ,4FAAA;AAJR","sourcesContent":[".contentArea{\n  background: white;\n  height: 100%;\n\n  .mainColumn{\n    height: 100%;\n\n    .topControlsRow {\n      padding: 16px 16px 5px;\n\n      .searchIcon {\n        position: absolute;\n        width: 24px;\n        height: 24px;\n        margin: 8px;\n      }\n\n      .searchInput {\n        width: 248px;\n      }\n\n      .searchButton {\n        width: 82px;\n      }\n    }\n\n    .tabBarRow {\n      height: 100%;\n\n      .tabIcon {\n        filter: invert(50%) sepia(9%) saturate(122%) hue-rotate(161deg) brightness(88%) contrast(87%);\n      }\n\n      .activeTabIcon {\n        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentArea": `contentArea_sojL6`,
	"mainColumn": `mainColumn_a3s0F`,
	"topControlsRow": `topControlsRow_Pixw5`,
	"searchIcon": `searchIcon_QbQ9P`,
	"searchInput": `searchInput_Cn62X`,
	"searchButton": `searchButton_MncaP`,
	"tabBarRow": `tabBarRow_lwlnz`,
	"tabIcon": `tabIcon_sQUql`,
	"activeTabIcon": `activeTabIcon_TLkBA`
};
export default ___CSS_LOADER_EXPORT___;
