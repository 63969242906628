import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content, Flex, Button, Text, Checkbox, TextInput, TextArea, Toast } from '@leverege/ui-elements'
import FileUploader from '../../lib/input/FileUploader'
import { FirmwareActions } from '../../../../actions'
import Spinner from '../../lib/spinner/Spinner'
import FirmwareService from '../../../../services/FirmwareService'
import S from './FirmwareEditor.less'


class FirmwareEditor extends Component {

  constructor( props ) {
    super( props )
    this.firmwareService = new FirmwareService()
    const { firmware } = props

    this.state = {
      loading : false,
      name : firmware && firmware.data.name,
      notes : firmware && firmware.data.notes,
      mainFile : null,
      leafFile : null,
      hostFile : null,
      repeaterFile : null,
      mainUnitChecked : !!( firmware && firmware.data.binaries.main ),
      wirelessSensorChecked : !!( firmware && firmware.data.binaries.leaf ),
      wirelessHostChecked : !!( firmware && firmware.data.binaries.host ),
      wirelessRepeaterChecked : !!( firmware && firmware.data.binaries.repeater )
    }
  }

  async onSubmit() {
    const { name, notes, mainFile, leafFile, hostFile, repeaterFile } = this.state
    const { dispatch, firmware, author, afterSaveChanges } = this.props
    const firmwareData = {
      name,
      notes,
      host : hostFile,
      repeater : repeaterFile,
      main : mainFile,
      leaf : leafFile
    }
    try {
      this.setState( { loading : true } );

      if ( firmware ) {
        await this.firmwareService.updateFirmware( firmware.id, firmwareData )

        Toast.success( 'Firmware has been successfully updated', { containerId : 'toastDefault' } )
      } else {
        const { id, message } = await dispatch(
          FirmwareActions.createFirmwareVersion( { ...firmwareData, author } ) )

        if ( !id ) {
          Toast.error( message, { autoClose : 8000, containerId : 'toastDefault' } )
        } else {
          Toast.success( 'Firmware has been successfully uploaded', { containerId : 'toastDefault' } )
        }
      }

      this.setState( { loading : false } );

      if ( afterSaveChanges ) {
        afterSaveChanges()
      }
    } catch ( { message } ) {
      Toast.error( message, { autoClose : 8000, containerId : 'toastDefault' } )
    }
  }

  download( firmware, name ) {
    const { dispatch } = this.props

    dispatch( FirmwareActions.downloadFirmwareFile( firmware.id, name, name ) )
  }

  render() {
    const {
      mainUnitChecked,
      wirelessSensorChecked,
      wirelessHostChecked,
      wirelessRepeaterChecked,
      name,
      notes,
      loading
    } = this.state
    const { firmware, onClose = () => {} } = this.props

    return (
      <React.Fragment>
        <Content relative className={S.content}>
          <Content.Header className={S.header}>
            <Flex direction="row" className={S.headerRow} justify="space-between">
              <Text className={S.headerTitle}>
                {firmware ? `Update Version ${firmware.data.version}` : 'Upload Firmware'}
              </Text>
              <div onClick={() => onClose()}>
                <img src="/images/cross.png" alt="close" className={S.crossButton} />
              </div>
            </Flex>
          </Content.Header>
          <Content.Area className={S.contentArea}>
            {
              loading ? <Spinner /> : (
                <div>
                  <Flex direction="column">
                    <Flex direction="row">
                      <Text className={S.editorItemTitle}>Name</Text>
                    </Flex>
                    <Flex direction="row">
                      <TextInput
                        value={name}
                        onChange={( { value } ) => this.setState( { name : value } )}
                        className={S.nameInput} />
                    </Flex>
                    <Flex direction="row">
                      <Text className={S.editorItemTitle}>Update Notes</Text>
                    </Flex>
                    <Flex direction="row">
                      <TextArea
                        value={notes}
                        onChange={( { value } ) => this.setState( { notes : value } )}
                        className={S.notesInput} />
                    </Flex>
                  </Flex>
                  {!firmware && ( 
                    <Flex direction="row" justify="space-between" className={S.firmwareTypeItem}>
                      <Text>Main Unit</Text>
                      <Checkbox
                        variant="row"
                        value={mainUnitChecked}
                        onChange={( { value } ) => this.setState( { mainUnitChecked : value } )} />
                    </Flex>
                  )}
                  {
                    !firmware && mainUnitChecked && (
                      <Flex direction="column" className={S.editBlock}>
                        <Flex direction="row">
                          <Text className={S.editorItemTitle}>Upload Firmware</Text>
                        </Flex>
                        <Flex direction="row">
                          <FileUploader
                            className={S.fileUploader}
                            onChange={file => this.setState( { mainFile : file } )} />
                        </Flex>
                      </Flex>                       
                    )
                  }
                  {!firmware && ( 
                    <Flex direction="row" justify="space-between" className={S.firmwareTypeItem}>
                      <Text>Wireless Sensor</Text>
                      <Checkbox
                        variant="row"
                        value={wirelessSensorChecked}
                        onChange={( { value } ) => this.setState( { wirelessSensorChecked : value } )} />
                    </Flex>
                  )}
                  {
                    !firmware && wirelessSensorChecked && (
                      <Flex direction="column" className={S.editBlock}>
                        <Flex direction="row">
                          <Text className={S.editorItemTitle}>Upload Firmware</Text>
                        </Flex>
                        <Flex direction="row">
                          <FileUploader
                            className={S.fileUploader}
                            onChange={file => this.setState( { leafFile : file } )} />
                        </Flex>
                      </Flex>
                    )
                  }
                  {!firmware && ( 
                    <Flex direction="row" justify="space-between" className={S.firmwareTypeItem}>
                      <Text>Wireless Host</Text>
                      <Checkbox
                        variant="row"
                        value={wirelessHostChecked}
                        onChange={( { value } ) => this.setState( { wirelessHostChecked : value } )} />
                    </Flex>
                  )}
                  {
                    !firmware && wirelessHostChecked && (
                      <Flex direction="column" className={S.editBlock}>
                        <Flex direction="row">
                          <Text className={S.editorItemTitle}>Upload Firmware</Text>
                        </Flex>
                        <Flex direction="row">
                          <FileUploader
                            className={S.fileUploader}
                            onChange={file => this.setState( { hostFile : file } )} />
                        </Flex>
                      </Flex>
                    )
                  }
                  {!firmware && (
                    <Flex direction="row" justify="space-between" className={S.firmwareTypeItem}>
                      <Text>Wireless Repeater</Text>
                      <Checkbox
                        variant="row"
                        value={wirelessRepeaterChecked}
                        onChange={( { value } ) => this.setState( { wirelessRepeaterChecked : value } )} />
                    </Flex>
                  )}
                  {
                    !firmware && wirelessRepeaterChecked && (
                      <Flex direction="column" className={S.editBlock}>
                        <Flex direction="row">
                          <Text className={S.editorItemTitle}>Upload Firmware</Text>
                        </Flex>
                        <Flex direction="row">
                          <FileUploader
                            className={S.fileUploader}
                            onChange={file => this.setState( { repeaterFile : file } )} />
                        </Flex>
                      </Flex>
                    )
                  }
                </div>
              )
            }
          </Content.Area>
          <Content.Footer className={S.footer}>
            <Flex variant="buttons" className={S.footerRow}>
              <Button onClick={() => this.onSubmit()}>{ !firmware ? 'Upload Firmware' : 'Save Changes' }</Button>
              <Button onClick={() => onClose()}>Close</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </React.Fragment>
    )
  }
}

export default connect( state => ( {
  author : state.auth.profile && state.auth.profile.email
} ) )( FirmwareEditor )
