import React, { Component } from 'react';
import classNames from 'classnames'
import { Flex } from '@leverege/ui-elements'
import Path from '@leverege/path'
import S from './FileUploader.less'

const PLACEHOLDER = 'Choose a file to upload';


class FileUploader extends Component {

  state = {
    fileName : PLACEHOLDER
  }

  constructor( props ) {
    super( props )

    this.ref = React.createRef()
  }

  onChange() {
    const { onChange = () => {} } = this.props
    const file = Path.get( 'current/files', this.ref )[0]

    this.setState( { fileName : file ? file.name : PLACEHOLDER } )

    onChange( file );
  }

  render() {
    const { fileName } = this.state
    const { className } = this.props

    return (
      <Flex
        variant="rowLA"
        className={classNames( S.inputContainer, className )}
        onChange={event => this.onChange( event )}
        onClick={() => this.ref.current.click()}>
        <input type="file" ref={this.ref} />
        <img src="/images/clip.png" alt="clip" className={S.clipImage} />
        <div className={S.fileNameContainer}>{fileName}</div>
      </Flex>
    );
  }
}


export default FileUploader
