import React from 'react'
import { connect } from 'react-redux'
import { Form, Flex, Text, MultiSelect, Label } from '@leverege/ui-elements'
import { Track } from '../../../accessors'
import S from './FirmwareListEditor.less'

const TypeItem = ( { item } ) => (
  <span className={S.item}>
    {item.name}
  </span>
)

class DeviceListEditor extends React.Component {
  static contextType = Form.Context

  onFirmwareChanged = ( { data, value, action } ) => {
    if ( action === 'insert' ) {
      this.onAdd( { data, value } )
    } else {
      this.onRemove( { data, value } )
    }
  }

  onAdd = ( { value } ) => {
    const { validator } = this.context    
    const { name } = this.props

    const val = validator.value( name ) || []
    validator.onInputChange( {
      data : name,
      value : [
        ...val,
        value 
      ]
    } )
  }

  onRemove = ( { value } ) => {
    const { name } = this.props
    const { validator } = this.context
    const val = validator.value( name )

    const remainingDevices = val.filter( item => item.id !== value.id )

    validator.onInputChange( {
      value : remainingDevices,
      data : name
    } )
  }

  render() {
    const { name, firmwares } = this.props
    const { validator } = this.context
    const val = validator.value( name ) || []
    const binaries = Track.binaries( { firmwares : val } )
    
    return (
      <Flex variant="colM">
        <Flex align="center" variant="rowM">
          <Label variant="fixedWidth" htmlFor="name">Firmware</Label>          
        </Flex>
        <Flex variant="colM">               
          <MultiSelect
            variant="portalForm"
            className={S.firmwareSelect}
            data={firmwares || []}
            value={val}
            tagComponent={TypeItem}
            itemComponent={TypeItem}
            valueField="id"
            textField="name"
            eventData="firmwares"
            allowCreate={false}
            onChange={this.onFirmwareChanged} />          
          {Object.keys( binaries )
            .map( ( file ) => {
              return (
                <Flex key={file} align="center" justify="space-between" style={{ marginLeft : 16 }}>
                  <Text>
                    {`${binaries[file].file} - ${binaries[file].name}`}
                  </Text>                  
                </Flex>
              )
            } ) }
        </Flex>     
      </Flex>      
    )
  }
}

export default connect( state => ( {
  model : state.data
} ) )( DeviceListEditor )
