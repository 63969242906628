import React from 'react'
import Thunk from 'redux-thunk'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'

import Api from '@leverege/api'

import { Theme } from '@leverege/ui-elements'
import ActiveTheme from '@leverege/ui-active-theme'

import './css/global-style.less'
import AppTheme from './theme'
import BootApp from './BootApp'
import reducers from './reducers'

import { Select, Constants } from './actions'

import ServerApi from './util/ServerApi'

function start( opts = {} ) {
  const api = Api.init( opts.api || {
    host : process.env.API_HOST,
    projectId : Constants.PROJECT_ID,
    storeToken : true,
  } )

  const portalServerUrl = process.env.PORTAL_API_HOST
  const serverApiUrl = process.env.SIREN_API_HOST
  const serverApi = new ServerApi( { serverUrl : serverApiUrl } )

  const history = createBrowserHistory()
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    reducers( { history } ),
    composeEnhancers(
      applyMiddleware(
        Thunk.withExtraArgument( { projectId : process.env.PROJECT_ID, api, portalServerUrl, serverApi } ),
        routerMiddleware( history ),
        ( { getState } ) => next => ( action ) => {
          const returnValue = next( action );
          if ( action.type === Constants.AUTH_LOGGED_IN ) {
            // const user = action.profile
          } else if ( action.type === Constants.AUTH_LOGGED_OUT ) {
            setTimeout( () => {
              store.dispatch( Select.clearAll() )
            }, 1 )

          }
          return returnValue;
        } )
    )
  )

  let theme = AppTheme
  if ( process.env.NODE_ENV === 'development' ) {
    theme = new ActiveTheme( { altTheme : AppTheme, appearanceProjectId : '5C1pPCOS1i5uvzViWP2BA9' } )
  }

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Theme theme={theme}>
          <BootApp />
        </Theme>
      </ConnectedRouter>
    </Provider>,
    document.getElementById( 'root' ) )
}

window.Application = { start }
