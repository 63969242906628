import React, { useState } from 'react';
import { Content, Dialog, Flex, Button, Text, Checkbox } from '@leverege/ui-elements'
import { capitalize } from 'lodash'

import Device from '../../../../accessors/Device'
import Sensor from '../../../../accessors/Sensor'
import { NOT_AVAILABLE } from '../../../../actions/Constants'
import S from './ConfirmationDialog.less'

const RetryFirmwareDialog = ( props ) => {
  const {
    show,
    device,
    sensors,
    onConfirm = ( payload ) => {},
    onDeny = () => {}
  } = props

  const [ selectedFirmware, setSelectedFirmware ] = useState( null )
  const [ selectedSensorIds, setSelectedSensorIds ] = useState( {} )
  let firmwareWirelessSensor = Device.sensorFirmware( device )
  if ( firmwareWirelessSensor !== NOT_AVAILABLE ) firmwareWirelessSensor = `Version ${firmwareWirelessSensor}`

  let firmwareWirelessHost = Device.hostFirmware( device )
  if ( firmwareWirelessHost !== NOT_AVAILABLE ) firmwareWirelessHost = `Version ${firmwareWirelessHost}`

  let firmwareWirelessRepeater = Device.repeaterFirmware( device )
  if ( firmwareWirelessRepeater !== NOT_AVAILABLE ) firmwareWirelessRepeater = `Version ${firmwareWirelessRepeater}`

  const firmwareVersion = {
    sensor : firmwareWirelessSensor,
    host : firmwareWirelessHost,
    repeater : firmwareWirelessRepeater,
  }

  const toggleSelectedFirmware = ( firmwareType ) => {
    if ( selectedFirmware === firmwareType ) {
      setSelectedFirmware( null )
    } else {
      setSelectedFirmware( firmwareType )
      if ( firmwareType === 'sensor' ) {
        const ids = ( sensors || [] ) && sensors.map( sensor => Sensor.esn( sensor ) )
        const sensorIds = {}
        ids.forEach( ( id ) => {
          sensorIds[id] = true
        } )
        setSelectedSensorIds( sensorIds )
      }
    }
  }

  const toggleSelectedSensor = ( sensorId ) => {
    if ( selectedSensorIds[sensorId] ) {
      delete selectedSensorIds[sensorId]
      setSelectedSensorIds( {
        ...selectedSensorIds
      } )
    } else {
      setSelectedSensorIds( {
        ...selectedSensorIds,
        [sensorId] : true
      } )
    }
  }

  const onConfirmRetry = () => {
    let payload
    if ( selectedFirmware === 'host' ) {
      payload = {
        hostId : '1'.repeat( 16 )
      }
    } else if ( selectedFirmware === 'repeater' ) {
      payload = {
        repeaterId : '1'.repeat( 16 )
      }
    } else if ( selectedFirmware === 'sensor' ) {
      payload = {
        sensorIds : Object.keys( selectedSensorIds )
      }
    }
    if ( payload ) {
      onConfirm( payload )
    }
  }

  let retryEnabled = false
  if ( selectedFirmware === 'sensor' ) {
    retryEnabled = Object.keys( selectedSensorIds ).length !== 0
  } else {
    retryEnabled = !!selectedFirmware
  }
  return (
    <Dialog show={show} className={S.dialog}>
      <Content relative className={S.content}>
        <Content.Header className={S.header}>
          <Text>Retry Firmware Download</Text>
          <Text className={S.firmwareSubtitle}>Select the Firmwares that you want to retry. The unit will automatically retry the latest firmware version.</Text>
        </Content.Header>
        <Content.Area className={S.contentArea}>
          {[ 'host', 'repeater', 'sensor' ].map( ( firmwareType ) => {
            return (
              <Flex key={firmwareType} direction="row" justify="space-between" className={S.firmwareTypeItem}>
                <Flex direction="row" align="center">
                  <Checkbox
                    variant="row"
                    value={selectedFirmware === firmwareType}
                    onChange={() => toggleSelectedFirmware( firmwareType )} />
                  <Text className={S.firmwareTitle}>
                    {`Wireless ${capitalize( firmwareType )}`}
                  </Text>
                </Flex>
                <Text className={S.firmwareSubtitle}>{firmwareVersion[firmwareType]}</Text>
              </Flex>
            )
          } )}
          {sensors && sensors.length > 0 && selectedFirmware === 'sensor' && (
            <Content.Area className={S.sensorList}>
              {sensors.map( ( sensor ) => {
                const esn = Sensor.esn( sensor )
                return (
                  <Flex key={sensor.id} direction="row" justify="space-between" className={S.firmwareTypeItem}>
                    <Flex direction="row" align="center">
                      <Checkbox
                        variant="row"
                        value={selectedSensorIds[esn]}
                        onChange={() => toggleSelectedSensor( esn )} />
                      <Text className={S.firmwareTitle}>
                        {Sensor.name( sensor )}
                      </Text>
                      <Text className={S.firmwareSubtitle}>{esn}</Text>
                    </Flex>
                    <Text className={S.firmwareSubtitle}>{Sensor.firmwareVersion( sensor, { device } )}</Text>
                  </Flex>
                )
              } )}
            </Content.Area>
          )}
          {sensors && sensors.length === 0 && selectedFirmware === 'sensor' && (
            <Text className={S.firmwareTitle}>
              No sensors associated
            </Text>
          )}
        </Content.Area>
        <Content.Footer className={S.header}>
          <Flex variant="buttons">
            <Button variant="hollow" disabled={!retryEnabled} onClick={onConfirmRetry}>Retry</Button>
            <Button variant="delete" onClick={onDeny}>Cancel</Button>
          </Flex>
        </Content.Footer>
      </Content>
    </Dialog>
  )
}


export default RetryFirmwareDialog
