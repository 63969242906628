import React, { useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Dialog, Text } from '@leverege/ui-elements'

import EmailPreActivate from './EmailPreActivate' 
import EmailActivate from './EmailActivate'
import OneTimeCodes from './OneTimeCodes'

import S from './TwoFactor.css'

const initialEnrollData = {
  email : '',
  password : '',
  code : '',
}

function Enroll( props ) {
  const { auth } = props
  const [ enrollData, setEnrollData ] = useState( initialEnrollData )

  const onChange = ( { data, value } ) => {
    setEnrollData( {
      ...enrollData,
      [data] : value
    } )
  }

  return (
    <Dialog show>
      <header className={classnames( S.header )}>
        <img 
          src="/images/lock-icon.png"
          alt="Lock icon"
          className={classnames( S.lockIcon )} />
        <Text variant="dialogTitle">Two-Factor Authentication Enrollment</Text>
      </header>
      {auth?.profile?.twoFactorMethodsConfigured != null ? (
        <EmailPreActivate 
          enrollData={enrollData}
          onChange={onChange} />
      ) : null}
      {!auth?.oneTimeCodes && auth?.profile?.twoFactor?.methods?.email?.active === false ? (
        <EmailActivate 
          enrollData={enrollData} 
          onChange={onChange} />
      ) : null}
      {auth?.oneTimeCodes ? (
        <OneTimeCodes />
      ) : null}
    </Dialog>
  )
}

export default connect(
  state => ( {
    auth : state.auth,
  } ),
  dispatch => ( {
  } )
)( Enroll )
