module.exports = {
  "hierarchy": {
    "small": [
      "button-small"
    ],
    "hollow": [
      "button-hollow"
    ],
    "smallHollow": [
      "button-hollow",
      "button-smallHollow"
    ],
    "link": [
      "button-hollow",
      "button-link"
    ],
    "smallLink": [
      "button-hollow",
      "button-link",
      "button-smallLink"
    ],
    "remove": [
      "button-hollow",
      "button-link",
      "button-remove"
    ],
    "add": [
      "button-hollow",
      "button-add"
    ],
    "closeToast": [
      "button-hollow",
      "button-link",
      "button-closeToast"
    ],
    "delete": [
      "button-hollow",
      "button-delete"
    ],
    "search": [
      "button-search"
    ],
    "pageBack": [
      "button-pageBack"
    ],
    "pageNext": [
      "button-pageBack",
      "button-pageNext"
    ],
    "logoutButton": [
      "button-logoutButton"
    ],
    "cancelButton": [
      "button-cancelButton"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "hollow": {},
    "smallHollow": {},
    "link": {},
    "smallLink": {},
    "remove": {
      "icon": "fa fa-trash"
    },
    "add": {
      "icon": "fa fa-plus"
    },
    "closeToast": {},
    "delete": {},
    "search": {},
    "pageBack": {
      "icon": "fa fa-chevron-left"
    },
    "pageNext": {
      "icon": "fa fa-chevron-right"
    },
    "logoutButton": {},
    "cancelButton": {}
  }
}