import Theme from './Theme.css'
import Body from './Body.css'
import BodySupport from './BodySupport'
import Breadcrumbs from './Breadcrumbs.css'
import BreadcrumbsSupport from './BreadcrumbsSupport'
import Button from './Button.css'
import ButtonSupport from './ButtonSupport'
import Checkbox from './Checkbox.css'
import CheckboxSupport from './CheckboxSupport'
import ComboboxSupport from './ComboboxSupport'
import CompositeTextInput from './CompositeTextInput.css'
import CompositeTextInputSupport from './CompositeTextInputSupport'
import DataBlock from './DataBlock.css'
import DataBlockSupport from './DataBlockSupport'
import DateTimePicker from './DateTimePicker.css'
import DateTimePickerSupport from './DateTimePickerSupport'
import DateTimePickerV2 from './DateTimePickerV2.css'
import DateTimePickerV2Support from './DateTimePickerV2Support'
import DateTimeRangePickerSupport from './DateTimeRangePickerSupport'
import Dropdown from './Dropdown.css'
import DropdownSupport from './DropdownSupport'
import Flex from './Flex.css'
import FlexSupport from './FlexSupport'
import Label from './Label.css'
import LabelSupport from './LabelSupport'
import List from './List.css'
import ListSupport from './ListSupport'
import MultiSelect from './MultiSelect.css'
import MultiSelectSupport from './MultiSelectSupport'
import MultiSelector from './MultiSelector.css'
import MultiSelectorSupport from './MultiSelectorSupport'
import Pane from './Pane.css'
import PaneSupport from './PaneSupport'
import Popup from './Popup.css'
import PopupSupport from './PopupSupport'
import Portal from './Portal.css'
import PortalSupport from './PortalSupport'
import ProgressBar from './ProgressBar.css'
import ProgressBarSupport from './ProgressBarSupport'
import PropertyGrid from './PropertyGrid.css'
import PropertyGridSupport from './PropertyGridSupport'
import RadioButton from './RadioButton.css'
import RadioButtonSupport from './RadioButtonSupport'
import Slider from './Slider.css'
import SliderSupport from './SliderSupport'
import SplitPane from './SplitPane.css'
import SplitPaneSupport from './SplitPaneSupport'
import TabPane from './TabPane.css'
import TabPaneSupport from './TabPaneSupport'
import Table from './Table.css'
import TableSupport from './TableSupport'
import TableV2 from './TableV2.css'
import TableV2Support from './TableV2Support'
import Text from './Text.css'
import TextSupport from './TextSupport'
import TextArea from './TextArea.css'
import TextAreaSupport from './TextAreaSupport'
import TextInput from './TextInput.css'
import TextInputSupport from './TextInputSupport'
import Toast from './Toast.css'
import ToastSupport from './ToastSupport'
import Toggle from './Toggle.css'
import ToggleSupport from './ToggleSupport'
import ToggleButton from './ToggleButton.css'
import ToggleButtonSupport from './ToggleButtonSupport'
import VTable from './VTable.css'
import VTableSupport from './VTableSupport'


function addScript( src ) {
  const script = document.createElement( "script" )
  script.crossOrigin = "anonymous"
  script.src = src
  document.head.appendChild( script )
}



const styles = {
  Body,
  Breadcrumbs,
  Button,
  Checkbox,
  CompositeTextInput,
  DataBlock,
  DateTimePicker,
  DateTimePickerV2,
  Dropdown,
  Flex,
  Label,
  List,
  MultiSelect,
  MultiSelector,
  Pane,
  Popup,
  Portal,
  ProgressBar,
  PropertyGrid,
  RadioButton,
  Slider,
  SplitPane,
  TabPane,
  Table,
  TableV2,
  Text,
  TextArea,
  TextInput,
  Toast,
  Toggle,
  ToggleButton,
  VTable
}
const supports = {
  Body : BodySupport,
  Breadcrumbs : BreadcrumbsSupport,
  Button : ButtonSupport,
  Checkbox : CheckboxSupport,
  Combobox : ComboboxSupport,
  CompositeTextInput : CompositeTextInputSupport,
  DataBlock : DataBlockSupport,
  DateTimePicker : DateTimePickerSupport,
  DateTimePickerV2 : DateTimePickerV2Support,
  DateTimeRangePicker : DateTimeRangePickerSupport,
  Dropdown : DropdownSupport,
  Flex : FlexSupport,
  Label : LabelSupport,
  List : ListSupport,
  MultiSelect : MultiSelectSupport,
  MultiSelector : MultiSelectorSupport,
  Pane : PaneSupport,
  Popup : PopupSupport,
  Portal : PortalSupport,
  ProgressBar : ProgressBarSupport,
  PropertyGrid : PropertyGridSupport,
  RadioButton : RadioButtonSupport,
  Slider : SliderSupport,
  SplitPane : SplitPaneSupport,
  TabPane : TabPaneSupport,
  Table : TableSupport,
  TableV2 : TableV2Support,
  Text : TextSupport,
  TextArea : TextAreaSupport,
  TextInput : TextInputSupport,
  Toast : ToastSupport,
  Toggle : ToggleSupport,
  ToggleButton : ToggleButtonSupport,
  VTable : VTableSupport
}
const color = {
  "action1": "#082a55",
  "action2": "#E62700",
  "action3": "#62A420",
  "alertDangerA": "#e62700",
  "alertDangerB": "#ffcccc",
  "alertInfoA": "#79d274",
  "alertInfoB": "#deffdc",
  "alertSuccessA": "#74c2d2",
  "alertSuccessB": "#b9e4e4",
  "alertWarnA": "#fcda4b",
  "alertWarnB": "#fcf8e3",
  "background1": "#f8fafc",
  "background2": "#f9f9f9",
  "background3": "#EEEEEE",
  "black": "#000000",
  "border1": "#b2b2b2",
  "border2": "#d2d2d2",
  "border3": "#e2e2e2",
  "brand1": "#082a55",
  "brand2": "#f8fafc",
  "brand3": "#0d3568",
  "brand4": "#3A65C7",
  "disabled": "#B2BAC4",
  "label1": "#313131",
  "label2": "#7F7F7F",
  "label3": "#a4a4a4",
  "label4": "#FFFFFF",
  "rollover": "#deffdc",
  "selection": "#b9e4e4",
  "shadow1": "rgba( 0, 0, 0, 0.25 )",
  "shadow2": "rgba( 0, 0, 0, 0.15 )",
  "shadow3": "rgba( 0, 0, 0, 0.075 )",
  "white": "#FFFFFF"
}
const icon = {}
const size = {
  "borderRadiusLarge": "8px",
  "borderRadiusMedium": "4px",
  "borderRadiusSmall": "2px",
  "fontLarge": "16px",
  "fontLarger": "24px",
  "fontMedium": "14px",
  "fontSmall": "11px",
  "large": "16px",
  "medium": "8px",
  "negative": "-4px",
  "padLarge": "16px",
  "padMedium": "8px",
  "padSmall": "4px",
  "small": "4px"
}
const resources = { color, icon, size }

module.exports = { styles, supports, resources }