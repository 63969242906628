import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Button, Text, TextInput } from '@leverege/ui-elements'
import { preActivateTwoFactor } from '../../../actions/UserActions'

import S from './TwoFactor.css'

function EmailPreActivate( props ) {
  const { auth, enrollData, onChange, preActivateTwoFactor } = props

  const onSubmit = async () => {
    preActivateTwoFactor( auth.idToken, auth.profile.id, enrollData.email, enrollData.password )
  }

  const interceptOnChange = ( { data, value, isReturn } ) => {
    onChange( { data, value } )
    if ( isReturn ) {
      preActivateTwoFactor( 
        auth.idToken, 
        auth.profile.id, 
        data === 'email' ? value : enrollData.email, 
        data === 'password' ? value : enrollData.password 
      )
    } 
  }

  return (
    <React.Fragment>
      <Text 
        variant="contentTitle"
        hint="Enter Email"
        className={classnames( S.contentTitle )}>
        Enter Your Email
      </Text>
      <Text 
        variant="default"
        className={classnames( S.contentText )}>
        We’ll send a security code to this email whenever you sign in.
      </Text>
      <TextInput
        hint="Enter Email"
        value={enrollData.email}
        onChange={interceptOnChange}
        eventData="email"
        variant="formInput"
        alwaysFireOnReturn
        className={classnames( S.textInput )} />
      <Text  
        variant="default"
        className={classnames( S.contentText )}>
        Please confirm your password
      </Text>
      <TextInput
        hint="Enter Password"
        value={enrollData.password}
        onChange={interceptOnChange}
        type="password"
        eventData="password"
        variant="formInput"
        alwaysFireOnReturn
        className={classnames( S.textInput )} />
      {auth.error ? (
        <Text 
          variant="error"
          className={classnames( S.errorText )}>
          {auth.message}
        </Text>
      ) : null}
      <Button 
        onClick={onSubmit}
        type="submit"
        className={classnames( S.button )}>
        Next
      </Button>
    </React.Fragment>
  )
}

export default connect(
  state => ( {
    auth : state.auth,
  } ),
  dispatch => ( {
    preActivateTwoFactor : ( token, userId, email, password ) => dispatch( preActivateTwoFactor( token, userId, email, password ) )
  } )
)( EmailPreActivate )
