// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast_d4U9r {
  color: var(--color-label1);
  border: 1px solid var(--color-border1);
  padding: var(--size-padMedium) var(--size-padLarge);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: var(--color-background1);
}
.toast_d4U9r > .Toastify__progress-bar {
  background: none;
}
.toast_d4U9r.toast-success_SLv1U {
  color: #0e815a;
  background-color: #edf9f0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.toast_d4U9r.toast-error_pWcnE {
  background-color: var(--color-alertDangerB);
}
.toast_d4U9r.toast-warn_uWcaX {
  background-color: var(--color-alertWarnA);
}
.toast_d4U9r.toast-info_rpx0E {
  background-color: var(--color-alertSuccessA);
}
`, "",{"version":3,"sources":["webpack://./src/theme/Toast.css"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,sCAAA;EACA,mDAAA;EACA,wCAAA;EACA,0CAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EACE,cAAA;EACA,yBAAA;EACA,6BAAA;EACA,8BAAA;EACA,+BAAA;EACA,gCAAA;AACF;AACA;EACE,2CAAA;AACF;AACA;EACE,yCAAA;AACF;AACA;EACE,4CAAA;AACF","sourcesContent":[".toast {\n  color: var( --color-label1 );\n  border: 1px solid var( --color-border1 );\n  padding: var( --size-padMedium ) var( --size-padLarge );\n  box-shadow: 0 4px 8px rgba( 0, 0, 0, 0.2 );\n  background-color: var( --color-background1 );\n}\n.toast > :global( .Toastify__progress-bar ) {\n  background: none;\n}\n.toast.toast-success {\n  color: #0e815a;\n  background-color: #edf9f0;\n  border-top-color: transparent;\n  border-left-color: transparent;\n  border-right-color: transparent;\n  border-bottom-color: transparent;\n}\n.toast.toast-error {\n  background-color: var( --color-alertDangerB );\n}\n.toast.toast-warn {\n  background-color: var( --color-alertWarnA );\n}\n.toast.toast-info {\n  background-color: var( --color-alertSuccessA );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `toast_d4U9r`,
	"toast-success": `toast-success_SLv1U`,
	"toast-error": `toast-error_pWcnE`,
	"toast-warn": `toast-warn_uWcaX`,
	"toast-info": `toast-info_rpx0E`
};
export default ___CSS_LOADER_EXPORT___;
