// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentArea_EIO_w {
  height: 100%;
}
.contentArea_EIO_w .table_HMrT4 {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/devices/Device/DeviceGroups.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAFA;EAII,YAAA;AACJ","sourcesContent":[".contentArea {\n  height: 100%;\n\n  .table {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentArea": `contentArea_EIO_w`,
	"table": `table_HMrT4`
};
export default ___CSS_LOADER_EXPORT___;
