import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Flex, Content, PortalOverlay, Button } from '@leverege/ui-elements'
import { Validator } from '@leverege/validator'
import { Customer } from '../../../accessors'
import { CustomerActions } from '../../../actions'
import CustomerEditor from './CustomerEditor'
import CustomerDetails from './CustomerDetails'
import Search from '../lib/input/Search'
import DefaultTable from '../lib/table/DefaultTable'

import S from './CustomerScreen.less'


class CustomerScreen extends React.Component {

  static get OVERLAY_PORTAL_MODE() {
    return {
      HIDE : 'hide',
      VIEW : 'view',
      EDIT : 'edit'
    }
  }

  constructor( props ) {
    super( props )

    this.state = {
      query : '',
      lastUsedQuery : '',
      page : 0,
      perPage : 15,
      overlayPortalMode : CustomerScreen.OVERLAY_PORTAL_MODE.HIDE,
      clickedCustomerId : null,
      // selectedCustomers : []
    }
  }

  componentDidMount() {
    this.loadCustomers()
  }

  loadCustomers() {
    const { dispatch, pages } = this.props
    const { page, perPage } = this.state

    return dispatch( CustomerActions.getCustomers( pages[page], perPage, true ) )
  }

  onCustomerChanged = async ( id ) => {
    const { dispatch } = this.props

    if ( id ) {
      dispatch( CustomerActions.clear() )
      await this.loadCustomers()
      dispatch( push( `/customers/${id}` ) )
    }
  }

  onClose = ( evt ) => {
    const { dispatch } = this.props

    this.setState( {
      overlayPortalMode : CustomerScreen.OVERLAY_PORTAL_MODE.HIDE,
      clickedCustomerId : null
    } )

    dispatch( CustomerActions.clear() )

    this.loadCustomers()
  }

  onAddCustomer = () => {
    const { dispatch } = this.props
    dispatch( push( '/customers/add' ) )
  }

  search = () => {
    const { dispatch } = this.props
    const { query } = this.state

    if ( query && Validator.isEmail( query ) ) {
      dispatch( CustomerActions.clear() )
      dispatch( CustomerActions.getCustomerByEmail( query, true ) )
      this.setState( { lastUsedQuery : query } )
    }

    if ( query === '' ) {
      dispatch( CustomerActions.clear() )
      this.setState( { lastUsedQuery : query } )
    }
  }

  searchQueryChange = ( { value } ) => {
    this.setState( { query : value } )
  }

  onRowClick = ( row ) => {
    this.setState( {
      overlayPortalMode : CustomerScreen.OVERLAY_PORTAL_MODE.VIEW,
      clickedCustomerId : row.id
    } )
  }

  setPage = ( page ) => {
    const { dispatch, pages } = this.props
    const { perPage } = this.state
    this.setState( { page } )
    dispatch( CustomerActions.getCustomers( pages[page], perPage, true ) )
  }

  setPageSize = ( perPage ) => {
    const { dispatch } = this.props
    this.setState( { page : 0, perPage } )
    dispatch( CustomerActions.clear() )
    dispatch( CustomerActions.getCustomers( undefined, perPage, true ) )
  }

  onCustomerSelectionChange( customers ) {
    this.setState( {
      selectedCustomers : customers
    } )
  }

  onClosePortalOverlay = () => {
    this.setState( {
      overlayPortalMode : CustomerScreen.OVERLAY_PORTAL_MODE.HIDE,
      clickedCustomerId : null
    } )
  }

  setPortalOverlayMode = ( overlayPortalMode ) => {
    this.setState( { overlayPortalMode } )
  }

  render() {
    const { idsPerPage, pages, loading, lookup } = this.props
    const { page, query, lastUsedQuery, overlayPortalMode, clickedCustomerId/* , selectedCustomers*/, searchWasPerformed } = this.state
    const pageId = pages[page]
    const ids = idsPerPage[pageId] || []
    const items = ids.map( id => lookup[id] )
    // const actionButtonsConfig = [
    //   { key : 1, icon : '/images/svg/gray/trash.svg', onClick : () => {} },
    //   { key : 2, icon : '/images/svg/gray/user-move.svg', onClick : () => {} }
    // ]

    const columns = [
      { Header : 'Email', id : 'email', accessor : Customer.email, className : S.itemName },
      { Header : 'Created At', id : 'created', accessor : Customer.createdAt, className : S.itemName },
      { Header : 'Last Sign In', id : 'signin', accessor : Customer.lastSignIn, className : S.itemName }
    ]
    
    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <Flex direction="column" className={S.panel}>
            <Flex align="center" variant="rowL" className={S.searchControlsRow}>
              <Search
                placeholder="Search by Email"
                disabled={lastUsedQuery === query}
                value={query}
                onChange={this.searchQueryChange}
                onSearch={this.search} />
            </Flex>

            <DefaultTable
              className={S.table}
              data={items}
              defaultPageSize={15}
              loading={loading && items.length === 0}
              columns={columns}
              defaultPageSize={15}
              pages={pages.length}
              onPageChange={page => this.setPage( page )}
              onPageSizeChange={perPage => this.setPageSize( perPage )}
              onRowClick={row => this.onRowClick( row )} />

            <PortalOverlay
              show={overlayPortalMode !== CustomerScreen.OVERLAY_PORTAL_MODE.HIDE}
              targetId="mainScreen"
              animation="slideFromRight"
              position="right">
              <div>
                {
                  overlayPortalMode === CustomerScreen.OVERLAY_PORTAL_MODE.VIEW && (
                    <CustomerDetails
                      customerId={clickedCustomerId}
                      onEditCustomer={() => this.setPortalOverlayMode( CustomerScreen.OVERLAY_PORTAL_MODE.EDIT )}
                      onClose={this.onClosePortalOverlay} />
                  )
                }
                {
                  overlayPortalMode === CustomerScreen.OVERLAY_PORTAL_MODE.EDIT && (
                    <CustomerEditor
                      customerId={clickedCustomerId}
                      afterSaveChanges={() => this.setPortalOverlayMode( CustomerScreen.OVERLAY_PORTAL_MODE.VIEW )}
                      onClose={this.onClosePortalOverlay} />
                  )
                }
              </div>
            </PortalOverlay>

          </Flex>
        </Content.Area>
      </Content>
    )
  }

}

export default connect( ( state, props ) => ( {
  idsPerPage : state.customer.idsPerPage,
  lookup : state.customer.lookup,
  loading : state.customer.loading,
  pages : state.customer.pages,
  customerId : props.match.params.customerId,
  ui : state.ui
} ) )( CustomerScreen )
