import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content, TabPane } from '@leverege/ui-elements'
import S from './Admin.less'
import Account from './Admin/Account'
import Users from './Admin/Users'
import Settings from './Admin/Settings'


class Admin extends Component {

  render() {
    const tabsConfig = [
      // { key : 1, tabId : 'me', title : 'Me', disabled : true },
      // { key : 2, tabId : 'account', title : 'My Account', disabled : false, getComponent : () => <Account /> },
      // { key : 3, tabId : 'notif', title : 'My Notifications', disabled : false, getComponent : () => {} },
      // { key : 4, tabId : 'company', title : 'Company', disabled : true },
      { key : 5, tabId : 'users', title : 'Users', disabled : false, getComponent : () => <Users /> },
      { key : 6, tabId : 'settings', title : 'Menu Settings', disabled : false, getComponent : () => <Settings /> }
    ]

    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <TabPane
            className={S.tabPane}
            activeTab="account"
            variant="left">
            {
              tabsConfig.map( ( { key, tabId, title, disabled, getComponent } ) => (
                <TabPane.Tab
                  disabled={disabled}
                  key={key}
                  tabId={tabId}
                  tabName={title}>
                  { !disabled && getComponent() }
                </TabPane.Tab>
              ) )
            }
          </TabPane>
        </Content.Area>
      </Content>
    )
  }
}

export default connect( () => ( {} ) )( Admin )
