module.exports = {
  "hierarchy": {
    "small": [
      "textInput-small"
    ],
    "esn": [
      "textInput-esn"
    ],
    "portalForm": [
      "textInput-portalForm"
    ],
    "search": [
      "textInput-search"
    ],
    "paginator": [
      "textInput-paginator"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "esn": {},
    "portalForm": {},
    "search": {
      "icon": "fa fa-search"
    },
    "paginator": {}
  }
}