import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content, Flex, Button, TextInput, Toggle } from '@leverege/ui-elements'
import S from './Account.less'


class Account extends Component {

  state = {
    email : '',
    phone : '',
    emailAlerts : false,
    textAlerts : false
  }

  onFieldValueChange = ( { data, value } ) => {
    this.setState( { [data] : value } )
  }

  render() {
    const { email, phone, emailAlerts, textAlerts } = this.state

    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <Flex direction="column" className={S.mainColumn}>

            <Flex direction="row" className={S.accountItemTitle}>
              Contact Information
            </Flex>
            <Flex direction="row" className={S.accountItemInfo}>
              By enabling alerts, you agree to receive text messages and/or emails about the status of shipments.
            </Flex>
            <Flex direction="column" className={S.textInputLabel}>
              <Flex direction="row">
                Contact Email
              </Flex>
              <Flex direction="row" className={S.textInput}>
                <TextInput hint="Enter Email" value={email} eventData="email" onChange={this.onFieldValueChange} />
              </Flex>
            </Flex>
            <Flex direction="column">
              <Flex direction="row" className={S.textInputLabel}>
                Contact Phone Number
              </Flex>
              <Flex direction="row" className={S.textInput}>
                <TextInput hint="Enter Phone Number" value={phone} eventData="phone" onChange={this.onFieldValueChange} />
              </Flex>
            </Flex>
            <Flex direction="row-reverse">
              <Button className={S.saveButton}>Save Changes</Button>
            </Flex>

            <hr className={S.hr} />

            <Flex direction="row" className={S.accountItemTitle}>
              Notification Settings
            </Flex>
            <Flex direction="row" justify="space-between">
              <Flex direction="column">
                <div className={S.accountConfigTitle}>Enable Email Alerts</div>
                <div className={S.accountConfigInfo}>By enabling email alerts, you will receive emails to the above email address for any alerts</div>
              </Flex>
              <Flex direction="row-reverse">
                <Toggle variant="small" className={S.toggleButton} value={emailAlerts} eventData="emailAlerts" onChange={this.onFieldValueChange} />
              </Flex>
            </Flex>
            <Flex direction="row" justify="space-between">
              <Flex direction="column">
                <div className={S.accountConfigTitle}>Enable Text Alerts</div>
                <div className={S.accountConfigInfo}>By enabling text alerts, you acknowledge that you will receive text messages to the listed number. Data rates may apply</div>
              </Flex>
              <Flex direction="row-reverse">
                <Toggle variant="small" className={S.toggleButton} value={textAlerts} eventData="textAlerts" onChange={this.onFieldValueChange} />
              </Flex>
            </Flex>
            <Flex direction="row-reverse">
              <Button className={S.saveButton}>Apply Changes</Button>
            </Flex>
          </Flex>
        </Content.Area>
      </Content>
    )
  }
}

export default connect( () => ( {} ) )( Account )
