// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_WuqWh {
  color: var(--color-label1);
  border: 1px solid var(--color-background3);
  font-size: var(--size-fontMedium);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: var(--color-white);
}
.popup_WuqWh > .popupItem_itOrI {
  width: 100%;
  padding: var(--size-padSmall) var(--size-fontLarge) var(--size-padSmall) var(--size-padLarge);
  display: inline-grid;
  text-align: left;
}
.popup_WuqWh > .popupItem_itOrI:hover {
  background-color: var(--color-background3);
}
.popup_WuqWh > .popupItem_itOrI:active:focus {
  color: var(--color-white);
  background-color: var(--color-brand1);
}
.popup_WuqWh > .popupDivider_j6zVe {
  height: 1px;
  margin-top: var(--size-padSmall);
  margin-bottom: var(--size-padSmall);
  background-color: var(--color-background3);
}
.popup_WuqWh.popup-small_Z3sYW {
  font-size: var(--size-fontSmall);
}
`, "",{"version":3,"sources":["webpack://./src/theme/Popup.css"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,0CAAA;EACA,iCAAA;EACA,wCAAA;EACA,oCAAA;AACF;AACA;EACE,WAAA;EACA,6FAAA;EACA,oBAAA;EACA,gBAAA;AACF;AACA;EACE,0CAAA;AACF;AACA;EACE,yBAAA;EACA,qCAAA;AACF;AACA;EACE,WAAA;EACA,gCAAA;EACA,mCAAA;EACA,0CAAA;AACF;AACA;EACE,gCAAA;AACF","sourcesContent":[".popup {\n  color: var( --color-label1 );\n  border: 1px solid var( --color-background3 );\n  font-size: var( --size-fontMedium );\n  box-shadow: 0 2px 4px rgba( 0, 0, 0, 0.2 );\n  background-color: var( --color-white );\n}\n.popup > .popupItem {\n  width: 100%;\n  padding: var( --size-padSmall ) var( --size-fontLarge ) var( --size-padSmall ) var( --size-padLarge );\n  display: inline-grid;\n  text-align: left;\n}\n.popup > .popupItem:hover {\n  background-color: var( --color-background3 );\n}\n.popup > .popupItem:active:focus {\n  color: var( --color-white );\n  background-color: var( --color-brand1 );\n}\n.popup > .popupDivider {\n  height: 1px;\n  margin-top: var( --size-padSmall );\n  margin-bottom: var( --size-padSmall );\n  background-color: var( --color-background3 );\n}\n.popup.popup-small {\n  font-size: var( --size-fontSmall );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `popup_WuqWh`,
	"popupItem": `popupItem_itOrI`,
	"popupDivider": `popupDivider_j6zVe`,
	"popup-small": `popup-small_Z3sYW`
};
export default ___CSS_LOADER_EXPORT___;
