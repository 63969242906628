const Flavors = require( '../util/Flavors' )
const Constants = require( './Constants' )

function getCustomerByEmail( email, useFbAuth = true ) {
  return async ( dispatch, getState, { serverApi } ) => {
    try {
      // LS-1641 keep, only called with 'useFbAuth' -> true, uses custom logic on siren-marine-api to fetch fb users
      const user = await serverApi.get( `user/${email}?useFbAuth=${useFbAuth}` )

      dispatch( {
        type : Constants.CUSTOMERS_LOADED,
        payload : {
          customers : user.status !== 404 ? [ user ] : [],
        }
      } )
    } catch ( err ) {
      dispatch( { type : Constants.CUSTOMERS_LOADING, payload : { loading : false } } )
      console.error( err )
    }
  }
}

function getCustomer( userId ) {
  return async ( dispatch, getState, { serverApi } ) => {
    try {
      // LS-1641 keep, siren-marine-api does fb db lookup for fleets (no api-server call, no fleet blueprint)
      // reads from fb db paths:
      //   rt/:systemId/users/:userId/fleet
      //   rt/:systemId/dev/:boatId/data
      //   rt/:systemId/fleets/:fleetId
      const devices = await serverApi.get( `user/${userId}/devices` )
      dispatch( {
        type : Constants.CUSTOMERS_DEVICES_LOADED,
        payload : { userId, devices }
      } )
    } catch ( err ) {
      console.error( err )
      dispatch( {
        type : Constants.CUSTOMERS_DEVICES_LOADED,
        payload : { userId, devices : [] }
      } )
    }
  }
}

function getCustomers( page, perPage, useFbAuth = true ) {
  return async ( dispatch, getState, { serverApi } ) => {
    try {
      const { customer } = getState()
      const { idsPerPage } = customer
      if ( idsPerPage[page] && idsPerPage[page].length > 1 ) {
        return
      }
      dispatch( { type : Constants.CUSTOMERS_LOADING, payload : { loading : true } } )

      let url = `user?useFbAuth=${useFbAuth}&perPage=${perPage}`
      if ( page && page !== 'first' ) {
        url += `&page=${page}`
      }
      // LS-1641 keep, only called with 'useFbAuth' -> true, uses custom logic on siren-marine-api to fetch fb users
      const json = await serverApi.get( url ) 
      const { users, currentPage, nextPage } = json

      dispatch( {
        type : Constants.CUSTOMERS_LOADED,
        payload : {
          customers : users,
          currentPage,
          nextPage
        }
      } )
    } catch ( err ) {
      dispatch( { type : Constants.CUSTOMERS_LOADING, payload : { loading : false } } )
      console.error( err )
    }
  }
}

function removeCustomerFromDevice( customerId, deviceId ) {
  return async ( dispatch, getState, { serverApi } ) => {
    try {
      // LS-1641 keep, siren-marine-api does custom fb db fleet stuff (no api-server call, no fleet blueprint)
      // reads from fb db paths:
      //   rt/:systemId/users/:userId/fleet
      // updates fb db paths:
      //   rt/:systemId/fleets/:fleetId/devices
      //   rt/:systemId/fleets/:fleetId/boats/:deviceId
      //   rt/:systemId/fleets/:fleetId/devices
      //   rt/:systemId/dev/:deviceId/fleets/:fleetId
      //   rt/:systemId/dev/:deviceId/geoshapes/contains/:fleetId
      return await serverApi.delete( `user/${customerId}/device/${deviceId}` )
    } catch ( error ) {
      throw error
    }
  }
}

function moveDeviceToCustomer( deviceId, userEmail ) {
  return async ( dispatch, getState, { serverApi } ) => {
    try {
      // LS-1641 keep, have to: (1) validate shopElf subscription and (2) assign fb auth user to fleet
      return await serverApi.post( `device/${deviceId}/move-to-customer/${userEmail}` )
    } catch ( error ) {
      throw error
    }
  }
}

function addCustomerToDevice( deviceId, userEmail ) {
  return async ( dispatch, getState, { serverApi } ) => {
    try {
      // LS-1641 keep, custom siren-marine-api logic with shopelf validation
      return await serverApi.post( `device/${deviceId}/add-customer/${userEmail}` )
    } catch ( error ) {
      throw error
    }
  }
}

function getCustomerById( customerId ) {
  return async ( dispatch, getState, { serverApi } ) => {
    try {
      const userData = await serverApi.get( `user/by-id/${customerId}` )
      dispatch( { type : Constants.SET_CUSTOMER_DATA, payload : userData } )
    } catch ( error ) {
      throw error
    }
  }
}

function clear() {
  return { type : Constants.CUSTOMERS_CLEAR }
}

module.exports = {
  getCustomerByEmail,
  getCustomer,
  getCustomers,
  removeCustomerFromDevice,
  moveDeviceToCustomer,
  addCustomerToDevice,
  clear,
  getCustomerById
}
