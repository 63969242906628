const { Constants } = require( '../actions' )

const INITIAL_STATE = {
  trackList : [],
  trackListLoading : false,
  tracksByDeviceId : [],
  trackByDeviceLoading : false,
  trackDevices : [],
  trackDevicesLoading : false,
  trackFirmwares : [],
  trackFirmwaresLoading : false
}

function track( state = INITIAL_STATE, action ) {
  switch ( action.type ) {
    case Constants.TRACKS_LOADING:
      return { ...state, trackListLoading : true }
    case Constants.TRACKS_LOADED:
      return { ...state, trackListLoading : false, trackList : action.trackList }
    case Constants.TRACKS_BY_DEVICE_LOADING:
      return { ...state, trackByDeviceLoading : true }
    case Constants.TRACKS_BY_DEVICE_LOADED:
      return { ...state, trackByDeviceLoading : false, tracksByDeviceId : action.tracksByDeviceId }
    case Constants.TRACKS_DEVICES_LOADING:
      return { ...state, trackDevicesLoading : true }
    case Constants.TRACKS_DEVICES_LOADED:
      return { ...state, trackDevicesLoading : false, trackDevices : action.trackDevices }
    case Constants.TRACKS_FIRMWARES_LOADING:
      return { ...state, trackFirmwaresLoading : true }
    case Constants.TRACKS_FIRMWARES_LOADED:
      return { ...state, trackFirmwaresLoading : false, trackFirmwares : action.trackFirmwares }
    default:
      return state
  }
}

module.exports = track
