const Constants = require( '../actions/Constants' );

const initialState = {
  user : null,
  users : [],
  loading : false,
  usersRoles : {
    // array contains each role's permissions, default to no permissions
    ShipAdmin : [ ],
    ShipSupport : [ ],
    ShipEngineer : [ ],
  }
}

function user( state = initialState, action ) {

  switch ( action.type ) {

    case Constants.SET_LOGGED_USER:
      return {
        ...state,
        user : action.user
      }

    case Constants.USERS_LOADING:
      return {
        ...state,
        loading : true,
        users : []
      }

    case Constants.USERS_LOADED: {
      const { payload } = action
      return {
        ...state,
        loading : false,
        users : payload
      }
    }

    case Constants.USERS_CLEAR:
      return {
        ...state,
        loading : false,
        users : []
      }
      
    case Constants.SET_USERS_ROLES: {
      const { payload } = action
      return {
        ...state,
        usersRoles : payload
      }
    }

    default:
      return state
  }

}

module.exports = user
