import React from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { Content, Flex } from '@leverege/ui-elements'
import DeviceActions from '../../../actions/DeviceActions'
import DeviceAccessors from '../../../accessors/Device'
import Search from '../lib/input/Search'
import DefaultTable from '../lib/table/DefaultTable'

import S from './Devices.less'

class Devices extends React.Component {

  state = {
    query : '',
    lastUsedQuery : '',
    searchWasPerformed : false
  }

  search = ( value ) => {
    const { query } = this.state
    const { getDevices } = this.props

    console.log( 'search' )
    console.log( { value } )
    if ( value ) {
      getDevices( query )

      this.setState( { lastUsedQuery : query, searchWasPerformed : true } )
    }
  }

  searchQueryChange = ( { value } ) => {
    this.setState( { query : value } )
  }

  onRowClick = ( device ) => {
    const { navigateTo, setSelectedDevice } = this.props

    setSelectedDevice( device )
    navigateTo( `/devices/${device.id}` )
  }

  render() {
    const { query, lastUsedQuery, searchWasPerformed /* , selectedDevices*/ } = this.state
    const { devices, loading } = this.props

    const columns = [
      {
        id : 'esn',
        sortable : false,
        Header : 'ESN',
        accessor : DeviceAccessors.esn,
        className : S.tableESN
      },
      {
        id : 'iccid',
        sortable : false,
        Header : 'ICCID',
        accessor : DeviceAccessors.iccid,        
      },
      {
        id : 'imsi',
        sortable : false,
        Header : 'IMSI',
        accessor : DeviceAccessors.imsi,        
      },
      {
        id : 'name',
        sortable : false,
        Header : 'Name',
        accessor : DeviceAccessors.name,        
      },
      {
        id : 'host',
        accessor : DeviceAccessors.hostFirmware,        
        Header : 'Host',
        resizable : false,
        sortable : false,
      },
      {
        id : 'sensor',
        accessor : DeviceAccessors.sensorFirmware,        
        Header : 'Sensor',
        resizable : false,
        sortable : false
      },
      {
        id : 'main',
        accessor : DeviceAccessors.mainFirmware,        
        Header : 'Main Unit',
        resizable : false,
        sortable : false
      },
      {
        id : 'repeater',
        accessor : DeviceAccessors.repeaterFirmware,        
        Header : 'Repeater',
        resizable : false,
        sortable : false
      },      
      {
        id : 'checkin',
        Header : 'Checked In',
        resizable : false,
        sortable : false,
        accessor : DeviceAccessors.checkIn,        
      }
    ]

    return (
      <Content className={S.contentArea}>
        <Content.Area>
          <Flex variant="colS" className={S.mainColumn}>

            <Flex variant="rowL">
              <Search
                placeholder="Search by ESN"
                disabled={lastUsedQuery === query}
                value={query}
                onChange={this.searchQueryChange}
                onSearch={this.search} />
            </Flex>

            <Flex variant="default" className={S.tableContainer}>
              <DefaultTable
                className={S.table}
                noRowsComponent={searchWasPerformed ? null :
                  (
                    <Flex direction="column" align="center">
                      <Flex direction="row" justify="space-between" align="center">
                        <img src="/images/svg/search.svg" alt="search" />
                      </Flex>
                      <Flex direction="row" justify="space-between" align="center">
                        Please search for a Device in order to load results
                      </Flex>
                    </Flex>
                  )
                }
                data={devices}
                columns={columns}
                loading={loading}
                showPagination={false}
                onRowClick={row => this.onRowClick( row )} />
            </Flex>
          </Flex>
        </Content.Area>
      </Content>
    )
  }
}

export default connect(
  state => ( {
    devices : state.device.devices,
    loading : state.device.loading
  } ),
  dispatch => ( {
    getDevices : query => dispatch( DeviceActions.getDevices( query ) ),
    setSelectedDevice : device => dispatch( DeviceActions.setSelectedDevice( device ) ),
    navigateTo : page => dispatch( push( page ) )
  } )
)( Devices )
