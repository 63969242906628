module.exports = {
  "hierarchy": {
    "small": [
      "portal-small"
    ]
  },
  "props": {
    "default": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "small": {
      "animation": "slideFromTop",
      "position": "top"
    }
  }
}