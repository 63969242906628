// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_WK3G3 {
  height: 100%;
  background: white;
  width: 600px;
}
.content_WK3G3 .header_Tnw5x {
  height: 56px;
  border-bottom: 1px solid #E7EAEF;
  border-top: 1px solid #E7EAEF;
}
.content_WK3G3 .header_Tnw5x .headerRow_K796L {
  height: 56px;
}
.content_WK3G3 .header_Tnw5x .headerRow_K796L .headerTitle_l7ZrF {
  margin: 16px 24px;
  font-size: 18px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #28548D;
}
.content_WK3G3 .header_Tnw5x .headerRow_K796L .crossButton_mTR1J {
  margin: 12px 24px;
  width: 32px;
  height: 32px;
}
.content_WK3G3 .contentArea_Ylqpu {
  background: #F9FBFB;
}
.content_WK3G3 .contentArea_Ylqpu .infoBlock_cXSSE {
  height: 100%;
  padding: 32px 24px;
}
.content_WK3G3 .contentArea_Ylqpu .infoBlock_cXSSE .infoTitle_OsAQP {
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #3E4243;
}
.content_WK3G3 .contentArea_Ylqpu .infoBlock_cXSSE .info_F0Dw4 {
  font-size: 15px;
  line-height: 20px;
  color: #3E4243;
  margin-top: 8px;
  margin-bottom: 32px;
}
.content_WK3G3 .contentArea_Ylqpu .infoBlock_cXSSE .uploadDate_MiZb6 {
  font-weight: 800;
}
.content_WK3G3 .footer_M9rCb {
  height: 96px;
  padding-top: 30px;
  padding-right: 25px;
  box-shadow: 0px -1px 8px rgba(41, 50, 61, 0.1);
}
.content_WK3G3 .footer_M9rCb .footerRow_zCJua {
  padding-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/customers/CustomerEditor.less"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,iBAAA;EACA,YAAA;AAAF;AAHA;EAMI,YAAA;EACA,gCAAA;EACA,6BAAA;AAAJ;AARA;EAWM,YAAA;AAAN;AAXA;EAcQ,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AAAR;AAnBA;EAuBQ,iBAAA;EACA,WAAA;EACA,YAAA;AADR;AAxBA;EA+BI,mBAAA;AAJJ;AA3BA;EAkCM,YAAA;EACA,kBAAA;AAJN;AA/BA;EAsCQ,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AAJR;AAtCA;EA8CQ,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;AALR;AA7CA;EAsDQ,gBAAA;AANR;AAhDA;EA4DI,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,8CAAA;AATJ;AAtDA;EAkEM,cAAA;AATN","sourcesContent":["\n.content {\n  height : 100%;\n  background: white;\n  width: 600px;\n\n  .header {\n    height: 56px;\n    border-bottom: 1px solid #E7EAEF;\n    border-top: 1px solid #E7EAEF;\n\n    .headerRow {\n      height: 56px;\n\n      .headerTitle {\n        margin: 16px 24px;\n        font-size: 18px;\n        font-weight: 900;\n        line-height: 24px;\n        letter-spacing: 0.015em;\n        color: #28548D;\n      }\n\n      .crossButton {\n        margin: 12px 24px;\n        width: 32px;\n        height: 32px;\n      }\n    }\n  }\n\n  .contentArea {\n    background: #F9FBFB;\n\n    .infoBlock {\n      height: 100%;\n      padding: 32px 24px;\n\n      .infoTitle {\n        font-weight: 900;\n        font-size: 18px;\n        line-height: 24px;\n        letter-spacing: 0.015em;\n        color: #3E4243;\n      }\n\n      .info {\n        font-size: 15px;\n        line-height: 20px;\n        color: #3E4243;\n        margin-top: 8px;\n        margin-bottom: 32px;\n      }\n\n      .uploadDate {\n        font-weight: 800;\n      }\n    }\n  }\n\n  .footer {\n    height: 96px;\n    padding-top: 30px;\n    padding-right: 25px;\n    box-shadow: 0px -1px 8px rgba(41, 50, 61, 0.1);\n\n    .footerRow {\n      padding-top: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content_WK3G3`,
	"header": `header_Tnw5x`,
	"headerRow": `headerRow_K796L`,
	"headerTitle": `headerTitle_l7ZrF`,
	"crossButton": `crossButton_mTR1J`,
	"contentArea": `contentArea_Ylqpu`,
	"infoBlock": `infoBlock_cXSSE`,
	"infoTitle": `infoTitle_OsAQP`,
	"info": `info_F0Dw4`,
	"uploadDate": `uploadDate_MiZb6`,
	"footer": `footer_M9rCb`,
	"footerRow": `footerRow_zCJua`
};
export default ___CSS_LOADER_EXPORT___;
