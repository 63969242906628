import React from 'react'
import { connect } from 'react-redux'
import { Content, PortalOverlay } from '@leverege/ui-elements'
import { Firmware } from '../../../../accessors'
import TableWithSelectableRowsAndActions from '../../lib/table/TableWithSelectableRowsAndActions'
import FirmwareDetails from './FirmwareDetails'
import FirmwareEditor from './FirmwareEditor'
import S from './FirmwareList.less'
import FirmwareTypePills from './FirmwareTypePills'
import SendFirmwareDialog from '../SendFirmwareDialog'
import { FirmwareActions } from '../../../../actions'

class FirmwareList extends React.Component {

  static get OVERLAY_PORTAL_MODE() {
    return {
      HIDE : 'hide',
      VIEW : 'view',
      EDIT : 'edit',
      UPLOAD : 'upload'
    }
  }

  state = {
    overlayPortalMode : FirmwareList.OVERLAY_PORTAL_MODE.HIDE,
    clickedFirmware : null,
    isEditPanelVisible : false,
    showDialog : false,
    dialogType : '',
    selectedActiveFirmwares : []
  }

  onFirmwareSelectionChange = async ( selectedActiveFirmwares ) => {
    this.setState( { selectedActiveFirmwares }, () => {
      this.changeDropdownOptions()
    } )
  }

  onFirmwareClick( firmware ) {
    this.setState( {
      clickedFirmware : firmware,
      overlayPortalMode : FirmwareList.OVERLAY_PORTAL_MODE.VIEW,
    } )
  }

  onFirmwareUpdated = () => {
    const { dispatch } = this.props
    dispatch( FirmwareActions.getAllFirmwares( ) )
  }

  onFirmwareSaved() {
    this.setState( { overlayPortalMode : FirmwareList.OVERLAY_PORTAL_MODE.VIEW } )
  }

  openFirmwareEditor( firmware ) {
    this.setState( {
      clickedFirmware : firmware,
      overlayPortalMode : FirmwareList.OVERLAY_PORTAL_MODE.EDIT,
    } )
  }

  onShowDialog = ( value ) => {
    this.setState( {
      showDialog : true,
      dialogType : value
    } )
  }

  onCloseDialog = () => {
    this.setState( {
      showDialog : false,
      dialogType : null,
    } )
  }

  toggleOverlayPortalVisibility() {
    const { isEditPanelVisible } = this.state

    this.setState( {
      isEditPanelVisible : !isEditPanelVisible
    } )
  }

  onOverlayPortalClose() {
    this.setState( { isEditPanelVisible : false } )
    this.setState( {
      overlayPortalMode : FirmwareList.OVERLAY_PORTAL_MODE.HIDE
    } )
  }

  changeDropdownOptions = () => {
    const { selectedActiveFirmwares } = this.state
    const { onChangeDropdownOptions } = this.props

    let dropdownOptions = []

    if ( selectedActiveFirmwares.length === 0 ) {
      dropdownOptions = dropdownOptions.concat( [
        { key : 1, name : 'Upload Firmware', value : 1, onClick : () => this.toggleOverlayPortalVisibility() }
      ] )
    } else if ( selectedActiveFirmwares.length === 1 ) {
      dropdownOptions = dropdownOptions.concat( [
        { key : 1, name : `Send Firmware to Group (${selectedActiveFirmwares.length})`, value : 1, onClick : () => this.onShowDialog( 'track' ) },
        { key : 2, name : `Send Firmware to Device (${selectedActiveFirmwares.length})`, value : 2, onClick : () => this.onShowDialog( 'device' ) },
        { key : 3, name : `Send Firmware to Multiple Devices (${selectedActiveFirmwares.length})`, value : 3, onClick : () => this.onShowDialog( 'devices' ) },
        { key : 4, name : `Archive Firmwares (${selectedActiveFirmwares.length})`, value : 4, onClick : () => this.onShowDialog( 'archive' ) }
      ] )
    } else {
      dropdownOptions = dropdownOptions.concat( [
        { key : 4, name : `Archive Firmwares (${selectedActiveFirmwares.length})`, value : 4, onClick : () => this.onShowDialog( 'archive' ) }
      ] )
    }

    onChangeDropdownOptions( dropdownOptions )
  }

  componentDidMount = () => {
    const { dispatch } = this.props
    dispatch( FirmwareActions.getAllFirmwares( ) )

    this.changeDropdownOptions()

  }

  render() {
    const { overlayPortalMode, clickedFirmware, showDialog, dialogType, selectedActiveFirmwares, isEditPanelVisible } = this.state
    const { activeFirmwares, loading } = this.props

    const firmware = activeFirmwares.reduce( ( acc, row ) => {
      acc[row.id] = row
      return acc
    }, {} )
    const columns = [
      { Header : 'Name', id : 'name', accessor : Firmware.name, width : 100 },
      {
        Header : 'Uploaded',
        id : 'uploaded',
        accessor : Firmware.uploaded,
        width : 150,
        sortMethod : ( a, b ) => {
          const x = new Date( b ).getTime()
          const y = new Date( a ).getTime()
          return y > x ? 1 : -1;
        }
      },
      { Header : 'Author', id : 'author', accessor : Firmware.author, width : 220 },
      { Header : 'Notes', id : 'notes', accessor : Firmware.notes, width : 300 },
      {
        Header : 'Type',
        id : 'type',
        width : 500,
        Cell : x => <FirmwareTypePills binaries={x && x.original && x.original.data && x.original.data.binaries} />
      }
    ]

    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <TableWithSelectableRowsAndActions
            className={S.table}
            data={activeFirmwares}
            // data={results && results.items ? results.items.map( id => lookup[id] ) : []}
            columns={columns}
            defaultPageSize={15}
            defaultSorted={[ { id : 'uploaded', desc : true } ]}
            loading={loading}
            onSelectionChange={this.onFirmwareSelectionChange}
            onRowClick={row => this.onFirmwareClick( row )} />

          <PortalOverlay
            show={isEditPanelVisible}
            targetId="mainScreen"
            animation="slideFromRight"
            position="right">
            <div>
              <FirmwareEditor
                afterSaveChanges={this.onFirmwareUpdated}
                onClose={() => this.onOverlayPortalClose()} />
            </div>
          </PortalOverlay>

          <PortalOverlay
            show={overlayPortalMode !== FirmwareList.OVERLAY_PORTAL_MODE.HIDE}
            targetId="mainScreen"
            animation="slideFromRight"
            position="right">
            <div>
              {overlayPortalMode === FirmwareList.OVERLAY_PORTAL_MODE.VIEW && (
                <FirmwareDetails
                  firmware={firmware[clickedFirmware.id]}
                  onUpdateFirmware={firmware => this.openFirmwareEditor( firmware )}
                  onClose={() => this.onOverlayPortalClose()} />
              )}
              {overlayPortalMode === FirmwareList.OVERLAY_PORTAL_MODE.EDIT && (
                <FirmwareEditor
                  firmware={firmware[clickedFirmware.id]}
                  afterSaveChanges={() => this.onFirmwareSaved()}
                  onClose={() => this.onOverlayPortalClose()} />
              )}
            </div>
          </PortalOverlay>
          <SendFirmwareDialog
            type={dialogType}
            onClose={this.onCloseDialog}
            firmware={selectedActiveFirmwares[0]}
            firmwares={selectedActiveFirmwares}
            show={showDialog} />
        </Content.Area>
      </Content>
    )
  }
}

export default connect( state => ( {
  activeFirmwares : state.firmware.activeFirmwares,
  loading : state.firmware.loading
} ) )( FirmwareList )
