import ServerApi from '../util/ServerApi' 

const serverApi = new ServerApi( { serverUrl : process.env.SIREN_API_HOST } )

class FirmwareService {
  async getTrackByDeviceId( deviceId ) {
    try {
      const tracks = await serverApi.get( `${deviceId}/track` )
      return tracks
    } catch ( error ) {
      console.log( { error } )
    }
  }

  async getTracksFirmwares( trackId ) {
    try {
      const firmwares = await serverApi.get( `track/firmwares?trackId=${trackId}` )
      return firmwares
    } catch ( error ) {
      console.log( { error } )
    }
  }


  async getTracksDevices( trackId ) {
    try {
      const devices = await serverApi.get( `track/devices?trackId=${trackId}` )
      return devices
    } catch ( error ) {
      console.log( { error } )
    }
  }

  async updateFirmware( firmwareId, data ) {
    try {
      const firmware = await serverApi.put( `firmware/${firmwareId}`, { body : JSON.stringify( { data } ) } )
      return firmware
    } catch ( error ) {
      console.log( { error } )
    }
  }
}


module.exports = FirmwareService
