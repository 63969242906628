// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentArea_kLpGQ {
  background: #F9FBFB;
  padding: 0 16px 16px 16px;
  height: 100%;
}
.contentArea_kLpGQ .dataBlock_SzeQX {
  width: 100%;
  min-width: 300px;
}
.contentArea_kLpGQ .checkInDataBlock_Wq36a {
  width: 50%;
  min-width: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/devices/Device/DeviceOverview.less"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,yBAAA;EACA,YAAA;AAAF;AAHA;EAMI,WAAA;EACA,gBAAA;AAAJ;AAPA;EAWI,UAAA;EACA,gBAAA;AADJ","sourcesContent":["\n.contentArea {\n  background: #F9FBFB;\n  padding: 0 16px 16px 16px;\n  height: 100%;\n\n  .dataBlock {\n    width: 100%;\n    min-width: 300px;\n  }\n\n  .checkInDataBlock{\n    width: 50%;\n    min-width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentArea": `contentArea_kLpGQ`,
	"dataBlock": `dataBlock_SzeQX`,
	"checkInDataBlock": `checkInDataBlock_Wq36a`
};
export default ___CSS_LOADER_EXPORT___;
