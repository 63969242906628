import Path from '@leverege/path'
import { format, parseISO } from 'date-fns'
import { capitalize } from 'lodash'

import { NOT_AVAILABLE } from '../actions/Constants'

const type = ( data ) => {
  const type = new Path( 'type' ).get( data )
  return capitalize( type )
}

const label = ( data ) => {
  const label = new Path( 'label' ).get( data )
  return capitalize( label )
}

const lastUpdate = ( data ) => {
  const lastUpdate = new Path( 'lastUpdate' ).get( data )

  if ( lastUpdate ) {
    const raw = parseISO( new Date( lastUpdate ).toISOString() )
    if ( raw ) {
      return format( raw, 'MM/dd/yyyy HH:mm' )
    }
  }

  return NOT_AVAILABLE
}

const display = ( data ) => {
  const t = type( data )
  if ( t === 'Switchback' || !t ) return '-'
  const display = new Path( 'display' ).get( data )
  return display ? 'Enabled' : 'Disabled'
}

module.exports = {
  type,
  label,
  lastUpdate,
  display
}
