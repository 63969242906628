import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Content, SplitPane } from '@leverege/ui-elements'

import Menu from '../menu/Menu'
import Header from '../header/Header'
import Devices from '../devices/Devices'
import { getAllowedMenuItems, getUserRole } from '../menu-items'
import NotFound from '../NotFound'

import S from '../App.less'

const Main = ( { router, user, usersRoles } ) => {
  const role = getUserRole( user )
  const menus = getAllowedMenuItems( role, usersRoles )

  return (
    <Content horizontal>
      <SplitPane variant="default" minSize={256} maxSize={256} defaultSize={256} autoSaveKey="main-split-pane" split="vertical">
        <Menu />
        <Content>
          <Content.Header className={S.header}>
            <Header />
          </Content.Header>
          <Content.Area id="mainScreen">
            <Switch location={router.location}>
              {menus.map( ( { routes } ) => routes.map( ( { path, component }, i ) => (
                <Route key={`route-${path}-${i}`} path={path} component={component} exact />
              ) ) )}
              <Route path="/" component={Devices} exact />
              <Route path="/not-found" component={NotFound} />
            </Switch>
          </Content.Area>
        </Content>
      </SplitPane>
    </Content>
  )
}

export default connect(
  state => ( {
    router : state.router,
    user : state.user.user,
    usersRoles : state.user.usersRoles
  } ) )( Main )
