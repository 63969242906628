// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_VG5Bt {
  height: 100%;
  background: white;
  width: 600px;
}
.content_VG5Bt .header_EqZs5 {
  height: 56px;
  border-bottom: 1px solid #E7EAEF;
  border-top: 1px solid #E7EAEF;
}
.content_VG5Bt .header_EqZs5 .headerRow_rSlJ3 {
  height: 56px;
}
.content_VG5Bt .header_EqZs5 .headerRow_rSlJ3 .headerTitle_mtVZJ {
  margin: 16px 24px;
  font-size: 18px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #28548D;
}
.content_VG5Bt .header_EqZs5 .headerRow_rSlJ3 .crossButton_XFpnN {
  margin: 12px 24px;
  width: 32px;
  height: 32px;
}
.content_VG5Bt .contentArea_otcae {
  background: #F9FBFB;
  padding: 12px 24px;
}
.content_VG5Bt .contentArea_otcae .emailInput_pCvah {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #C1C6C7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 24px;
}
.content_VG5Bt .footer_V_oP4 {
  height: 96px;
  padding-top: 30px;
  padding-right: 25px;
  box-shadow: 0px -1px 8px rgba(41, 50, 61, 0.1);
}
.content_VG5Bt .footer_V_oP4 .footerRow_QzLAi {
  padding-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/devices/Device/DeviceCustomerMove.less"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,iBAAA;EACA,YAAA;AAAF;AAHA;EAMI,YAAA;EACA,gCAAA;EACA,6BAAA;AAAJ;AARA;EAWM,YAAA;AAAN;AAXA;EAcQ,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AAAR;AAnBA;EAuBQ,iBAAA;EACA,WAAA;EACA,YAAA;AADR;AAxBA;EA+BI,mBAAA;EACA,kBAAA;AAJJ;AA5BA;EAmCM,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;AAJN;AArCA;EA8CI,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,8CAAA;AANJ;AA3CA;EAoDM,cAAA;AANN","sourcesContent":["\n.content {\n  height : 100%;\n  background: white;\n  width: 600px;\n\n  .header {\n    height: 56px;\n    border-bottom: 1px solid #E7EAEF;\n    border-top: 1px solid #E7EAEF;\n\n    .headerRow {\n      height: 56px;\n\n      .headerTitle {\n        margin: 16px 24px;\n        font-size: 18px;\n        font-weight: 900;\n        line-height: 24px;\n        letter-spacing: 0.015em;\n        color: #28548D;\n      }\n\n      .crossButton {\n        margin: 12px 24px;\n        width: 32px;\n        height: 32px;\n      }\n    }\n  }\n\n  .contentArea {\n    background: #F9FBFB;\n    padding: 12px 24px;\n\n    .emailInput {\n      width: 100%;\n      background: #FFFFFF;\n      border: 1px solid #C1C6C7;\n      box-sizing: border-box;\n      border-radius: 4px;\n      margin-top: 4px;\n      margin-bottom: 24px;\n    }\n  }\n\n  .footer {\n    height: 96px;\n    padding-top: 30px;\n    padding-right: 25px;\n    box-shadow: 0px -1px 8px rgba(41, 50, 61, 0.1);\n\n    .footerRow {\n      padding-top: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content_VG5Bt`,
	"header": `header_EqZs5`,
	"headerRow": `headerRow_rSlJ3`,
	"headerTitle": `headerTitle_mtVZJ`,
	"crossButton": `crossButton_XFpnN`,
	"contentArea": `contentArea_otcae`,
	"emailInput": `emailInput_pCvah`,
	"footer": `footer_V_oP4`,
	"footerRow": `footerRow_QzLAi`
};
export default ___CSS_LOADER_EXPORT___;
