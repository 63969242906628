module.exports = {
  "hierarchy": {
    "small": [
      "compositeTextInput-small"
    ],
    "search": [
      "compositeTextInput-search"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "search": {
      "button": "search",
      "icon": "fa fa-search"
    }
  }
}