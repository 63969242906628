const Constants = require( '../actions/Constants' );

const initialState = {
  loading : false,
  devices : [],
  selectedDevice : null
}

function device( state = initialState, action ) {

  switch ( action.type ) {

    case Constants.DEVICES_LOADING: {
      const { payload } = action

      return {
        ...state,
        loading : payload.loading
      }
    }

    case Constants.DEVICES_LOADED: {
      const { payload } = action

      return {
        ...state,
        devices : payload.devices
      }
    }

    case Constants.SET_SELECTED_DEVICE: {
      const { payload } = action
      return {
        ...state,
        selectedDevice : payload.device
      }
    }

    default:
      return state
  }

}

module.exports = device
