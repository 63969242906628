import React from 'react';
import { Content, Dialog, Flex, Button, Text } from '@leverege/ui-elements'
import S from './ConfirmationDialog.less'


class ConfirmationDialog extends React.Component {

  render() {
    const {
      headerText,
      bodyText,
      show,
      onConfirm = () => {},
      onDeny = () => {}
    } = this.props

    return (
      <Dialog show={show} className={S.dialog}>
        <Content relative className={S.content}>
          <Content.Header className={S.header}>
            <Text>{ headerText }</Text>
          </Content.Header>
          <Content.Area className={S.contentArea}>
            <Text>{ bodyText }</Text>
          </Content.Area>
          <Content.Footer className={S.header}>
            <Flex variant="buttons">
              <Button variant="delete" onClick={onDeny}>No</Button>
              <Button variant="hollow" onClick={onConfirm}>Yes</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </Dialog>
    );
  }
}


export default ConfirmationDialog
