// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_Bcix_ {
  color: var(--color-label1);
  padding: var(--size-medium) var(--size-large);
  font-size: var(--size-fontMedium);
}
.label_Bcix_.label-small_gSrGx {
  padding: var(--size-small) var(--size-medium);
  font-size: var(--size-fontSmall);
}
.label_Bcix_.label-left_x1EuL {
  color: var(--color-label2);
  padding: 0px;
}
.label_Bcix_.label-fixedWidth_4GNS7 {
  color: var(--color-brand1);
  font-weight: 600;
  font-family: var(--fontFamily-primary);
}
.label_Bcix_.label-title_yLOCn {
  font-size: var(--size-fontLarge);
  font-weight: 700;
  padding-left: var(--size-padSmall);
  padding-right: var(--size-padSmall);
}
`, "",{"version":3,"sources":["webpack://./src/theme/Label.css"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,6CAAA;EACA,iCAAA;AACF;AACA;EACE,6CAAA;EACA,gCAAA;AACF;AACA;EACE,0BAAA;EACA,YAAA;AACF;AACA;EACE,0BAAA;EACA,gBAAA;EACA,sCAAA;AACF;AACA;EACE,gCAAA;EACA,gBAAA;EACA,kCAAA;EACA,mCAAA;AACF","sourcesContent":[".label {\n  color: var( --color-label1 );\n  padding: var( --size-medium ) var( --size-large );\n  font-size: var( --size-fontMedium );\n}\n.label.label-small {\n  padding: var( --size-small ) var( --size-medium );\n  font-size: var( --size-fontSmall );\n}\n.label.label-left {\n  color: var( --color-label2 );\n  padding: 0px;\n}\n.label.label-fixedWidth {\n  color: var( --color-brand1 );\n  font-weight: 600;\n  font-family: var( --fontFamily-primary );\n}\n.label.label-title {\n  font-size: var( --size-fontLarge );\n  font-weight: 700;\n  padding-left: var( --size-padSmall );\n  padding-right: var( --size-padSmall );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `label_Bcix_`,
	"label-small": `label-small_gSrGx`,
	"label-left": `label-left_x1EuL`,
	"label-fixedWidth": `label-fixedWidth_4GNS7`,
	"label-title": `label-title_yLOCn`
};
export default ___CSS_LOADER_EXPORT___;
