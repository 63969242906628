import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Flex, Text, Button, Content } from '@leverege/ui-elements'
import moment from 'moment'
import { CustomerActions } from '../../../actions'
import S from './CustomerDetails.less'
import { getCustomerById } from '../../../actions/CustomerActions'

class CustomerDetails extends React.Component {

  componentDidMount() {
    const { customerId, dispatch } = this.props
    dispatch( CustomerActions.getCustomer( customerId ) )
    dispatch( getCustomerById( customerId ) )
    
  }

  onDeviceClick = ( device ) => {    
    const { dispatch } = this.props
    dispatch( push( `/devices/${device.id}` ) )
  }

  render() {
    const { lookup, customerId, onClose, onEditCustomer, customer } = this.props

    const data = lookup[customerId]

    return (
      <React.Fragment>
        <Content relative className={S.content}>
          <Content.Header className={S.header}>
            <Flex direction="row" className={S.headerRow} justify="space-between">
              <Text className={S.headerTitle}>
                {data.email}
              </Text>
              <div onClick={() => onClose()}>
                <img src="/images/cross.png" alt="close" className={S.crossButton} />
              </div>
            </Flex>
          </Content.Header>
          <Content.Area className={S.contentArea}>
            <Flex direction="column" className={S.infoBlock}>
              {customer && customer.deactivationRequested && (
                <Text className={S.warningText}>
                  This customer requested to deactivate their account on 
                  {' '}
                  {moment( customer && customer.deactivationRequestDate ).format( 'MM/DD/YYYY' )}
                  {' '}
                  in the Siren Marine mobile app
                </Text>
              )}
              <br />
              <Flex direction="row" className={S.infoTitle}>
                Email
              </Flex>
              <Flex direction="row" className={S.info}>
                {data.email}
              </Flex>
              <Flex direction="row" className={S.infoTitle}>
                Customer Devices
              </Flex>
              <Flex direction="column" className={S.info}>
                {( !data.devices || data.devices.length === 0 ) && 'No devices tied to this customer' }
                {data.devices && data.devices.map( ( device ) => {
                  return (
                    <Text key={device.mobileId} 
                      className={S.deviceLink}
                      onClick={() => this.onDeviceClick( device )}>
                      {`${device.mobileId}${device.name !== device.mobileId ? ` - ${device.name}` : ''}`}
                    </Text>
                  ) 
                }
                )}
              </Flex>
            </Flex>
          </Content.Area>
          <Content.Footer className={S.footer}>
            <Flex variant="buttons" className={S.footerRow}>
              <Button onClick={() => onEditCustomer()}>Edit Details</Button>
              <Button onClick={() => onClose()}>Close</Button>
            </Flex>
          </Content.Footer>
        </Content>
      </React.Fragment>
    )
  }
}


export default connect( state => ( {
  customer : state.customer.customer,
  lookup : state.customer.lookup,
  author : state.auth.profile && state.auth.profile.email
} ) )( CustomerDetails )
