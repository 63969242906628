// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiSelect_FoACT > .rw-widget-input {
  height: 41px;
}
.multiSelect_FoACT > .rw-widget-input .rw-multiselect-tag {
  margin-top: 4px;
  margin-bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/theme/MultiSelect.css"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACA;EACE,eAAA;EACA,kBAAA;AACF","sourcesContent":[".multiSelect > :global( .rw-widget-input ) {\n  height: 41px;\n}\n.multiSelect > :global( .rw-widget-input .rw-multiselect-tag ) {\n  margin-top: 4px;\n  margin-bottom: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiSelect": `multiSelect_FoACT`
};
export default ___CSS_LOADER_EXPORT___;
