import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Flex, Breadcrumbs } from '@leverege/ui-elements'

import S from './Header.less'

class Header extends React.Component {

  onClick = ( evt ) => {
    const { dispatch } = this.props
    dispatch( push( evt.data ) )
  }

  onBreadCrumbClick = ( { data } ) => {
    const { dispatch } = this.props
    const { eventData } = data
    dispatch( push( eventData ) )
  }

  render() {
    const { router, lookup, selectedDevice } = this.props
    const path = router.location.pathname
    const parts = path.split( '/' )
    const crumbs = parts.map( ( p, i ) => {
      const eventData = parts.slice( 0, i + 1 ).join( '/' )
      if ( lookup[p] && ( lookup[p].name || lookup[p].email ) ) {
        return { name : lookup[p].name || lookup[p].email, eventData }
      }
      if ( i === 2 && parts[1] === 'devices' && selectedDevice && selectedDevice.esn ) {
        return { name : selectedDevice.esn, eventData }
      }
      let name = ( p || 'Home' )
      name = name.substr( 0, 1 ).toUpperCase() + name.substr( 1 )
      return { name, eventData }
    } )

    return (
      <Flex variant="noSpace" className={S.header} justify="space-between" align="center">
        <Breadcrumbs crumbs={crumbs} onClick={this.onBreadCrumbClick} />
        <Flex />
        <Flex variant="noSpace" align="stretch" alignSelf="stretch">
          {/* <ToggleButton 
              variant="link"
              value={path.startsWith( '/dashboard' ) || path === '/'}
              eventData=""
              onChange={this.onClick}>
              Dashboard
            </ToggleButton>
            <ToggleButton 
              variant="link"
              value={path.startsWith( '/analytics' )}
              eventData="/analytics"
              onChange={this.onClick}>
              Reports
            </ToggleButton> */}

          <span style={{ paddingTop : 20, fontWeight : 800, color : '#67798E', fontSize : '15px' }}>SHIP</span>
          <span style={{ padding : '20px 20px 0px 0px', color : '#67798E', fontSize : '15px' }}>| Siren Hardware Interactive Portal</span>

        </Flex>

      </Flex>
    )
  }
}

export default connect(
  state => ( {
    lookup : {
      ...state.data.lookup,
      ...state.customer.lookup
    },
    router : state.router,
    selectedDevice : state.device.selectedDevice
  } ) )( Header )
