module.exports = {
  "hierarchy": {
    "horizontal": [
      "dataBlock-horizontal"
    ],
    "deviceInfo": [
      "dataBlock-deviceInfo"
    ],
    "transparent": [
      "dataBlock-deviceInfo",
      "dataBlock-transparent"
    ]
  },
  "props": {
    "default": {},
    "horizontal": {
      "vertical": false
    },
    "deviceInfo": {},
    "transparent": {}
  }
}