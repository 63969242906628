import Path from '@leverege/path'
import { format } from 'date-fns'
import { capitalize } from 'lodash'

const name = ( data ) => {
  const name = Object.keys( data.version )[0]
  return capitalize( name )
}

const version = ( data ) => {
  const name = Object.keys( data.version )[0]
  return new Path( `version/${name}/version` ).get( data )
}

// const notes = new Path( 'data/notes' ).get
const author = new Path( 'time' ).get
const sent = ( data ) => {
  const value = new Path( 'time' ).get( data )
  return format( value, 'MM/dd/yyyy HH:mm' )
}

module.exports = {
  name,
  version,
  author,
  sent,  
}
