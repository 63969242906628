import Firmware from './firmware/Firmware'
import Devices from './devices/Devices'
import DeviceScreen from './devices/Device/DeviceScreen'
import CustomerScreen from './customers/CustomerScreen'
import DeploymentTracks from './tracks/DeploymentTracks'
import Admin from './admin/Admin'
import Inventory from './devices/Inventory'

export const menuItems = [ {
  title : 'Devices',
  icon : '/images/svg/white/s3p.svg',
  eventData : '/devices',
  value : path => path === '/devices',
  routes : [
    { path : '/devices', component : Devices },
    { path : '/devices/:deviceId', component : DeviceScreen }
  ],
  permission : 'ShipDeviceManager'
}, {
  title : 'Inventory',
  icon : '/images/svg/white/inventory.svg',
  eventData : '/inventory',
  value : path => path === '/inventory',
  routes : [
    { path : '/inventory', component : Inventory },
  ],
  permission : 'ShipInventoryManager'
},
{
  title : 'Firmware',
  icon : '/images/svg/white/export.svg',
  eventData : '/firmware',
  value : path => path === '/firmware',
  routes : [
    { path : '/firmware', component : Firmware },
    { path : '/firmware/:firmwareId', component : Firmware }
  ],
  permission : 'ShipFirmwareManager'
}, {
  title : 'Customers',
  icon : '/images/svg/white/user.svg',
  eventData : '/customers',
  value : path => path === '/customers',
  routes : [
    { path : '/customers', component : CustomerScreen },
    { path : '/customers/:customerId', component : CustomerScreen }
  ],
  permission : 'ShipCustomerManager'
}, {
  title : 'Groups',
  icon : '/images/svg/white/users.svg',
  eventData : '/groups',
  value : path => path === '/groups',
  routes : [
    { path : '/groups', component : DeploymentTracks },
    { path : '/groups/:trackId', component : DeploymentTracks }
  ],
  permission : 'ShipGroupManager'
}, {
  title : 'Settings',
  icon : '/images/svg/white/settings.svg',
  eventData : '/settings',
  value : path => path === '/settings',
  routes : [
    { path : '/settings', component : Admin }
  ],
  permission : 'ShipSettingManager'
} ]

export const getUserRole = ( user ) => {
  if ( !user ) return null

  // Default to ShipEngineer role
  let role = 'ShipEngineer'
  const userRoleNames = user?.roles?.map( r => r.name )
  if ( userRoleNames?.includes( 'ShipAdmin' ) ) {
    role = 'ShipAdmin'
  } else if ( userRoleNames?.includes( 'ShipSupport' ) ) {
    role = 'ShipSupport'
  }
  return role
}

// usersRoles
// {
//   ShipAdmin : [ ShipDeviceManager, ShipFirmwareManager ],
// }
export const getAllowedMenuItems = ( userRole, usersRoles ) => {
  if ( !userRole || !usersRoles ) return []

  // Get permission list for user's role
  const permissions = usersRoles[userRole]
  // Return menu items whose permission is included in the permission list
  return menuItems?.filter( menu => permissions?.includes( menu.permission ) )
}
