import P from '@leverege/promise'
import Constants from './Constants'
import TrackService from '../services/TrackService'

function updateTrack( trackId, data = {} ) {
  const trackService = new TrackService()

  const {
    devices,
    firmwares,
    name
  } = data

  return async ( dispatch, getState, { api } ) => {
    const reqs = []

    if ( devices.added && devices.added.length > 0 ) {
      devices.added.forEach( ( item ) => {
        reqs.push( () => {
          return trackService.addDeviceToTrack( api, trackId, item.id )
        } )
      } )
    }

    if ( firmwares.added && firmwares.added.length > 0 ) {
      firmwares.added.forEach( ( item ) => {
        reqs.push( () => {
          return trackService.addFirmwareToTrack( api, trackId, item.id )
        } )
      } )
    }

    if ( devices.removed && devices.removed.length > 0 ) {
      devices.removed.forEach( ( item ) => {
        reqs.push( () => {
          return trackService.removeDeviceFromTrack( api, trackId, item.id )
        } )
      } )
    }

    if ( firmwares.removed && firmwares.removed.length > 0 ) {
      firmwares.removed.forEach( ( item ) => {
        reqs.push( () => {
          return trackService.removeFirmwareFromTrack( api, trackId, item.id )
        } )
      } )
    }

    reqs.push( () => {
      // explicity specify other properties to update besides 'devices' and 'firmwares' handled above
      return trackService.updateTrack( api, trackId, { name } )
    } )

    try {
      await P.limit( reqs, { limit : 5 } )
    } catch ( ex ) {
      console.error( ex )
      throw ex
    }
  }
}

function createTrack( data ) {
  return async ( dispatch, getState, { api } ) => {
    const { devices, firmwares, ...rest } = data
    const trackInterface = api.interface( Constants.SYSTEM_ID, 'track' )

    try {
      // Requires "Project -> Deployment Track -> Create deployment track" authz perm
      const res = await trackInterface
        .create( {
          name : rest.name,
          data : {
            ...rest
          }
        } )

      const nDevices = await P.mapLimit( devices.added, ( device ) => {
        // Requires "Project -> Deployment Track -> Add device in deployment track" authz perm
        return trackInterface
          .obj( res )
          .grp( 'devices' )
          .create( device )
      }, { handleReject : true, limit : 5 } )

      const nFirmwares = await P.mapLimit( firmwares.added, ( device ) => {
        // Requires "Project -> Deployment Track -> Add firmware in deployment track" authz perm
        return trackInterface
          .obj( res )
          .grp( 'firmwares' )
          .create( device )
      }, { handleReject : true, limit : 5 } )

      return { track : res, devices : nDevices, firmwares : nFirmwares }
    } catch ( ex ) {
      throw ex
    }
  }
}


function getTrackList( ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.TRACKS_LOADING, payload : { trackListLoading : true } } )
    try {
      // Requires "Project -> Deployment Track -> List deployment track" authz perm
      const { items : trackList } = await api.interface( Constants.SYSTEM_ID, 'track' ).list()
      dispatch( { type : Constants.TRACKS_LOADED, trackList, trackListLoading : false } )
    } catch ( error ) {
      dispatch( { type : Constants.TRACKS_LOADED, payload : { trackList : [], trackListLoading : false } } )
    }
  }
}

function getTrackByDeviceId( deviceId ) {
  return async ( dispatch, getState, { serverApi, api } ) => {
    dispatch( { type : Constants.TRACKS_BY_DEVICE_LOADING, tracksLoading : true } )

    try {
      // Requires "Project -> Deployment Track -> List devices in deployment track" authz perm
      const tracksByDeviceId = await api.interface( Constants.SYSTEM_ID, 'track' )
        .obj( '*' )
        .grp( 'devices' )
        .obj( deviceId )
        .get()
      dispatch( { type : Constants.TRACKS_BY_DEVICE_LOADED, tracksByDeviceId, tracksLoading : false } )
    } catch ( error ) {
      dispatch( { type : Constants.TRACKS_BY_DEVICE_LOADED, tracksByDeviceId : [], tracksLoading : false } )
    }
  }
}

function getTrackDevices( trackId = '*' ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.TRACKS_DEVICES_LOADING, trackDevicesLoading : true } )
    try {
      // Requires "Project -> Deployment Track -> List devices in deployment track" authz perm
      const { items : trackDevices } = await api.interface( Constants.SYSTEM_ID, 'track' )
        .obj( trackId )
        .grp( 'devices' )
        .get()
      dispatch( { type : Constants.TRACKS_DEVICES_LOADED, trackDevices, trackDevicesLoading : false } )
    } catch ( error ) {
      dispatch( { type : Constants.TRACKS_DEVICES_LOADED, trackDevices : [], trackDevicesLoading : false } )
    }
  }
}

function getTrackFirmwares( trackId = '*' ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.TRACKS_FIRMWARES_LOADING, trackDevicesLoading : true } )
    try {
      // Requires "Project -> Deployment Track -> List firmwares in deployment track" authz perm
      const { items : trackFirmwares } = await api.interface( Constants.SYSTEM_ID, 'track' )
        .obj( trackId )
        .grp( 'firmwares' )
        .get()
      dispatch( { type : Constants.TRACKS_FIRMWARES_LOADED, trackFirmwares, trackFirmwaresLoading : false } )
    } catch ( error ) {
      dispatch( { type : Constants.TRACKS_FIRMWARES_LOADED, trackFirmwares : [], trackDevicesLoading : false } )
    }
  }
}

function getTrackGroups( ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.TRACKS_LOADING, payload : { trackListLoading : true } } )
    try {
      const trackInterface = api.interface( Constants.SYSTEM_ID, 'track' )
      // Requires "Project -> Deployment Track -> List deployment track" authz perm
      const trackList = await trackInterface.list()
      // Requires "Project -> Deployment Track -> List devices in deployment track" authz perm
      const trackDevices = await trackInterface.obj( '*' ).grp( 'devices' ).list()
      // Requires "Project -> Deployment Track -> List firmwares in deployment track" authz perm
      const trackFirmwares = await trackInterface.obj( '*' ).grp( 'firmwares' ).list()
      dispatch( { type : Constants.TRACKS_LOADED, trackList : trackList.items, trackListLoading : false } )
      dispatch( { type : Constants.TRACKS_DEVICES_LOADED, trackDevices : trackDevices.items, trackListLoading : false } )
      dispatch( { type : Constants.TRACKS_FIRMWARES_LOADED, trackFirmwares : trackFirmwares.items, trackListLoading : false } )
    } catch ( error ) {
      dispatch( { type : Constants.TRACKS_LOADED, payload : { trackList : [], trackListLoading : false } } )
    }
  }
}

module.exports = {
  updateTrack,
  createTrack,
  getTrackList,
  getTrackByDeviceId,
  getTrackDevices,
  getTrackFirmwares,
  getTrackGroups,
}
