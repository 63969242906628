// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textArea_uMvVa {
  color: var(--color-label2);
  border: 1px solid var(--color-brand1);
  padding: var(--size-medium) var(--size-large);
  outline: none;
  font-size: var(--size-fontMedium);
  transition: background-color 0.2s, color 0.2s, transform 0.2s;
  border-radius: var(--size-small);
  background-color: var(--color-white);
}
.textArea_uMvVa:focus {
  border-top-color: var(--color-brand4);
  border-left-color: var(--color-brand4);
  border-right-color: var(--color-brand4);
  border-bottom-color: var(--color-brand4);
}
.textArea_uMvVa[readonly] {
  color: var(--color-disabled);
  border-top-color: var(--color-disabled);
  border-left-color: var(--color-disabled);
  border-right-color: var(--color-disabled);
  border-bottom-color: var(--color-disabled);
}
.textArea_uMvVa[data-error=true] {
  border-top-color: var(--color-action2);
  border-left-color: var(--color-action2);
  border-right-color: var(--color-action2);
  border-bottom-color: var(--color-action2);
}
.textArea_uMvVa::placeholder {
  color: var(--color-disabled);
  font-size: var(--size-fontSmall);
  font-style: italic;
}
.textArea_uMvVa.textArea-small_oZqH4 {
  padding: var(--size-small) var(--size-medium);
  font-size: var(--size-fontSmall);
}
.textArea_uMvVa.textArea-esns_qlrgv {
  min-width: 250px;
}
.textArea_uMvVa.textArea-portalForm_BDT2K {
  width: 90%;
  margin-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/theme/TextArea.css"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,qCAAA;EACA,6CAAA;EACA,aAAA;EACA,iCAAA;EACA,6DAAA;EACA,gCAAA;EACA,oCAAA;AACF;AACA;EACE,qCAAA;EACA,sCAAA;EACA,uCAAA;EACA,wCAAA;AACF;AACA;EACE,4BAAA;EACA,uCAAA;EACA,wCAAA;EACA,yCAAA;EACA,0CAAA;AACF;AACA;EACE,sCAAA;EACA,uCAAA;EACA,wCAAA;EACA,yCAAA;AACF;AACA;EACE,4BAAA;EACA,gCAAA;EACA,kBAAA;AACF;AACA;EACE,6CAAA;EACA,gCAAA;AACF;AACA;EACE,gBAAA;AACF;AACA;EACE,UAAA;EACA,iBAAA;AACF","sourcesContent":[".textArea {\n  color: var( --color-label2 );\n  border: 1px solid var( --color-brand1 );\n  padding: var( --size-medium ) var( --size-large );\n  outline: none;\n  font-size: var( --size-fontMedium );\n  transition: background-color 0.2s,color 0.2s,transform 0.2s;\n  border-radius: var( --size-small );\n  background-color: var( --color-white );\n}\n.textArea:focus {\n  border-top-color: var( --color-brand4 );\n  border-left-color: var( --color-brand4 );\n  border-right-color: var( --color-brand4 );\n  border-bottom-color: var( --color-brand4 );\n}\n.textArea[readonly] {\n  color: var( --color-disabled );\n  border-top-color: var( --color-disabled );\n  border-left-color: var( --color-disabled );\n  border-right-color: var( --color-disabled );\n  border-bottom-color: var( --color-disabled );\n}\n.textArea[data-error=true] {\n  border-top-color: var( --color-action2 );\n  border-left-color: var( --color-action2 );\n  border-right-color: var( --color-action2 );\n  border-bottom-color: var( --color-action2 );\n}\n.textArea::placeholder {\n  color: var( --color-disabled );\n  font-size: var( --size-fontSmall );\n  font-style: italic;\n}\n.textArea.textArea-small {\n  padding: var( --size-small ) var( --size-medium );\n  font-size: var( --size-fontSmall );\n}\n.textArea.textArea-esns {\n  min-width: 250px;\n}\n.textArea.textArea-portalForm {\n  width: 90%;\n  margin-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textArea": `textArea_uMvVa`,
	"textArea-small": `textArea-small_oZqH4`,
	"textArea-esns": `textArea-esns_qlrgv`,
	"textArea-portalForm": `textArea-portalForm_BDT2K`
};
export default ___CSS_LOADER_EXPORT___;
