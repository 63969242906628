module.exports = {
  "hierarchy": {
    "small": [
      "radioButton-small"
    ]
  },
  "props": {
    "default": {
      "iconOn": "fa fa-circle"
    },
    "small": {
      "iconOn": "fa fa-circle"
    }
  }
}