import Api from '@leverege/api'
import { Constants } from '../actions'

const API = Api.init( {
  host : process.env.API_HOST,
  projectId : process.env.PROJECT_ID,
  storeToken : true,
} )

class TrackService {
  getTrackObj( api, trackId ) {
    return api.interface( Constants.SYSTEM_ID, 'track' ).obj( trackId )
  }

  async getTrackGroupSelected( trackId ) {
    try {
      // Requires "Project -> Deployment Track -> List devices in a deployment track" authz perm
      const devices = await this.getTrackObj( API, trackId ).grp( 'devices' ).list()
      // Requires "Project -> Deployment Track -> List firmwares in a deployment track" authz perm
      const firmwares = await this.getTrackObj( API, trackId ).grp( 'firmwares' ).list()
      return { devices, firmwares }
    } catch ( error ) {
      // TODO: show error dialog
    }
  }

  // Returns track
  async deleteTrack( trackId ) {
    try {
      // Requires "Project -> Deployment Track -> Delete deployment track" authz perm
      return this.getTrackObj( API, trackId ).delete()
    } catch ( error ) {
      // TODO: show error dialog
    }
  }

  // Returns track
  async updateTrack( api, trackId, data ) {
    try {
      // Requires "Project -> Deployment Track -> Update deployment track" authz perm
      return this.getTrackObj( api, trackId ).update( {
        // the 'data' property only updates info @ fire db -> /rt/:systemId/dev/:deviceId
        data,
        // special 'name' property must also be updated at top-level to edit fire db -> /device/:deviceId
        name : data.name, 
      } )
    } catch ( error ) {
      // TODO: show error dialog
    }
  }

  // Returns track
  async addDeviceToTrack( api, trackId, deviceId ) {
    try {
      // Requires "Project -> Deployment Track -> Add device in deployment track" authz perm
      return this.getTrackObj( api, trackId ).grp( 'devices' ).create( { id : deviceId } )
    } catch ( error ) {
      // TODO: show error dialog
    }
  }

  // Returns device
  async removeDeviceFromTrack( api, trackId, deviceId ) {
    try {
      // Requires "Project -> Deployment Track -> Remove device in deployment track" authz perm
      return this.getTrackObj( api, trackId ).grp( 'devices' ).delete( { id : deviceId } )
    } catch ( error ) {
      // TODO: show error dialog
    }
  }

  // Returns track
  async addFirmwareToTrack( api, trackId, firmwareId ) {
    try {
      // Requires "Project -> Deployment Track -> Add firmware in deployment track" authz perm
      return this.getTrackObj( api, trackId ).grp( 'firmwares' ).create( { id : firmwareId } )
    } catch ( error ) {
      // TODO: show error dialog
    }
  }

  // Returns firmware
  async removeFirmwareFromTrack( api, trackId, firmwareId ) {
    try {
      // Requires "Project -> Deployment Track -> Remove firmware in deployment track" authz perm
      return this.getTrackObj( api, trackId ).grp( 'firmwares' ).delete( { id : firmwareId } )
    } catch ( error ) {
      // TODO: show error dialog
    }
  }
}


module.exports = TrackService
