import Path from '@leverege/path'
import { format } from 'date-fns'

const name = new Path( 'data/name' ).get
const notes = new Path( 'data/notes' ).get
const author = new Path( 'data/author' ).get
const uploaded = ( data ) => {
  const value = new Path( 'created' ).get( data )
  return format( value, 'MM/dd/yyyy HH:mm' )
}

const ITEM_TYPE = 'firmware'
const ITEMS_TARGETED = `${ITEM_TYPE}sTargeted`
const ITEMS_SELECTED = `${ITEM_TYPE}sSelected`

module.exports = {
  name,
  notes,
  author,
  uploaded,
  ITEM_TYPE,
  ITEMS_TARGETED,
  ITEMS_SELECTED,
}
