import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, PortalOverlay, Toast } from '@leverege/ui-elements'

import DefaultTable from '../../lib/table/DefaultTable'
import Spinner from '../../lib/spinner/Spinner'

import DeviceAlertRecipientDetails from './DeviceAlertRecipientDetails'
import Device from '../../../../accessors/Device'
import { DeviceActions } from '../../../../actions'
import ConfirmationDialog from '../../lib/dialog/ConfirmationDialog'

import S from './DeviceCustomers.less'

class DeviceAlertRecipients extends Component {

  static get OVERLAY_PORTAL_MODE() {
    return {
      HIDE : 'hide',
      VIEW : 'view'
    }
  }

  state = {
    overlayPortalMode : DeviceAlertRecipients.OVERLAY_PORTAL_MODE.HIDE,
    selectedAlertRecipientIndex : null,
    showDialog : false,
    loading : false
  }

  onRowClick = ( { index } ) => {
    this.setState( {
      overlayPortalMode : DeviceAlertRecipients.OVERLAY_PORTAL_MODE.VIEW,
      selectedAlertRecipientIndex : index
    } )
  }

  setOverlayPortalMode = ( overlayPortalMode ) => {
    this.setState( { overlayPortalMode } )
  }

  clearAlertRecipients = async () => {
    // const { selectedCustomers } = this.state
    const { device, dispatch } = this.props;

    this.setState( { showDialog : false, loading : true } )

    try {
      await dispatch( DeviceActions.clearDeviceAlertRecipients( device?.id ) )

      Toast.success(
        'Successfully removed alert recipients, manually refresh the page to verify',
        { containerId : 'toastDefault' }
      )

      this.setState( { showDialog : false, loading : false } )

    } catch ( error ) {
      this.setState( { showDialog : false, loading : false } )
    }
  }

  changeDropdownOptions = () => {
    // const { selectedRecipients } = this.state
    const { onChangeDropdownOptions } = this.props

    let dropdownOptions = []

    dropdownOptions = dropdownOptions.concat( [
      { key : 1, name : 'Clear Alert Recipients', value : 9, onClick : () => this.setState( { showDialog : true } ) },
    ] )

    onChangeDropdownOptions( dropdownOptions )
  }

  componentDidMount = async () => {
    this.changeDropdownOptions()
  }

  render() {
    const { overlayPortalMode, selectedAlertRecipientIndex, showDialog, loading } = this.state
    const { device } = this.props

    const alertRecipients = Device.getAlertRecipients( device )

    const columns = [
      { Header : 'Name', accessor : 'name' },
      { Header : 'Email', accessor : 'email' },
      { Header : 'Email Enabled?', Cell : x => ( x.original.emailEnabled ? 'Yes' : 'No' ) },
      { Header : 'Phone', accessor : 'phone' },
      { Header : 'Phone Enabled?', Cell : x => ( x.original.phoneEnabled ? 'Yes' : 'No' ) },
      { Header : 'Daily Status Reports Enabled?', Cell : x => ( x.original.dailyReportsEnabled ? 'Yes' : 'No' ) },
    ]

    return (
      <Flex variant="colM" className={S.contentArea}>
        {loading && <Spinner />}
        { !loading && ( 
          <Flex variant="colM" className={S.tableArea}>
            <DefaultTable
              className={S.table}
              data={alertRecipients}
              columns={columns}
              loading={false}
              onRowClick={row => this.onRowClick( row )} />
          </Flex>
        ) }
        <PortalOverlay
          show={overlayPortalMode !== DeviceAlertRecipients.OVERLAY_PORTAL_MODE.HIDE}
          targetId="mainScreen"
          animation="slideFromRight"
          position="right">
          <div>
            { overlayPortalMode === DeviceAlertRecipients.OVERLAY_PORTAL_MODE.VIEW && (
              <DeviceAlertRecipientDetails
                alertRecipient={alertRecipients[selectedAlertRecipientIndex]}
                onClose={() => this.setOverlayPortalMode( DeviceAlertRecipients.OVERLAY_PORTAL_MODE.HIDE )} />
            ) }
          </div>
        </PortalOverlay>
        <ConfirmationDialog
          show={showDialog}
          headerText={`Clear alert recipients on the device: ${device.esn}`}
          bodyText="Are you sure you want to clear alert recipients on the device?"
          onDeny={() => this.setState( { showDialog : false } )}
          onConfirm={() => this.clearAlertRecipients()} />
      </Flex>
    )
  }
}


export default connect( () => ( {} ) )( DeviceAlertRecipients )
