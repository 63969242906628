// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pane_ajaBp {
  border: 1px solid var(--color-disabled);
  padding: var(--size-padMedium) var(--size-padLarge);
  transition: box-shadow 0.2s;
}
.pane_ajaBp:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.pane_ajaBp.pane-dialog_FXK8m {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: var(--color-white);
}
`, "",{"version":3,"sources":["webpack://./src/theme/Pane.css"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,mDAAA;EACA,2BAAA;AACF;AACA;EACE,8CAAA;AACF;AACA;EACE,wCAAA;EACA,oCAAA;AACF","sourcesContent":[".pane {\n  border: 1px solid var( --color-disabled );\n  padding: var( --size-padMedium ) var( --size-padLarge );\n  transition: box-shadow 0.2s;\n}\n.pane:hover {\n  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);\n}\n.pane.pane-dialog {\n  box-shadow: 0 4px 8px rgba( 0, 0, 0, 0.2 );\n  background-color: var( --color-white );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pane": `pane_ajaBp`,
	"pane-dialog": `pane-dialog_FXK8m`
};
export default ___CSS_LOADER_EXPORT___;
