import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Flex, Content, PortalOverlay } from '@leverege/ui-elements'
import { TrackActions } from '../../../actions'
import { Track } from '../../../accessors'
import ConfirmationDialog from '../lib/dialog/ConfirmationDialog'
import TableWithSelectableRowsAndActions from '../lib/table/TableWithSelectableRowsAndActions'
import Search from '../lib/input/Search'
import ActionDropdownBar from '../lib/button/ActionDropdownBar'
import DeploymentTrackEditor from './DeploymentTrackEditor'
import S from './DeploymentTracks.less'
import TrackService from '../../../services/TrackService'

class DeploymentTracks extends React.Component {

  static get OVERLAY_PORTAL_MODE() {
    return {
      HIDE : 'hide',
      VIEW : 'view',
      EDIT : 'edit'
    }
  }

  static get DIALOG_MODE() {
    return {
      HIDE : 'hide',
      DELETE : 'delete'
    }
  }

  state = {
    overlayPortalMode : DeploymentTracks.OVERLAY_PORTAL_MODE.HIDE,
    dialogMode : DeploymentTracks.DIALOG_MODE.HIDE,
    query : '',
    lastUsedQuery : '',
    selectedGroups : [],
    clickedGroup : null,
    loading : false,
    dropdownOptions : []
  }

  constructor( props ) {
    super( props )

    this.isUnmounted = false;
    this.trackService = new TrackService()
  }

  async componentDidMount() {
    await this.loadData()
    this.onGroupSelectionChange( [] )
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  async loadData() {
    const { getTrackGroups } = this.props
    getTrackGroups()
 
    this.setState( { loading : true } )

    if ( !this.isUnmounted ) {
      this.setState( { loading : false } )
    }
  }

  onSelectAction = ( value ) => {
    if ( value === 'delete' ) {
      this.openDeleteDialog()
    }
  }

  onDeleteGroup = () => {
    const { selectedGroups } = this.state

    Promise.all( selectedGroups.map( ( group ) => {
      this.trackService.deleteTrack( group.id )
    } ) )
      .then( async () => {
        await this.loadData()
        this.setState( { dialogMode : DeploymentTracks.DIALOG_MODE.HIDE } )
      } )
  }

  search = () => {
    // TODO
  }

  searchQueryChange = ( { value } ) => {
    this.setState( { query : value } )
  }

  onGroupSelectionChange = ( selectedGroups ) => {
    this.setState( { selectedGroups } )

    let dropdownOptions = []

    if ( selectedGroups.length === 0 ) {
      dropdownOptions = [
        { key : 1, name : 'Add Group', value : 1, onClick : () => this.onAddGroup() }
      ]
    } else {
      dropdownOptions = [
        { key : 1, name : `Delete Group (${selectedGroups.length})`, value : 1, onClick : () => this.onSelectAction( 'delete' ) }
      ]
    }

    this.setState( { dropdownOptions } )
  }

  onGroupClick = ( group ) => {
    this.setState( {
      clickedGroup : group,
      overlayPortalMode : DeploymentTracks.OVERLAY_PORTAL_MODE.EDIT
    } )
  }

  onAddGroup = () => {
    this.setState( { overlayPortalMode : DeploymentTracks.OVERLAY_PORTAL_MODE.EDIT } )
  }

  openDeleteDialog = () => {
    this.setState( { dialogMode : DeploymentTracks.DIALOG_MODE.DELETE } )
  }

  afterGroupUpdated = async () => {
    this.setState( { overlayPortalMode : DeploymentTracks.OVERLAY_PORTAL_MODE.HIDE } )
    await this.loadData();
  }

  render() {
    const { className, trackList, trackDevices, trackFirmwares } = this.props
    const { lastUsedQuery, query, overlayPortalMode, clickedGroup, dropdownOptions, dialogMode, loading, } = this.state
    
    const trackListFormatted = trackList?.reduce( ( acc, row ) => {
      acc[row.id] = row
      return acc
    }, {} )

    const trackIds = trackList?.map( ( { id } ) => id )

    const groups = trackIds?.map( ( id ) => {
      const track = trackListFormatted[id]
      const numDevices = trackDevices.filter( d => d.track === id ).length

      const firmwares = trackFirmwares.filter( d => d.track === id )
        .map( d => trackFirmwares[d['track/firmwares']] )

      track.devices = `${numDevices} ESNs`
      track.firmwares = firmwares
      track.data = { ...track.data, name : track.name }
      return track
    } )

    const columns = [
      { Header : 'Name', id : 'name', accessor : Track.name, className : S.groupName },
      { Header : 'Devices', accessor : 'devices' },
      { Header : 'Main Unit', id : 'main', accessor : Track.mainBinary },
      { Header : 'Host', id : 'host', accessor : Track.hostBinary },
      { Header : 'Sensor', id : 'sensor', accessor : Track.sensorBinary },
      { Header : 'Repeater', id : 'repeater', accessor : Track.repeaterBinary },
    ]

    return (
      <Content>
        <Content.Area className={S.contentArea}>
          <Flex direction="column" className={S.panel}>
            <Flex align="center" variant="rowL" className={S.topControls}>
              <Search
                placeholder="Search by a group"
                disabled={lastUsedQuery === query}
                value={query}
                onChange={this.searchQueryChange}
                onSearch={this.search} />
              <ActionDropdownBar options={dropdownOptions} />
            </Flex>
            <TableWithSelectableRowsAndActions
              className={classNames( S.table, className )}
              loading={loading}
              data={groups || []}
              columns={columns}
              minRows={0}
              onSelectionChange={this.onGroupSelectionChange}
              onRowClick={this.onGroupClick} />
          </Flex>
          <PortalOverlay
            show={overlayPortalMode !== DeploymentTracks.OVERLAY_PORTAL_MODE.HIDE}
            targetId="mainScreen"
            animation="slideFromRight"
            position="right">
            <div>
              {
                overlayPortalMode === DeploymentTracks.OVERLAY_PORTAL_MODE.EDIT && (
                  <DeploymentTrackEditor
                    trackId={clickedGroup && clickedGroup.id}
                    key={clickedGroup && clickedGroup.id}
                    onClose={() => this.setState( { overlayPortalMode : DeploymentTracks.OVERLAY_PORTAL_MODE.HIDE, clickedGroup : null } )}
                    afterGroupUpdated={this.afterGroupUpdated} />
                ) }
            </div>
          </PortalOverlay>
          <ConfirmationDialog
            show={dialogMode === DeploymentTracks.DIALOG_MODE.DELETE}
            headerText="Are you sure you want to delete the selected groups?"
            bodyText="This action can not be undone. Deleting those items will erase all data."
            onDeny={() => this.setState( { dialogMode : DeploymentTracks.DIALOG_MODE.HIDE } )}
            onConfirm={this.onDeleteGroup} />
        </Content.Area>
      </Content>
    )
  }
}

const mapDispatchToProps = dispatch => ( {
  getTrackGroups : () => dispatch( TrackActions.getTrackGroups() ),
} )

export default connect( ( state, props ) => ( {
  ...state.track,
  model : state.data,
  ui : state.ui
} ), mapDispatchToProps )( DeploymentTracks )
