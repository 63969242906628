const Constants = require( '../actions/Constants' );

const initialState = {
  loading : false,
  activeFirmwares : [],
  archivedFirmwares : [],
  tracks : [],
  tracksLoading : false
}

function firmware( state = initialState, action ) {

  switch ( action.type ) {
  
    case Constants.FIRMWARE_LOADING: {
      const { payload } = action
  
      return {
        ...state,
        loading : payload.loading
      }
    }
  
    case Constants.FIRMWARE_LOADED: {
      const { payload } = action

      return {
        ...state,
        activeFirmwares : payload.activeFirmwares,
        archivedFirmwares : payload.archivedFirmwares
      }
    }

    case Constants.TRACKS_LOADING: {
      return {
        ...state,
        ...action.payload
      }
    }
  
    case Constants.TRACKS_LOADED: {

      return {
        ...state,
        ...action.payload
      }
    }
  
    default:
      return state
  }
  
}

module.exports = firmware
