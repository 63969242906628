module.exports = {
  "hierarchy": {
    "small": [
      "textArea-small"
    ],
    "esns": [
      "textArea-esns"
    ],
    "portalForm": [
      "textArea-portalForm"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "esns": {},
    "portalForm": {}
  }
}