import React from 'react'
import { Dropdown } from '@leverege/ui-elements'

export default class TrackSelector extends React.PureComponent {

  render() {
    const { tracks, onSelect, selectedTrack } = this.props

    if ( !tracks ) {
      return null
    }

    return (
      <Dropdown
        data={tracks}
        textField="name"
        valueField="id"
        sendValueField
        value={selectedTrack}
        onChange={onSelect} />
    )
  }
}
