module.exports = {
  "hierarchy": {
    "base": [
      "vTable-base"
    ]
  },
  "props": {
    "default": {},
    "base": {}
  }
}