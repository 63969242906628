import React from 'react'
import { connect } from 'react-redux'
import { Flex, Content, Button, Form, Text, Label, Toast, TextArea } from '@leverege/ui-elements'
import Path from '@leverege/path'
import { BeatLoader } from 'react-spinners'

import { Firmware, Device } from '../../../accessors'
import { FirmwareActions, DeviceActions } from '../../../actions'

let validator

class SendToDeviceDialog extends React.Component {

  constructor( props ) {
    super( props )

    this.state = {
      data : null,
      adding : false,
      addingCsv : false,
      esns : ''
    }

    this.validator = {
      fields : {
        esns : {

        }
      }
    }

    this.inputRef = React.createRef()
    this.reader = new FileReader()
    this.reader.onload = this.onFileLoaded
  }

  onSubmit = ( { value } ) => {
    const { firmware, onClose, dispatch } = this.props
    const { devices } = value

    const devicesIds = devices.map( dev => dev.id )

    dispatch( FirmwareActions.sendFirmwareToDevices( firmware.id, devicesIds ) )
      .then( ( results ) => {
        const success = []
        const failed = []
        results.forEach( ( res ) => {
          if ( res.status >= 300 ) {
            failed.push( res )
          } else {
            success.push( res )
          }
        } )

        const getEsns = ( arr ) => {
          const esns = arr
            .map( f => devices.find( x => x.id === f.deviceId ) )
            .filter( d => d !== null )
            .map( dev => Device.esn( dev ) )
          // console.log( esns )
          return esns.length === 1 ? esns[0] : esns.join( ', ' )
        }

        if ( failed.length > 0 ) {
          const esns = getEsns( failed )
          Toast.error(
            `Failed to send firmware to ${esns}.`,
            { containerId : 'toastDefault' }
          )
        }

        if ( success.length > 0 ) {
          const esns = getEsns( success )
          Toast.success(
            `Sent firmware ${Firmware.name( firmware )} to ${esns} successfully!`,
            { containerId : 'toastDefault' }
          )
        }

        onClose()

      } )
  }

  toggleAdding = () => {
    const { adding } = this.state
    this.setState( {
      adding : !adding,
      esns : '',
      error : null
    } )
  }

  toggleAddingCsv = () => {
    const { addingCsv } = this.state
    this.setState( {
      addingCsv : !addingCsv,
      esns : '',
      error : null
    } )
  }

  onESNsChange = ( { value } ) => {
    this.setState( {
      esns : value
    } )
  }

  updateDevicesItems = ( esns ) => {
    const { dispatch } = this.props
    const devices = validator.getDefaultData().devices || []

    dispatch( DeviceActions.getDevicesByNetworkAlias( esns ) )
      .then( ( res ) => {
        const nDevices = []
        res.forEach( ( item ) => {
          if ( item.status && item.status >= 300 ) {
            let message = item.message

            if ( item.status === 404 ) {
              message = `ESN ${item.message.substring( item.message.indexOf( '/' ) + 1 )} does not exist. Please enter a valid ESN.`
            }
            Toast.error( message, { containerId : 'toastDefault', autoClose : false } )
          } else {
            nDevices.push( item )
          }
        } )

        validator.onInputChange( {
          data : 'devices',
          value : [
            ...devices,
            ...nDevices
          ]
        } )
      } )

    this.setState( { checking : false } )
  }

  onAdd = () => {
    let { esns } = this.state
    esns = esns.replace( /\s/g, '' )

    this.setState( { checking : true } )
    this.updateDevicesItems( esns )
  }

  onFileLoaded = async () => {
    await this.setState( { checking : true } )

    const reg = new RegExp( '^[0-9]*$' )
    const fileContent = this.reader.result
    const esns = fileContent
      .split( '\n' )
      .map( line => line
        .replace( /(\r\n|\n|\r)/gm, '' )
        .replace( /"/g, '' )
      )
      .filter( line => reg.test( line ) )
      .filter( line => line !== '' )

    if ( esns.length === 0 ) {
      Toast.error( 'We couldn\'t identify valid ESNs in this file.', { containerId : 'toastDefault', autoClose : false } )
      await this.setState( { checking : false } )
      return
    }

    const nEsns = esns.join( ',' )
    this.updateDevicesItems( nEsns )
  }

  onFileError = () => {
    Toast.error( 'Error on selecting the CSV file', { containerId : 'toastDefault', autoClose : false } )
  }

  onFileChanged = () => {
    const files = Path.get( 'current/files', this.inputRef )

    if ( !files || files.length === 0 ) {
      Toast.error( 'Invalid file', { containerId : 'toastDefault', autoClose : false } )
      return
    }

    const file = files[0]
    const ext = file.name.split( '.' ).pop()
    if ( ext !== 'csv' ) {
      Toast.error( 'Please select a CSV file', { containerId : 'toastDefault', autoClose : false } )
      return
    }

    this.reader.readAsText( file )
  }

  onRemove = ( { data } ) => {
    const devices = validator.value( 'devices' )
    const remainingDevices = devices.filter( item => item.id !== data )

    validator.onInputChange( {
      data : 'devices',
      value : remainingDevices
    } )
  }

  renderForm = ( v ) => {
    const { onClose, firmware } = this.props
    const title = `Send firmware ${Firmware.name( firmware, '' )} to multiple devices`
    const { adding, addingCsv, esns, checking, error } = this.state

    validator = v
    const devices = validator.value( 'devices' )

    return (
      <Content relative style={{ width : 500, height : 300 }}>
        <Content.Header>
          <Text variant="instructions">{title}</Text>
        </Content.Header>
        <Content.Area style={{ paddingTop : 10 }}>
          <Flex variant="colM">
            <Flex align="center" variant="rowM">
              {( !adding && !addingCsv ) && (
                <React.Fragment>
                  <Button onClick={this.toggleAdding} variant="small">Add</Button>
                  <Button onClick={this.toggleAddingCsv} variant="small">Upload CSV</Button>
                </React.Fragment>
              )}
            </Flex>
            {adding && (
              <Flex variant="colM">
                <Flex align="center" variant="rowM">
                  <TextArea
                    variant="portalForm"
                    value={esns}
                    onChange={this.onESNsChange}
                    hint="Enter ESNs, separated by commas" />
                  {!checking ? (
                    <React.Fragment>
                      <Button variant="small" onClick={this.toggleAdding}>Cancel</Button>
                      <Button variant="small" onClick={this.onAdd}>Add</Button>
                    </React.Fragment>
                  ) : (
                    <BeatLoader size="10" color="blue" />
                  )}
                </Flex>
                {error && <Text variant="error">{error}</Text>}
              </Flex>
            )}
            {addingCsv && (
              <Flex variant="colM">
                <Flex align="center" variant="rowM">
                  <input
                    type="file"
                    name="csv-file"
                    ref={this.inputRef}
                    onChange={this.onFileChanged} />
                  {!checking && <Button variant="small" onClick={this.toggleAddingCsv}>Cancel</Button>}
                </Flex>
                <Text variant="hintText">Your file must include a header that says "ESN"</Text>
                {checking && <BeatLoader size="10" color="blue" />}
                {error && <Text variant="error">{error}</Text>}
              </Flex>
            )}
          </Flex>
          <Flex variant="colM" style={{ marginTop : 10 }}>
            {devices && devices.length > 0 ? devices
              .sort( ( a, b ) => {
                const nameA = Device.vesselName( a )
                const nameB = Device.vesselName( b )

                if ( nameA > nameB ) {
                  return 1
                }

                if ( nameB > nameA ) {
                  return -1
                }

                return 0
              } )
              .map( ( item ) => {
                return (
                  <Flex key={item.id} align="center" justify="space-between" style={{ marginLeft : 16 }}>
                    <Text>{Device.vesselName( item, item.name )}</Text>
                    <Button eventData={item.id} onClick={this.onRemove} variant="remove" />
                  </Flex>
                )
              } ) : (
                <Text>No Devices</Text>
            )}
          </Flex>
        </Content.Area>
        <Content.Footer>
          <Flex align="center" justify="flex-end" variant="rowM">
            <Button variant="hollow" type="button" onClick={onClose}>Close</Button>
            <Form.Submit>Send</Form.Submit>
          </Flex>
        </Content.Footer>
      </Content>
    )
  }

  render() {
    const { data } = this.state

    return (
      <Form
        render={this.renderForm}
        validator={this.validator}
        onSubmit={this.onSubmit}
        data={data} />
    )
  }
}


export default connect( state => ( {

} ) )( SendToDeviceDialog )
