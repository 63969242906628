import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Flex, DataBlock } from '@leverege/ui-elements'

import ConfirmationDialog from '../../lib/dialog/ConfirmationDialog'
import Device from '../../../../accessors/Device'
import { DeviceActions } from '../../../../actions'
import { NOT_AVAILABLE } from '../../../../actions/Constants'

import S from './DeviceOverview.less'

const DeviceOverview = ( props ) => {
  const {
    device,
    sendEnviromentCommand,
    sendConfigurationCommand,
    onChangeDropdownOptions,
    user,
    sendLowPowerOnCommand,
    sendLowPowerOffCommand,
    sendLowPowerAutoCommand,
  } = props

  const [ showDialog, setShowDialog ] = useState( false )
  const [ lowPowerDialog, setLowPowerDialog ] = useState( false )
  const [ pointDeviceDialog, setPointDeviceDialog ] = useState( false )
  const [ syncDeviceDialog, setSyncDeviceDialog ] = useState( false )
  const [ resetDeviceDialog, setResetDeviceDialog ] = useState( false )
  const [ checkDeviceDialog, setCheckDeviceDialog ] = useState( false )

  const [ nextEnv, setNextEnv ] = useState( 'production' )
  const [ nextLowPowerMode, setNextLowPowerMode ] = useState( 'off' )
  const [ commandValue, setCommandValue ] = useState( '' )

  const onConfirmCommand = () => {
    sendEnviromentCommand( device.id, nextEnv )
    setShowDialog( false )
    setPointDeviceDialog( false )
    setLowPowerDialog( false )
  }

  const onConfirmSetLowPower = () => {
    switch ( nextLowPowerMode ) {
      case 'on':
        sendLowPowerOnCommand( device.id )
        break
      case 'auto':
        sendLowPowerAutoCommand( device.id )
        break
      default:
        sendLowPowerOffCommand( device.id )
    }
    setShowDialog( false )
    setLowPowerDialog( false )
  }

  const onCancelCommand = () => {
    setShowDialog( false )
    setPointDeviceDialog( false )
    setLowPowerDialog( false )
    setSyncDeviceDialog( false )
    setResetDeviceDialog( false )
    setCheckDeviceDialog( false )
  }

  const onConfirmConfigurationCommand = () => {
    sendConfigurationCommand( device.id, commandValue )
    setShowDialog( false )
    setSyncDeviceDialog( false )
    setResetDeviceDialog( false )
    setCheckDeviceDialog( false )
  }

  const selectEnv = ( env ) => {
    setNextEnv( env )
    setPointDeviceDialog( true )
    setShowDialog( true )
  }

  const selectLowPowerMode = ( mode ) => {
    setNextLowPowerMode( mode )
    setLowPowerDialog( true )
    setShowDialog( true )
  }

  const selectCommand = ( cmd ) => {
    if ( cmd === 'backtap' ) {
      setSyncDeviceDialog( true )
      setShowDialog( true )
      setCommandValue( cmd )
    } else if ( cmd === 'reset' ) {
      setResetDeviceDialog( true )
      setShowDialog( true )
      setCommandValue( cmd )
    } else {
      setCheckDeviceDialog( true )
      setShowDialog( true )
      setCommandValue( cmd )
    }
  }

  useEffect( () => {

    const userRoleNames = user?.roles?.map( r => r?.name );
    let dropdownOptions;
    if ( userRoleNames?.includes( 'ShipAdmin' ) ) {
      dropdownOptions = [
        { key : 1, name : 'Point Device to Production', value : 1, onClick : () => selectEnv( 'production' ) },
        { key : 2, name : 'Point Device to Staging', value : 2, onClick : () => selectEnv( 'staging' ) },
        { key : 3, name : 'Point Device to Development', value : 2, onClick : () => selectEnv( 'development' ) },
        { key : 4, name : 'Synchronize Device', value : 3, onClick : () => selectCommand( 'backtap' ) },
        { key : 5, name : 'Reset Device', value : 4, onClick : () => selectCommand( 'reset' ) },
        { key : 6, name : 'Check for Device Updates', value : 5, onClick : () => selectCommand( 'checkIn' ) },
        { key : 7, name : 'Set Low Power Mode On', value : 6, onClick : () => selectLowPowerMode( 'on' ) },
        { key : 8, name : 'Set Low Power Mode Off', value : 7, onClick : () => selectLowPowerMode( 'off' ) },
        { key : 9, name : 'Set Low Power Mode Auto', value : 8, onClick : () => selectLowPowerMode( 'auto' ) },
      ]

    } else {
      dropdownOptions = [
        { key : 3, name : 'Synchronize Device', value : 3, onClick : () => selectCommand( 'sync' ) },
        { key : 4, name : 'Reset Device', value : 4, onClick : () => selectCommand( 'reset' ) },
        { key : 5, name : 'Check for Device Updates', value : 5, onClick : () => selectCommand( 'check' ) }
      ]
    }
    onChangeDropdownOptions( dropdownOptions )
  }, [ ] )

  let firmwareMainUnit = Device.mainFirmware( device )
  if ( firmwareMainUnit !== NOT_AVAILABLE ) firmwareMainUnit = `Version ${firmwareMainUnit}`

  let firmwareWirelessSensor = Device.sensorFirmware( device )
  if ( firmwareWirelessSensor !== NOT_AVAILABLE ) firmwareWirelessSensor = `Version ${firmwareWirelessSensor}`

  let firmwareWirelessHost = Device.hostFirmware( device )
  if ( firmwareWirelessHost !== NOT_AVAILABLE ) firmwareWirelessHost = `Version ${firmwareWirelessHost}`

  let firmwareWirelessRepeater = Device.repeaterFirmware( device )
  if ( firmwareWirelessRepeater !== NOT_AVAILABLE ) firmwareWirelessRepeater = `Version ${firmwareWirelessRepeater}`

  const firmwareUpdate = Device.lastFirmwareSentToDevice( device )

  const { gpsAntenna, gpsSelfTest, gpsReceiver } = Device.getGPSInfo( device )
  const lastCheckIn = Device.checkIn( device )

  const deviceInfo = {
    firmware : [
      { name : 'ICCID', value : Device.iccid( device ) },
      { name : 'IMSI', value : Device.imsi( device ) },
      { name : 'Main Unit', value : firmwareMainUnit },
      { name : 'Wireless Sensor', value : firmwareWirelessSensor },
      { name : 'Wireless Host', value : firmwareWirelessHost },
      { name : 'Wireless Repeater', value : firmwareWirelessRepeater },
      { name : 'Last Firmware Sent to Device', value : firmwareUpdate }
    ],
    gps : [
      { name : 'GPS Antenna Status', value : gpsAntenna },
      { name : 'GPS Self test', value : gpsSelfTest },
      { name : 'GPS Receiver Status', value : gpsReceiver }
    ],
    checkIn : [
      { name : 'Last Check-In', value : lastCheckIn }
    ]
  }

  return (
    <Flex variant="colM" className={S.contentArea}>
      <Flex variant="default">
        <DataBlock variant="deviceInfo" className={S.dataBlock} fields={deviceInfo.firmware} />
        <DataBlock variant="deviceInfo" className={S.dataBlock} fields={deviceInfo.gps} />
      </Flex>
      <Flex variant="default">
        <DataBlock variant="deviceInfo" className={S.dataBlock} fields={deviceInfo.checkIn} />
      </Flex>
      {lowPowerDialog && (
        <ConfirmationDialog
          show={showDialog}
          headerText="Send command"
          bodyText={`Are you sure you want to set Low Power Mode to ${nextLowPowerMode}?`}
          onDeny={() => onCancelCommand( )}
          onConfirm={() => onConfirmSetLowPower()} />
      )}
      {pointDeviceDialog && (
        <ConfirmationDialog
          show={showDialog}
          headerText="Send command"
          bodyText={`Are you sure you want to point the device to ${nextEnv}?`}
          onDeny={() => onCancelCommand( )}
          onConfirm={() => onConfirmCommand()} />
      )}
      {syncDeviceDialog && (
        <ConfirmationDialog
          show={showDialog}
          headerText="Send command"
          bodyText="Are you sure you want to synchronize your device?"
          onDeny={() => onCancelCommand( )}
          onConfirm={() => onConfirmConfigurationCommand()} />
      )}
      {resetDeviceDialog && (
        <ConfirmationDialog
          show={showDialog}
          headerText="Send command"
          bodyText="Are you sure you want to reset your device?"
          onDeny={() => onCancelCommand( )}
          onConfirm={() => onConfirmConfigurationCommand()} />
      )}
      {checkDeviceDialog && (
        <ConfirmationDialog
          show={showDialog}
          headerText="Send command"
          bodyText="Are you sure you want your device to check for updates?"
          onDeny={() => onCancelCommand( )}
          onConfirm={() => onConfirmConfigurationCommand()} />
      )}
    </Flex>
  )
}

const mapStateToProps = state => ( { user : state.user.user } )
const mapDispatchToProps = dispatch => ( {
  sendEnviromentCommand : ( id, env ) => {
    return dispatch( DeviceActions.sendCommand( id, {
      command : `setEnv,${env}`
    } ) )
  },
  sendConfigurationCommand : ( id, value ) => {
    return dispatch( DeviceActions.sendCommand( id, {
      command : `${value}`
    } ) )
  },
  sendLowPowerOnCommand : ( deviceId ) => {
    return dispatch( DeviceActions.sendCommand( deviceId, {
      command : 'setLowPowerMode,on'
    } ) )
  },
  sendLowPowerOffCommand : ( deviceId ) => {
    return dispatch( DeviceActions.sendCommand( deviceId, {
      command : 'setLowPowerMode,off'
    } ) )
  },
  sendLowPowerAutoCommand : ( deviceId ) => {
    return dispatch( DeviceActions.sendCommand( deviceId, {
      command : 'setLowPowerMode,auto'
    } ) )
  },
} )
export default connect( mapStateToProps, mapDispatchToProps )( DeviceOverview )
