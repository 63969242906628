// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_HC2Ns {
  z-index: 1;
  background: #ffffff;
  width: 100%;
}
.area_Lqx3g {
  z-index: 0;
}
.appContainer_toiCW {
  height: 100%;
  width: 100%;
}
.contentContainer_cTeUk {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/App.less"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,mBAAA;EACA,WAAA;AACF;AAEA;EACE,UAAA;AAAF;AAGA;EACE,YAAA;EACA,WAAA;AADF;AAIA;EACE,WAAA;AAFF","sourcesContent":[".header {\n  z-index : 1;\n  background: #ffffff;\n  width: 100%;\n}\n\n.area {\n  z-index : 0;\n}\n\n.appContainer {\n  height: 100%;\n  width: 100%;\n}\n\n.contentContainer {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_HC2Ns`,
	"area": `area_Lqx3g`,
	"appContainer": `appContainer_toiCW`,
	"contentContainer": `contentContainer_cTeUk`
};
export default ___CSS_LOADER_EXPORT___;
