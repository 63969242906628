module.exports = {
  "hierarchy": {
    "rowS": [
      "flex-rowS"
    ],
    "rowM": [
      "flex-rowM"
    ],
    "rowL": [
      "flex-rowL"
    ],
    "col": [
      "flex-col"
    ],
    "colS": [
      "flex-colS"
    ],
    "colM": [
      "flex-colM"
    ],
    "colL": [
      "flex-colL"
    ],
    "inputGroup": [
      "flex-inputGroup"
    ],
    "buttons": [
      "flex-rowL",
      "flex-buttons"
    ],
    "sidebarMenu": [
      "flex-sidebarMenu"
    ],
    "headerLogo": [
      "flex-headerLogo"
    ],
    "paginator": [
      "flex-paginator"
    ],
    "rowLA": [
      "flex-rowL",
      "flex-rowLA"
    ],
    "rowMA": [
      "flex-rowM",
      "flex-rowMA"
    ]
  },
  "props": {
    "default": {},
    "rowS": {},
    "rowM": {},
    "rowL": {},
    "col": {},
    "colS": {},
    "colM": {},
    "colL": {},
    "inputGroup": {},
    "buttons": {},
    "sidebarMenu": {},
    "headerLogo": {},
    "paginator": {},
    "rowLA": {},
    "rowMA": {}
  }
}