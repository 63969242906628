// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentArea_oJcNU {
  background: white;
}
.contentArea_oJcNU .mainColumn_KpRO0 {
  height: 100%;
}
.contentArea_oJcNU .mainColumn_KpRO0 .titleLabel_p5im1 {
  width: 120px;
  text-align: right;
}
.contentArea_oJcNU .mainColumn_KpRO0 .headerRow_GS2Bz {
  margin: 8px 16px 0px 16px;
}
.contentArea_oJcNU .mainColumn_KpRO0 .actionsRow_BKbHY {
  margin: 4px 8px;
}
.contentArea_oJcNU .mainColumn_KpRO0 .actionsRow_BKbHY .actionIconWrapper_IZnDu {
  width: 40px;
  height: 40px;
  padding: 8px;
  margin-right: 4px;
  border-radius: 32px;
}
.contentArea_oJcNU .mainColumn_KpRO0 .actionsRow_BKbHY .actionIconWrapper_IZnDu .actionIcon_w1RXe {
  filter: invert(23%) sepia(11%) saturate(245%) hue-rotate(145deg) brightness(91%) contrast(86%);
}
.contentArea_oJcNU .mainColumn_KpRO0 .actionsRow_BKbHY .actionIconWrapper_IZnDu:hover {
  background: #E6EAF0;
}
.contentArea_oJcNU .mainColumn_KpRO0 .actionsRow_BKbHY .actionIconWrapper_IZnDu:hover .actionIcon_w1RXe {
  filter: invert(30%) sepia(10%) saturate(3871%) hue-rotate(175deg) brightness(70%) contrast(91%);
}
.contentArea_oJcNU .mainColumn_KpRO0 .tabContainer_FCuxX {
  margin-top: 0;
  height: 100%;
}
.contentArea_oJcNU .mainColumn_KpRO0 .tabContainer_FCuxX .tabIcon_EF9j5 {
  filter: invert(50%) sepia(9%) saturate(122%) hue-rotate(161deg) brightness(88%) contrast(87%);
}
.contentArea_oJcNU .mainColumn_KpRO0 .tabContainer_FCuxX .activeTabIcon_RofNZ {
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);
}
`, "",{"version":3,"sources":["webpack://./src/components/desktop/devices/Device/DeviceScreen.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAFA;EAII,YAAA;AACJ;AALA;EAOM,YAAA;EACA,iBAAA;AACN;AATA;EAYM,yBAAA;AAAN;AAZA;EAgBM,eAAA;AADN;AAfA;EAmBQ,WAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;AADR;AAtBA;EA0BU,8FAAA;AADV;AAIQ;EACE,mBAAA;AAFV;AACQ;EAII,+FAAA;AAFZ;AA/BA;EAwCM,aAAA;EACA,YAAA;AANN;AAnCA;EA4CQ,6FAAA;AANR;AAtCA;EAgDQ,4FAAA;AAPR","sourcesContent":[".contentArea {\n  background: white;\n\n  .mainColumn {\n    height: 100%;\n\n    .titleLabel {\n      width: 120px;\n      text-align: right\n    }\n\n    .headerRow {\n      margin: 8px 16px 0px 16px;\n    }\n\n    .actionsRow {\n      margin: 4px 8px;\n\n      .actionIconWrapper {\n        width: 40px;\n        height: 40px;\n        padding: 8px;\n        margin-right: 4px;\n        border-radius: 32px;\n\n        .actionIcon {\n          filter: invert(23%) sepia(11%) saturate(245%) hue-rotate(145deg) brightness(91%) contrast(86%);\n        }\n\n        &:hover {\n          background: #E6EAF0;\n\n          .actionIcon {\n            filter: invert(30%) sepia(10%) saturate(3871%) hue-rotate(175deg) brightness(70%) contrast(91%);\n          }\n        }\n      }\n    }\n\n    .tabContainer {\n      margin-top: 0;\n      height: 100%;\n\n      .tabIcon {\n        filter: invert(50%) sepia(9%) saturate(122%) hue-rotate(161deg) brightness(88%) contrast(87%);\n      }\n\n      .activeTabIcon {\n        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(97%) contrast(103%);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentArea": `contentArea_oJcNU`,
	"mainColumn": `mainColumn_KpRO0`,
	"titleLabel": `titleLabel_p5im1`,
	"headerRow": `headerRow_GS2Bz`,
	"actionsRow": `actionsRow_BKbHY`,
	"actionIconWrapper": `actionIconWrapper_IZnDu`,
	"actionIcon": `actionIcon_w1RXe`,
	"tabContainer": `tabContainer_FCuxX`,
	"tabIcon": `tabIcon_EF9j5`,
	"activeTabIcon": `activeTabIcon_RofNZ`
};
export default ___CSS_LOADER_EXPORT___;
