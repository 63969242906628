import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Flex } from '@leverege/ui-elements'

import DeviceActions from '../../../../actions/DeviceActions'
import Sensor from '../../../../accessors/Sensor'
import DefaultTable from '../../lib/table/DefaultTable'
import RetryFirmwareDialog from '../../lib/dialog/RetryFirmwareDialog'
import Spinner from '../../lib/spinner/Spinner'

import S from './DeviceSensors.less'

const DeviceSensors = ( props ) => {
  const {
    device,
    sendRetryCommand,
    onChangeDropdownOptions,
    getDeviceSensors
  } = props
  const { sensors } = device

  const [ loading, setLoading ] = useState( !sensors )
  const [ showRetryDialog, setShowRetryDialog ] = useState( false )

  const onCancelCommand = () => {
    setShowRetryDialog( false )
  }

  const onConfirmCommand = ( payload ) => {
    sendRetryCommand( device?.id, payload )
    setShowRetryDialog( false )
  }

  useEffect( () => {
    async function fetchData() {
      if ( !sensors ) {
        await getDeviceSensors()
      }
      setLoading( false )
    }
    fetchData()

    const dropdownOptions = [
      { key : 1, name : 'Retry Firmware Downloads', value : 1, onClick : () => setShowRetryDialog( true ) },
    ]
    onChangeDropdownOptions( dropdownOptions )

  }, [ getDeviceSensors ] )

  const columns = [
    { id : 'id', Header : 'ID', accessor : Sensor?.esn },
    { id : 'name', Header : 'Name', accessor : Sensor?.name },
    { id : 'type', Header : 'Type', accessor : Sensor?.sensorType },
    { id : 'sensorLastUpdate', Header : 'Last Updated', accessor : Sensor?.sensorLastUpdate },
    {
      id : 'firmwareVersion',
      Header : 'Firmware Version',
      accessor : ( sensor ) => {
        return Sensor?.firmwareVersion( sensor, { device } )
      }
    },
    { id : 'value', Header : 'Value', accessor : Sensor?.value },
    { id : 'rssi',
      Header : 'RSSI',
      accessor : ( sensor ) => {
        return Sensor?.rssi( sensor, { device } ) 
      }
    },
    { id : 'battery', Header : 'Internal Battery', accessor : Sensor?.battery },
  ]

  return (
    <Flex variant="colM" className={S?.contentArea}>
      {loading ? <Spinner /> : (
        <DefaultTable
          className={S.table}
          data={( !sensors || ( sensors && ( sensors.status >= 400 || sensors.status <= 599 ) ) ) ? [] : sensors}
          columns={columns}
          loading={false} />
      )}
      {showRetryDialog && (
        <RetryFirmwareDialog
          device={device}
          sensors={( !sensors || ( sensors && ( sensors.status >= 400 || sensors.status <= 599 ) ) ) ? [] : sensors}
          show={showRetryDialog}
          onDeny={() => onCancelCommand()}
          onConfirm={payload => onConfirmCommand( payload )} />
      )}
    </Flex>
  )
}

const mapStateToProps = state => ( {} )
const mapDispatchToProps = dispatch => ( {
  sendRetryCommand : ( id, payload ) => {
    return dispatch( DeviceActions?.sendCommand( id, {
      path : 'wirelessSensor/firmware/retry',
      value : payload
    } ) )
  }
} )
export default connect( mapStateToProps, mapDispatchToProps )( DeviceSensors )
