import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content, Flex, Text } from '@leverege/ui-elements'
import DeviceActions from '../../../../actions/DeviceActions'
import Device from '../../../../accessors/Device'
import Spinner from '../../lib/spinner/Spinner'

import DeviceOverview from './DeviceOverview'
import DeviceCustomers from './DeviceCustomers'
import DeviceGroups from './DeviceGroups'
import DeviceFirmware from './DeviceFirmware'
import DeviceSensors from './DeviceSensors'
import DeviceNMEA from './DeviceNMEA'

import Tabs from '../../lib/tab/Tabs'
import S from './DeviceScreen.less'
import DeviceAlertRecipients from './DeviceAlertRecipients'
import ActionDropdownBar from '../../lib/button/ActionDropdownBar'
import { FirmwareActions, HistoryActions, TrackActions } from '../../../../actions'

class DeviceScreen extends Component {

  state = {
    dropdownOptions : [],
  }

  onTabChanged = () => this.onChangeDropdownOptions( [] )

  onChangeDropdownOptions = dropdownOptions => this.setState( { dropdownOptions } )

  componentDidMount = async () => {
    const {
      deviceId,
      device,
      getDevice,
      getFirmwareVersionHistory,
      getFirmwareList,
      getTrackByDeviceId,
    } = this.props

    if ( !device || device.id !== deviceId ) {
      await getDevice( deviceId )
    }
    getFirmwareVersionHistory( deviceId )
    getFirmwareList()
    getTrackByDeviceId( deviceId )
  }

  render = () => {
    const {
      device, firmwareVersionHistory,
      getDeviceSensors, getDevice,
      tracksByDeviceId
    } = this.props

    const { dropdownOptions } = this.state

    let items = null
    if ( tracksByDeviceId?.refs ) {
      items = Object.values( tracksByDeviceId.refs )?.map( ( track ) => {
        return {
          track : track.id,
          'track/devices' : device.id,
          data : {
            name : track.name
          },
          // Will always only be one matching ESN in the group for the device screen, this screen is only for one device!
          devices : '1 ESN',
          // TODO: Populate the firmware information if siren wants to keep the groups feature
          firmwares : [],
        }
      } )
    }

    const tabsConfig = [
      {
        key : 1,
        tabId : 'overview',
        title : 'Overview',
        icon : '/images/svg/white/s3p.svg',
        getComponent : () => <DeviceOverview device={device} onChangeDropdownOptions={this.onChangeDropdownOptions} />
      },
      {
        key : 2,
        tabId : 'sensors',
        title : 'Wireless Sensors',
        icon : '/images/svg/white/wireless.svg',
        getComponent : () => <DeviceSensors device={device} getDeviceSensors={getDeviceSensors} onChangeDropdownOptions={this.onChangeDropdownOptions} />
      },
      {

        key : 3,
        tabId : 'customers',
        title : 'Customers',
        icon : '/images/svg/white/profile.svg',
        getComponent : () => <DeviceCustomers device={device} customers={device.users} onChangeDropdownOptions={this.onChangeDropdownOptions} />
      },
      {
        key : 4,
        tabId : 'alertRecipients',
        title : 'Alert Recipients',
        icon : '/images/svg/white/profile.svg',
        getComponent : () => <DeviceAlertRecipients device={device} onChangeDropdownOptions={this.onChangeDropdownOptions} />
      },
      {
        key : 5,
        tabId : 'groups',
        title : 'Groups',
        icon : '/images/svg/white/users.svg',
        getComponent : () => <DeviceGroups groups={items} />
      },
      {
        key : 6,
        tabId : 'firmware',
        title : 'Firmware',
        icon : '/images/svg/white/export.svg',
        getComponent : () => <DeviceFirmware firmwareVersionHistory={firmwareVersionHistory} />
      },
      {
        key : 7,
        tabId : 'nmea',
        title : 'NMEA',
        icon : '/images/svg/white/nmea.svg',
        getComponent : () => <DeviceNMEA device={device} getDevice={getDevice} onChangeDropdownOptions={this.onChangeDropdownOptions} />
      }
    ]

    return (
      <Content>
        <Content.Area className={S.contentArea}>
          {!device ? <Spinner /> :
            (
              <Flex variant="colS" className={S.mainColumn}>
                <Flex variant="rowLA" className={S.headerRow}>
                  <Text variant="sectionTitle">{Device.nameAndEsn( device )}</Text>
                  <ActionDropdownBar options={dropdownOptions} />
                </Flex>
                <Tabs tabsConfig={tabsConfig} onTabChanged={this.onTabChanged} />
              </Flex>
            )
          }
        </Content.Area>
      </Content>
    )
  }
}

const mapStateToProps = ( state, props ) => {
  const deviceId = props.match.params.deviceId
  const tracksByDeviceId = state.track.tracksByDeviceId

  const { device : { selectedDevice } } = state
  const { firmwareVersionHistory } = state.history
  const { tracks : trackList } = state.firmware
  if ( firmwareVersionHistory !== undefined ) {
    firmwareVersionHistory.forEach( ( v ) => {
      const files = Object.keys( v.version )
      if ( files.length > 0 ) {
        const file = files[0]
        const firmwareId = v.version[file].firmwareId
        const firmware = state.data.lookup[firmwareId]
        if ( firmware ) {
          v.firmware = firmware
        }
      }
    } )
  }

  return {
    deviceId,
    device : selectedDevice,
    model : state.data,
    firmwareVersionHistory,
    trackList,
    tracksByDeviceId,
    ...state.firmware
  }
}

const mapDispatchToProps = dispatch => ( {
  getDevice : deviceId => dispatch( DeviceActions.getDevice( deviceId ) ),
  getFirmwareVersionHistory : deviceId => dispatch( HistoryActions.getFirmwareVersionHistory( deviceId ) ),
  getFirmwareList : () => dispatch( FirmwareActions.getAllFirmwares() ),
  getTrackByDeviceId : deviceId => dispatch( TrackActions.getTrackByDeviceId( deviceId ) ),
  getDeviceSensors : () => dispatch( DeviceActions.getDeviceSensors() ),
  dispatch,
} )

export default connect( mapStateToProps, mapDispatchToProps )( DeviceScreen )
